// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchUser } from './services/userService';
import { getUserError, getUser, getUserPending } from './reducers/userReducer';
import { Loader } from 'semantic-ui-react'
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router"
import { authContext, getToken } from './adalConfig';

class AdminRoute extends Component {

    constructor(props) {

        super(props);
    }

    state = {
        user: null,
    } 

    componentDidMount() {
        const { fetchUser } = this.props;
        fetchUser();
    }

    //componentDidUpdate(prevProps) {
    //    if (this.props.user !== prevProps.user) {
    //        this.shouldComponentRender();
    //    }
    //}

    //shouldComponentRender() {
    //    const { pending, user } = this.props;
    //    if (user && user.isAdmin) {
    //        console.log('go into true');
    //        return true;
    //    } else if (user && !user.isAdmin) {
    //        console.log('go into false');
    //        return false;
    //    }
    //}

    render() {
        const { component: Component, ...rest } = this.props
        const { user, error, pending, path, exact, component, location } = this.props;

        if (getToken() && (!user || !user.loginNameWithDomain)) return <Loader active inline='centered' />
        return (
            <Route
                {...rest}
                render={props => {
                    return !error && user.isAdmin ? (
                        <Component {...props} />
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/unauthorized",
                                    state: { from: props.location }
                                }}
                            />
                        )
                }}
            />
        )
    }
}
const mapStateToProps = state => ({
    error: getUserError(state),
    user: getUser(state),
    pending: getUserPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUser: fetchUser
}, dispatch)
export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AdminRoute);




