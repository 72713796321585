import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Icon, Grid, Label, List, Loader, Dimmer, Form, Radio, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchAllUsers, deleteUser, fetchAllSubmittedUsers } from '../../services/userService';
import { getAllRegisteredUsersError, getAllRegisteredUsers, getAllRegisteredUsersPending } from '../../reducers/AllRegisteredUsersReducer';
import { DeleteEmployeeInformationURL, PartialResetEmployeeURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminRegisteredUserPage.css';
import { authAxios } from '../../utils';
import { authContext, getToken } from '../../adalConfig';

var loginname = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class AdminRegisteredUserPage extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        data: [],
        LOB: ''
    }

    getEmployeesData(employees) {
        if (employees && employees.length > 0) {
            let data = employees.map((x, index) => {
                return ({
                    'delete': this.deleteSectionForEachRow(employees[index]),
                    'edit': this.editSectionForEachRow(employees[index]),
                    'email': this.emailSectionForEachRow(employees[index]),
                    'employeeName': x.name,
                    'loginNameWithDomain': x.loginNameWithDomain,
                    'computerDevices': x.currentSelectedComputerDevice,
                    'upgradeDate': x.upgradeWeek != null ? this.formatDate(x.upgradeWeek) : null,
                    'deliveryOffice': x.deliveryOffice,
                    'userComments': this.isBase64(x.userComments) ? atob(x.userComments) : x.userComments,
                    'softwarePackages': this.isBase64(x.softwarePackages) ? atob(x.softwarePackages) : x.softwarePackages,
                    'shipDate': x.shipDate != null ? this.formatDate(x.shipDate) : null,
                    'pickUpDate': x.pickUpDate != null ? this.formatDate(x.pickUpDate) : null,
                    'trackingNumber': x.trackingNumber,
                    'key': x.key
                    
                })
            });
            return data;
        } 
        return [];
    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }

    handleDeleteEmployee = (employeeInfo) => {
        let selectedEmployee = this.props.registeredUsers.find(_ => _.loginNameWithDomain == employeeInfo.loginNameWithDomain);
        authAxios.delete(`${PartialResetEmployeeURL}${selectedEmployee.key}`)
            .then(response => {
                let selectedUser = this.props.registeredUsers.find(_ => _.loginNameWithDomain == selectedEmployee.loginNameWithDomain);
                let employees = this.props.registeredUsers.splice(this.props.registeredUsers.indexOf(selectedUser), 1);
                let updatedEmployees = this.getEmployeesData(this.props.registeredUsers);

                this.setState({
                    data: updatedEmployees
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    deleteSectionForEachRow(employeeInfo) {
        return (
            <React.Fragment>
                <div className='dropdownAction'>
                    <Icon name="trash alternate" style={{ color: 'red' }} size='large' onClick={() => { if (window.confirm('Are you sure you wish to delete this user?')) this.handleDeleteEmployee(employeeInfo) }} />
                </div>
            </React.Fragment>
        )
    }

    editSectionForEachRow(employeeInfo) {
        return (
            <div className='dropdownAction'>
                <Link to={{ pathname: '/admin/adminUserReservationDetailPage/' + employeeInfo.key }} underlaycolor="transparent">
                    <Icon name="pencil alternate" style={{ color: 'blue' }} size='large' />
                </Link>
            </div>
        )
    }

    componentDidMount() {
        if (!loginname){
            this.props.history.push('../../unauthorized');
        } else {
            // since we r going from admin user reservation detail page back to this page
            // and admin user reservation detail page only can update one user at a time, we will update this employee first then we'll get all the other employees
            const { fetchAllSubmittedUsers } = this.props;
            fetchAllSubmittedUsers();
            this.setState({
                data: this.getEmployeesData(this.props.registeredUsers),
                LOB: 'CORP'
            });
            console.log(store.getState());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!this.props.registeredUsers || this.props.registeredUsers.length <= 0) return;
        if (this.props.registeredUsers != prevProps.registeredUsers) {
            this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
        }
        for (let i = 0; i < this.props.registeredUsers.length; i++) {
            if (this.props.registeredUsers != prevProps.registeredUsers) {
                this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
                break;
            }
            if (prevProps.registeredUsers.length > 0) {
                if (this.props.registeredUsers[i] && this.props.registeredUsers[i].computerDevices && this.props.registeredUsers[i].computerDevices.length != prevProps.registeredUsers[i].computerDevices.length) {
                    this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
                }
            }
        }
    }

    emailSectionForEachRow(employeeInfo) {
        let email = "mailto:" + employeeInfo.email;
        return (
            <div className='dropdownAction'>
                <a href={email}>
                    <Icon name="mail" size='large' />
                </a>
            </div>
        )
    }

    listOfComputerDevices(employeeInfo) {
        const { computerDevices } = employeeInfo;
        return (
            <React.Fragment>
                <Label>{employeeInfo.currentSelectedComputerDevice}</Label>
            </React.Fragment>
        );
    }

    handleLOBChange(e, value) {
        this.setState({
            LOB: value.value,
        }, () => {
            const { fetchAllSubmittedUsers } = this.props;
            fetchAllSubmittedUsers(this.state.LOB);
        });
    }

    handleGoBackToWelcomePage = () => {
        this.props.history.push('/welcomepage')
    }


    render() {
        let rowdata = this.state.data;
        rowdata = rowdata.map(function (item) {
            delete item.key;
            return item;
        })
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'delete',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: '',
                    field: 'edit',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Employee Name',
                    field: 'employeename',
                    sort: 'asc',
                    width: 60
                },
                {
                    label: 'Login',
                    field: 'login',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Device',
                    field: 'computerDevices',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Upgrade Date',
                    field: 'upgradedate',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Delivery Office',
                    field: 'deliveryoffice',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Other Notes',
                    field: 'userComments',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Software Packages',
                    field: 'softwarepackages',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Ship Date',
                    field: 'shipDate',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Pick Up Date',
                    field: 'pickUpDate',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Tracking Number',
                    field: 'trackingNumber',
                    sort: 'asc',
                    width: 100
                },

            ],
            rows: rowdata
        };
     
        if (this.props.registeredPending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )
        if (this.props.registeredPending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )
        return (
            <React.Fragment>
                <Form className='adminForms'>
                    <Form.Field className='dockformText'>
                        <p className='adminRadioButtonText'> Select line of business:</p>
                    </Form.Field>
                    <Form.Field className='dockform'>
                        <Radio className='adminRadioButton'
                            label='CORP'
                            name='radioGroupLOBManageHardware'
                            value='CORP'
                            checked={this.state.LOB === 'CORP'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                        <Radio className='adminRadioButton'
                            label='PROTIVITI'
                            name='radioGroupLOBManageHardware'
                            value='PROTIVITI'
                            checked={this.state.LOB === 'PROTIVITI'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                        <Radio className='adminRadioButton'
                            label='FIELD'
                            name='radioGroupLOBManageHardware'
                            value='FIELD'
                            checked={this.state.LOB === 'FIELD'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                    </Form.Field>

                </Form>
                <div className="datatable-grid">
                    <MDBDataTable
                        striped
                        bordered
                        hover
                        data={tableData} />
                </div>
                <Button
                    onClick={this.handleGoBackToWelcomePage}
                    className="welcome-page-back-buttonRegUsers">
                    Back
                </Button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllRegisteredUsersError(state),
    registeredUsers: getAllRegisteredUsers(state),
    registeredPending: getAllRegisteredUsersPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllSubmittedUsers: fetchAllSubmittedUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminRegisteredUserPage);


