import newestprobanner2 from '../../img/Banner1920x150_RH.png';
import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'
import '../Header.css';
import SmoothImage from 'react-smooth-image';

class Header extends Component {

    


    render() {
        const divStyle = {
            paddingBottom: 100
        };

        return (

            <header>
                <SmoothImage
                    src={newestprobanner2}
                    alt="header"
                    transitionTime={.5}
                    containerStyles={divStyle}
                //Other props if you choose to use them
                />
            </header>
               

        );
    }
}
export default Header;

