import React, { Component } from 'react';
import SmoothImage from 'react-smooth-image';
import { Form, TextArea, Input, Grid, Button, Image, Segment, Radio, List, GridRow, } from 'semantic-ui-react'
import './SelectionCorp.css';
import docksImage from '../../img/Docks2.jpg'
import { connect } from 'react-redux';
import { authAxios } from '../../utils';
import { UpdateUserURL, GetEmployeeInformationURL, InsertPatchEmployeeAsyncURL, GetDeviceImagesURL } from '../../constants';
import { authContext } from '../../adalConfig';
import Popup from "reactjs-popup";
import CorpHeader from './RHHeader';
import CorpFooter from './RHFooter';



let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class CorpHardware extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        //this.handleDockChange = this.handleDockChange.bind(this);
        this.handleLaptopChange = this.handleLaptopChange.bind(this);
        //this.handleYesDock = this.handleYesDock.bind(this);
    }

    state = {
        value: '',
        dock: '',
        error: 'false',
        userRole: '',
        key: '',
        computerDeviceData: [],
        //dockData: [
        //    { name: 'Dock 1', label: 'Type 1 Dock' },

        //    { name: 'Other', label: 'Other' }
        //]
    }


    getComputerDevicesData(computerdevices) {
        let data = computerdevices.map((x) => {
            return ({
                'name': x.name,
                'display': x.display,
                'processor': x.processor,
                'ram': x.ram,
                'hardDrive': x.hardDrive,
                'ports': x.ports,
                'weight': x.weight,
                'batterylife': x.batterylife,
                'accessories': x.dockingStation,
                'image': x.imageFile ? "data:image/" + x.imageName.split(".")[1] + ";base64, " + x.imageFile : null

            })
        });
        return data;
    }

    getComputerImageURL(computerData) {
        var data = "";
        for (var index = 0; index < computerData.length - 1; index++) {
            data = data.concat(computerData[index].image + ',');
        }
        data = data.concat(computerData[computerData.length - 1].image);
        return data;
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                let imageUrls = data.computerDevices.map(_ => _.image);


                authAxios.post(GetDeviceImagesURL, {
                    DeviceNames: imageUrls
                })
                    .then(response1 => {
                        let data1 = response1.data;
                        let newdata = data.computerDevices.map((item, i) => Object.assign({}, item, data1[i]));
                        this.setState({
                            value: data.currentSelectedComputerDevice,
                            //dock: data.currentSelectedDockDevice,
                            userRole: data.userRole,
                            computerDeviceData: this.getComputerDevicesData(newdata),
                            key: data.key
                        }, () => {
                            if (this.state.userRole === 'PROTIVITI')
                                this.props.history.push('/selection')
                            else if (this.state.userRole === 'FIELD')
                                this.props.history.push('/selectionfield')
                            this.setState({
                                loading: false
                            })

                        });
                    }).catch((error) => {
                        console.log('error ' + error);
                        this.setState({
                            errorredirect: true
                        });
                        this.setState({
                            loading: false
                        })
                        this.props.history.push('../unauthorized');
                    })




            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }



    handleClick() {
        if ( !this.state.value) {
            this.setState({
                error: 'true'
            })
        }
        else if (!this.state.computerDeviceData.find(_ => _.name === this.state.value)) {
            this.setState({
                error: 'true'
            })
        }
        //else if (this.state.value == 'Other') {
        //    let empDTO = {
        //        CurrentSelectedComputerDevice: this.state.value,
                
        //        upgradeWeek: ''
        //    }
             
        let results = [];
        let empDTO = {
            CurrentSelectedComputerDevice: this.state.value,

                upgradeWeek: ''}
            for (const property in empDTO) {
                results.push({
                    "op": "replace",
                    "path": `${property}`,
                    "value": `${empDTO[property]}`
                })
            }
            authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                results
            )
                .then(response => {
                    if (this.state.value.includes('Other'))
                        this.props.history.push('./review')
                    else
                        this.props.history.push('./calendar');
                })
                .catch((error) => {
                    console.log('error: ' + error);
                    authContext.login();
                    this.handleClick();

                });
        }


    

    handleLaptopChange(e, value) {
        this.setState({
            value: value.value,
            error: 'false'
        })
    }

   


    userErrorMessage = () => {
        return (
            <React.Fragment>
                {this.state.error === 'true' &&
                    <h2 className="errorreviewUIselect" >
                        Error: You must make a selection for all the required fields.
                    </h2>

                }

            </React.Fragment>
        )
    }


    handleSecondColumn(item) {
        //if (item.name === 'Other') {
        //    return (
        //        <React.Fragment>
        //            <Grid.Column>
        //                <br /><p className='other'><text className='bold'>1.</text> Go to BuyRHight.</p>
        //                <p className='other'><text className='bold'>2.</text> In the "What do you need?" field, enter the computer you are looking for. </p>
        //                <p className='other'><text className='bold'>3.</text> Select the computer labeled "(Corp Upgrade)".</p>
        //                <p className='other'><text className='important'>Important*</text> Make sure to answer the following question on BuyRHight when you add the computer to your cart: "Did you receive an upgrade invite from: NACorpUpgrades@roberthalf.com?” Please answer “Yes" or "No".</p><br />
        //            </Grid.Column>
        //        </React.Fragment>
        //    )
        //}
       // else {
            return (
                <React.Fragment>
                    <Grid.Column>

                        <Grid columns={2} divided className="detailsselectcorp">
                            <Grid.Row className="rowlight">
                                <Grid.Column>
                                    Display
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.display}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowdark">
                                <Grid.Column>
                                    Processor
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.processor}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowlight">
                                <Grid.Column>
                                    Ram
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.ram}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowdark">
                                <Grid.Column>
                                    Hard Drive
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.hardDrive}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowlight">
                                <Grid.Column>
                                    Ports
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.ports}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowdark">
                                <Grid.Column>
                                    Weight
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.weight}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowlight">
                                <Grid.Column>
                                    Battery Life
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.batterylife}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="rowdark">
                                <Grid.Column>
                                    Accessories
                                </Grid.Column>
                                <Grid.Column className="leftalign">
                                    {item.accessories}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Grid.Column>
                </React.Fragment>
            )
       // }
    }

    handleHelpClick = async () => {
        this.props.history.push('./help');
    }

    handleClickBack = async () => {
        this.props.history.push('./userinformation');
    }


    render() {
        

        const contStyleLaptops = {
            marginLeft: 'auto',
            marginRigth: 'auto',
            paddingBottom: 300
        }
        const imgStyleLaptops = {
            height: 295,
            width: 500,
            marginLeft: 50
        }

        return (
            <div className="outer-container">
                <div className='inner-container'>
                    <CorpHeader />

                    <h1 className="h1SelectCorp">Computer Selection</h1>
                    <Form required>
                        <Grid columns={2} divided className="selection" >

                            {this.state.computerDeviceData.map((item, index) =>
                                (<Grid.Row className="border">
                                    <Grid.Column className="area">

                                        <Form.Field className=''>
                                            <Radio

                                                className='text'
                                                label={item.name}
                                                name='radioGroupSelectionCorp'
                                                value={item.name}
                                                checked={this.state.value === item.name}
                                                onChange={(e, value) => this.handleLaptopChange(e, value)}
                                            />
                                        </Form.Field>

                                        <Popup
                                            modal
                                            trigger={
                                                <Image src={item.image} className='laptops' />
                                            }
                                            position="center">
                                            <Image src={item.image} className='picturepopup' />
                                        </Popup>

                                    </Grid.Column>

                                    {this.handleSecondColumn(item)}

                                </Grid.Row>))}
                        </Grid>

                    </Form>





                   





                    <div>
                        <Button color="green" className="backbutton2" onClick={this.handleClickBack}>   <i class="left arrow icon"></i>Back</Button>
                        <Button
                            type="submit"
                            color="green"
                            width="50"

                            className="calnextbutton"
                            onClick={this.handleClick}
                        >
                            Next
                            
                        <i class="right arrow icon">
                            </i>
                        </Button>

                    </div>

                    <div>   <Button className="helpbutton2" onClick={this.handleHelpClick}> Help</Button>
                    </div>

                    {this.userErrorMessage()}

                </div>
                <CorpFooter />
            </div>


        );
    }
}

export default connect()(CorpHardware);



//<div>
//    <Form>
//        <Form.Field className='dockformText'>
//            <p>
//                If you have a different docking station that is not pictured below, or do not have one, please select Other.  <br />
//            </p>
//        </Form.Field>
//        {this.state.dockData.map((item, index) =>
//            (
//                <Form.Field className='dockform'>
//                    <Radio className='text'
//                        label={item.label}
//                        name='radioGroupDock'
//                        value={item.name}
//                        checked={this.state.dock === item.name}
//                        onChange={(e, value) => this.handleDockChange(e, value)}
//                    />
//                </Form.Field>
//            ))}
//    </Form>
//</div>