import React, { Component } from 'react';
import { withRouter } from 'react-router';
import backbutton from '../../img/BackButton.PNG';

import { Segment, Button, Image, Grid, Label, Input } from 'semantic-ui-react'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../../shares/Checkbox';
import { UpdateOfficeListingPointOfContactURL, GetSingleOfficeListingPointOfContactURL } from '../../constants';
import { authAxios } from '../../utils';
import './AdminUserAddPage.css';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';

class AdminOfficeEditComponent extends Component {
    constructor(props) {
        super(props)
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
    selectedCheckedBoxes = [];
    state = {
        key: '',
        costCenter: '',
        office: '',
        country: '',
        shippingInfo: '',
        phoneNumber: '',
        loginName: '',
    }

    componentDidMount() {
        authAxios.get(GetSingleOfficeListingPointOfContactURL + this.props.location.pathname.split("/")[3])
            .then(response => {
                let data = response.data
                this.setState({
                    key: data.key, costCenter: data.costCenter, office: data.office, country: data.country,
                    shippingInfo: data.shippingInfo, phoneNumber: data.phoneNumber, loginName: data.loginName
                }, () => {
                    console.log("office listing and point of contact got passed in: " + this.state);
                })
            })
            .catch((error) => {
                console.log('error: ' + error);
            });
        
    }

    handleClickBack() {
        this.props.history.push(`/admin/adminOfficeListingPage`);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    };

    handleSave = () => {
        const { key, loginName, costCenter, office, country, shippingInfo, phoneNumber } = this.state;
        authAxios.put(`${UpdateOfficeListingPointOfContactURL}/${key}`, {
            loginName,
            office,
            costCenter,
            country,
            shippingInfo,
            phoneNumber,
        })
            .then(response => {
                this.props.history.push(`/admin/adminOfficeListingPage`);
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    render() {
        const { office, costCenter, country, shippingInfo, phoneNumber, loginName } = this.state
        return (
            <div className='outer-container'>
                <div className="inner-container">
                    <DualHeader/>
                    <Segment.Group className="segment-container">
                        <Segment className="segment-header inverted">Edit Office: {this.state.office} </Segment>
                        <Segment>
                            <Grid columns={2}>
                                {/* Office */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Office: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Office' name='office' value={office} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Cost Center */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Cost Center: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Cost Center' name='costCenter' value={costCenter} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Country */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Country: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Country' name='country' value={country} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Shipping Info */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Address: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Shipping Info' name='shippingInfo' value={shippingInfo} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Phone Number */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Phone Number: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Office Phone Number' name='phoneNumber' value={phoneNumber} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Point Of Contact */}
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Point Of Contact: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input placeholder='Domain\Username' name='loginName' value={loginName} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Segment>
                    </Segment.Group>
                    <div className="botbuttomdiv">
                        <Button className="backbuttonadmin3" onClick={this.handleClickBack}>Back</Button>
                        <Button className="savedbutton" onClick={this.handleSave}>Save</Button>

                    </div>
                </div>
                <DualFooter/>
            </div>
        );
    }
}

export default AdminOfficeEditComponent;



