import {
    FETCH_ALL_REGISTERED_USERS_SUCCESS, FETCH_ALL_REGISTERED_USERS_PENDING, FETCH_ALL_REGISTERED_USERS_ERROR, DELETE_USER_SUCCESS, DELETE_USER_PENDING
} from "../actions";

const initialStateAllRegisteredUsers = {
    registeredUsersPending: false,
    registeredUsers: [],
    error: null
}
export function allRegisteredUsersReducer(state = initialStateAllRegisteredUsers, action) {
    switch (action.type) {
        case FETCH_ALL_REGISTERED_USERS_PENDING:
            return {
                ...state,
                registeredUsersPending: true
            }
        case FETCH_ALL_REGISTERED_USERS_SUCCESS:
            return {
                ...state,
                registeredUsersPending: false,
                registeredUsers: action.registeredUsers
            }
        case FETCH_ALL_REGISTERED_USERS_ERROR:
            return {
                ...state,
                registeredUsersPending: false,
                error: action.error
            }

        case DELETE_USER_SUCCESS:
            return state.filter((data, i) => i != action.registeredUsers)

        default:
            return state;
    }
}

export const getAllRegisteredUsers = state => state.allRegisteredUsersReducer.registeredUsers ? state.allRegisteredUsersReducer.registeredUsers : null
export const getAllRegisteredUsersPending = state => state.allRegisteredUsersReducer.registeredUsersPending;
export const getAllRegisteredUsersError = state => state.allRegisteredUsersReducer.error;

