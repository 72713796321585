import React, { Component } from 'react';
import { Form, Button, Input, Dimmer, Loader, Radio } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import './ManageCalendar.css';
import { connect } from 'react-redux';
import { authAxios } from '../../utils';
import { UpdateSlots, GetAllFutureDatesURL } from '../../constants';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import DualHeader from '../DualHeader';
import DualFooter from '../DualFooter';


class ManageCalendar extends Component {
    constructor(props) {
       super();
        window.calcomp = this;
        this.handleCalClickBack = this.handleCalClickBack.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDateSelected = this.handleDateSelected.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleLOBChange = this.handleLOBChange.bind(this);
    }

    state = {
        date: new Date(),
        loading: false,
        disabledDates: [],
        slotsPro: [],
        slotsCORP: [],
        slotsField: [],
        modifiedDatesCORP: [],
        modifiedDatesPro: [],
        modifiedDatesField: [],
        LOB: 'CORP'
    }

    handleDayClick(day, modifiers = {}) {
        if (modifiers.disabled) {
            return;
        }
        this.setState({
            selectedDay: modifiers.selected ? undefined : day,
            error: true

        });
    }

    componentDidMount() {
        this.setState({
            loading: true
        })


        authAxios.get(GetAllFutureDatesURL, {
        })
            .then(response => {
                let data = response.data;
                let disableDates = [];                
                disableDates.push({ daysOfWeek: [0, 2, 3, 4, 5, 6] });
                disableDates.push({
                    before: this.DateNow()
                });
                
                let dataCORP = data.filter(item => (item.lob === 'CORP'));
                let dataField = data.filter(item => (item.lob === 'FIELD')) 
                let dataPro = data.filter(item => (item.lob === 'PRO')) 
                this.setState({                    
                    slotsCORP: this.getSlots(dataCORP),
                    slotsPro: this.getSlots(dataPro),
                    slotsField: this.getSlots(dataField),
                    disabledDates: disableDates,
                    loading: false
                });

            }).catch((error) => {
                this.setState({ loading: false });
            })


    }

    getSlots(slots) {
        let data = slots.map((x) => {
            return ({
                'DateTimeValue': x.dateTimeValue,
                'MaxSlotUS': x.maxSlotUS,
                'MaxSlotCA': x.maxSlotCA,
                'CurrentSlotUS': x.currentSlotCountUS,
                'CurrentSlotCA': x.currentSlotCountCA,                
            })
        });
        return data;
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDay, CAslot, USslot } = this.state;
        if (selectedDay !== prevState.selectedDay) {
            this.date = this.state.selectedDay;
            this.setState({
                selectedDay
                
            })
        }
        if (CAslot !== prevState.CAslot) {
            this.setState({
                CAslot
            })
        }
        if (USslot !== prevState.USslot) {
            this.setState({
                USslot
            })
        }
    }


    handleCalClickBack() {        
        this.props.history.push('./WelcomePage');
    }




    handleSave() {
        authAxios.post(UpdateSlots, {
            SlotUpdate: this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP : this.state.LOB === 'PRO' ? this.state.modifiedDatesPro : this.state.modifiedDatesField,
            LOB: this.state.LOB
        }).then(response => {
            this.props.history.push('./WelcomePage');
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }


    onChange = async function (date) {
        await this.setState({ date: date });
        await this.setState({ selectedDay: this.date });
    }


    DateNow = () => {
        let today = new Date();
        let firstMondayOfTheCurrentWeek = today.getDate() - today.getDay() + (today.getDay() == 0 ? -6 : 1);
        return new Date(today.setDate(firstMondayOfTheCurrentWeek));
    }



    passActiveDate(activeDate) {
        const monthAfterPress = activeDate.getMonth();
        console.log(monthAfterPress);
    }

    formatMonthTitle(d, locale) {
        return `${d.getMonth() + 1}/${d.getFullYear}`
    }

    handleSlotChangeUS(e, selectedDate) {
        let newSlot = this.state.LOB === 'CORP' ? this.state.slotsCORP : this.state.LOB === 'FIELD' ? this.state.slotsField : this.state.slotsPro  ;
        let foundIndex = newSlot.findIndex(_ => _.DateTimeValue === selectedDate);
        if (foundIndex !== -1) {
            newSlot[foundIndex].MaxSlotUS = e.target.value;
            if (this.state.LOB === 'CORP' ) {
                this.setState({
                    slotsCORP: newSlot,
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    slotsField: newSlot,
                })
            }
            else {
                this.setState({
                    slotsPro: newSlot,
                })
            }
        }
        else {
            let newSlotObj = {
                DateTimeValue: selectedDate,
                MaxSlotUS: e.target.value,                
                MaxSlotCA: this.state.LOB === 'CORP' ? 5 : this.state.LOB === 'PRO' ? 2 : 15,                
                CurrentSlotUS: 0,                
                CurrentSlotCA: 0,
                
            }
            //let joined = this.state.slots.concat(newSlotObj);
            let joined = this.state.LOB === 'CORP' ? this.state.slotsCORP.concat(newSlotObj) : this.state.LOB === 'FIELD' ? this.state.slotsField.concat(newSlotObj) : this.state.slotsPro.concat(newSlotObj);
            if (this.state.LOB === 'CORP') {
                this.setState({
                    slotsCORP: joined
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    slotsField: joined
                })
            }
            else {
                this.setState({
                    slotsPro: joined
                })
            }
        }

        let newModified = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP : this.state.LOB === 'FIELD' ? this.state.modifiedDatesField : this.state.modifiedDatesPro;
        let modifiedIndex = newModified.findIndex(_ => _.DateTimeValue === selectedDate);        
        if (modifiedIndex !== -1) {
            newModified[modifiedIndex].MaxSlotUS = e.target.value;
            if (this.state.LOB === 'CORP') {
                this.setState({
                    modifiedDatesCORP: newModified,
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    modifiedDatesField: newModified,
                })
            }
            else {
                this.setState({
                    modifiedDatesPro: newModified,
                })
            }
        }
        else {
            let newModifiedObj = {
                DateTimeValue: selectedDate,
                MaxSlotUS: e.target.value,                
                MaxSlotCA: this.state.LOB === 'CORP' ? 5 : this.state.LOB === 'PRO' ? 2 : 15,                
            }
            //let joined = this.state.modifiedDates.concat(newModifiedObj);
            let joined = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP.concat(newModifiedObj) : this.state.LOB === 'FIELD' ? this.state.modifiedDatesField.concat(newModifiedObj) : this.state.modifiedDatesPro.concat(newModifiedObj);
            if (this.state.LOB === 'CORP') {
                this.setState({
                    modifiedDatesCORP: joined,
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    modifiedDatesField: joined,
                })
            }
            else {
                this.setState({
                    modifiedDatesPro: joined
                })
            }
        }
    }


    handleSlotChangeCA(e, selectedDate) {
        let newSlot = this.state.LOB === 'CORP' ? this.state.slotsCORP : this.state.LOB === 'FIELD' ? this.state.slotsField : this.state.slotsPro;
        let foundIndex = newSlot.findIndex(_ => _.DateTimeValue === selectedDate);
        if (foundIndex !== -1) {
            newSlot[foundIndex].MaxSlotCA = e.target.value;
            if (this.state.LOB === 'CORP') {
                this.setState({
                    slotsCORP: newSlot,
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    slotsField: newSlot,
                })
            }
            else {
                this.setState({
                    slotsPro: newSlot,
                })
            }
        }
        else {
            let newSlotObj = {
                DateTimeValue: selectedDate,
                MaxSlotUS: this.state.LOB === 'CORP' ? 20 : this.state.LOB === 'PRO' ? 25 : 110,
                MaxSlotCA: e.target.value,                
                CurrentSlotUS: 0,                
                CurrentSlotCA: 0,
                
            }
            //let joined = this.state.slots.concat(newSlotObj);
            let joined = this.state.LOB === 'CORP' ? this.state.slotsCORP.concat(newSlotObj) : this.state.LOB === 'FIELD' ? this.state.slotsField.concat(newSlotObj) : this.state.slotsPro.concat(newSlotObj);
            if (this.state.LOB === 'CORP') {
                this.setState({
                    slotsCORP: joined
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    slotsField: joined
                })
            }
            else {
                this.setState({
                    slotsPro: joined
                })
            }
        }

        let newModified = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP : this.state.LOB === 'FIELD' ? this.state.modifiedDatesField : this.state.modifiedDatesPro;
        let modifiedIndex = newModified.findIndex(_ => _.DateTimeValue === selectedDate);
        if (modifiedIndex !== -1) {
            newModified[modifiedIndex].MaxSlotCA = e.target.value;
            if (this.state.LOB === 'CORP') {
                this.setState({
                    modifiedDatesCORP: newModified,
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    modifiedDatesField: newModified,
                })
            }
            else {
                this.setState({
                    modifiedDatesPro: newModified,
                })
            }
        }
        else {
            let newModifiedObj = {
                DateTimeValue: selectedDate,
                MaxSlotUS: this.state.LOB === 'CORP' ? 20 : this.state.LOB === 'PRO' ? 25 : 110,                
                MaxSlotCA: e.target.value,
            }
            //let joined = this.state.modifiedDates.concat(newModifiedObj);
            let joined = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP.concat(newModifiedObj) : this.state.LOB === 'FIELD' ? this.state.modifiedDatesField.concat(newModifiedObj) : this.state.modifiedDatesPro.concat(newModifiedObj);
            if (this.state.LOB === 'CORP') {
                this.setState({
                    modifiedDatesCORP: joined
                })
            }
            else if (this.state.LOB === 'FIELD') {
                this.setState({
                    modifiedDatesField: joined
                })
            }
            else {
                this.setState({
                    modifiedDatesPro: joined
                })
            }
        }
    }


    handleDateSelected() {
        let slots = this.state.LOB === 'CORP' ? this.state.slotsCORP : this.state.LOB === 'FIELD' ? this.state.slotsField : this.state.slotsPro;
        let selectedDate = new Date(this.state.selectedDay).toLocaleDateString();
        if (this.state.selectedDay) {
            let slot = slots.find(_ => _.DateTimeValue === selectedDate)
            return (
                <React.Fragment>
                    <Form.Field>
                        <label className='selecteddate'>
                            Selected Date: {selectedDate}
                        </label>
                    </Form.Field>
                    <Form.Field inline className='selecteddate'>
                        <label>US - Max: </label>
                        <Input
                            type="number"
                            onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 11) }}
                            value={slot ? slot.MaxSlotUS : this.state.LOB === 'CORP' ? 20 : this.state.LOB === 'PRO' ? 25 : 110}
                            onChange={(e) => this.handleSlotChangeUS(e, selectedDate)}
                            name='USSlot'
                            className='maxinput'
                        />
                        <label>&nbsp;&nbsp; Filled: {slot ? slot.CurrentSlotUS : 0}</label>
                        <label>&nbsp;&nbsp; Available: {slot ? slot.MaxSlotUS - slot.CurrentSlotUS : this.state.LOB === 'CORP' ? 20 : this.state.LOB === 'PRO' ? 25 : 110}</label>
                    </Form.Field>
                    <Form.Field inline className="selecteddate">
                        <label>CA - Max: </label>
                        <Input
                            type="number"
                            onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 11) }}
                            value={slot ? slot.MaxSlotCA : this.state.LOB === 'CORP' ? 5 : this.state.LOB === 'PRO' ? 2 : 15}
                            onChange={(e) => this.handleSlotChangeCA(e, selectedDate)}
                            name='CASlot'
                            className='maxinput'
                        />
                        <label>&nbsp;&nbsp; Filled: {slot ? slot.CurrentSlotCA : 0}</label>
                        <label>&nbsp;&nbsp; Available: {slot ? slot.MaxSlotCA - slot.CurrentSlotCA : this.state.LOB === 'CORP' ? 5 : this.state.LOB === 'PRO' ? 2 : 15}</label>
                    </Form.Field>
                </React.Fragment>
            )
        }
    }

    handleLOBChange(e, value) {
        this.setState({
            LOB: value.value,
        })
    }

    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Available Dates...</Loader>
                    </Dimmer>
                </div>
            )

        //const modDates = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP.map : this.state.modifiedDatesPro.map((d) => <ul key={d.DateTimeValue}> {d.DateTimeValue}: US - {d.MaxSlotUS} CA: - {d.MaxSlotsCA}</ul>)
        //const modDates = this.state.modifiedDates.map((d) => <ul key={d.DateTimeValue}> {d.DateTimeValue}: US - {d.MaxSlotUS} CA: - {d.MaxSlotCA} </ul>);

        let modDates = this.state.LOB === 'CORP' ? this.state.modifiedDatesCORP : this.state.LOB === 'PRO' ? this.state.modifiedDatesPro : this.state.modifiedDatesField;
        if (this.state.LOB === 'CORP') {
            modDates = this.state.modifiedDatesCORP.map((d) => <ul key={d.DateTimeValue}> {d.DateTimeValue}: US - {d.MaxSlotUS} CA - {d.MaxSlotCA} </ul>);
        }
        else if (this.state.LOB === 'PRO') {
            modDates = this.state.modifiedDatesPro.map((d) => <ul key={d.DateTimeValue}> {d.DateTimeValue}: US - {d.MaxSlotUS} CA - {d.MaxSlotCA} </ul>);
        }
        else {
            modDates = this.state.modifiedDatesField.map((d) => <ul key={d.DateTimeValue}> {d.DateTimeValue}: US - {d.MaxSlotUS} CA - {d.MaxSlotCA} </ul>);
        }

        return (

            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader />


                    <h1 className="h1Cal"> Manage Calendar </h1>

                    <Form className='adminForms'>
                        <Form.Field className='adminformText'>
                            <p className='adminRadioButtonText'> Select line of business:</p>
                        </Form.Field>
                        <Form.Field className='dockform'>
                            <Radio className='adminRadioButton'
                                label='CORP'
                                name='radioGroupLOBManageHardware'
                                value='CORP'
                                checked={this.state.LOB === 'CORP'}
                                onChange={(e, value) => this.handleLOBChange(e, value)}
                            />
                            <Radio className='adminRadioButton'
                                label='PROTIVITI'
                                name='radioGroupLOBManageHardware'
                                value='PRO'
                                checked={this.state.LOB === 'PRO'}
                                onChange={(e, value) => this.handleLOBChange(e, value)}
                            />
                            <Radio className='adminRadioButton'
                                label='FIELD'
                                name='radioGroupLOBManageHardware'
                                value='FIELD'
                                checked={this.state.LOB === 'FIELD'}
                                onChange={(e, value) => this.handleLOBChange(e, value)}
                            />
                        </Form.Field>

                    </Form>

                    <div className='calwrapper'>
                        <div className="leftside">
                            <DayPicker
                                onDayClick={this.state.date}
                                numberOfMonths={2}
                                selectedDays={new Date(this.state.selectedDay)}
                                onDayClick={this.handleDayClick}
                                formatMonthTitle
                                disabledDays={this.state.disabledDates}
                                month={this.state.month}
                                onInteraction={this.handleDaySelect}
                                onMonthChange={this.handleMonthChange}
                                modifiers={
                                    {
                                        monday: { daysOfWeek: [1] }
                                    }
                                }
                                value={this.date}
                                onChange={(d) => { this.onChange(d) }}
                                className={this.state.LOB === 'CORP' ? 'CORP' : this.state.LOB === 'PRO' ? 'PRO' : 'FIELD'}
                            />

                            <Form className="MangeCalendarDateInput">
                                {this.handleDateSelected()}
                            </Form>


                        </div>

                        <div className="rightside">
                            <div className="HeaderStrings">

                   

                                <h2>Modified Dates - {this.state.LOB === 'CORP' ? 'Corporate':'' || this.state.LOB === 'FIELD' ? 'Field':'' || this.state.LOB === 'PRO' ? 'Protiviti' : '' }</h2>
                                <div className= "ModifiedDates">
                                    {modDates}
                                </div>
                            </div>


                            <Form className="DateInput"  >

                                <Button
                                    type='submit'
                                    color="green"
                                    onClick={this.handleCalClickBack}
                                    className="calbackbutton">
                                    <i class="left arrow icon"></i> Back
                                </Button>

                                <Button
                                    type='submit'
                                    required
                                    color="green"
                                    onClick={this.handleSave}
                                    className="nextbtton"
                                >
                                    Save
                                    <i class="right arrow icon">
                                    </i>
                                </Button>        
                            </Form>
                            
                        </div>
                    </div>

                </div>
                <DualFooter/>
            </div>

        );
    }
}
export default connect()(ManageCalendar);