import newestprofooter from '../../img/ProtivitiThingFooter.jpg';
import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'
import '../Footer.css';



class Footer extends Component {



    render() {
        return (
            <footer>
                <Image src={newestprofooter} className="botbanner2" />
            </footer>


)}
    
}
export default Footer;

