import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './components/Home';
import UserInformation from './components/UserInformation';
import Unauthorized from './components/Unauthorized';
import CalendarComp from './components/CalendarComp';
import 'semantic-ui-css/semantic.min.css';
import { withAdalLoginApi } from './adalConfig'
import ProtectedRoute from './protectedRoute';
import AdminRoute from './AdminRoute';
import ReviewRoute from './ReviewRoute';
import Selection from './components/Pro/Selection';
import HelpPage from './components/HelpPage';
import ConfirmationPage from './components/ConfirmationPage';
import ReviewPage from './components/ReviewPage';
import WelcomePage from './components/admin/WelcomePage';
import AdminUserAddComponent from './components/admin/AdminUserAddPage';
import AdminPOCAddComponent from './components/admin/AdminPOCAddPage';
import AdminTabPageComponent from './components/admin/AdminTabPage';
import AdminUserEditComponent from './components/admin/AdminUserEditPage';
import AdminHardware from './components/admin/AdminHardwarePage';
import AdminDeviceAddComponent from './components/admin/AdminDeviceAddPage';
import AdminOfficeListing from './components/admin/AdminOfficeListingPage';
import AdminOfficeEditComponent from './components/admin/AdminOfficeEditPage';
import AdminUserReservationDetailComponent from './components/admin/AdminUserReservationDetailPage';
import AdminRegisteredUserPage from './components/admin/AdminRegisteredUserPage';
import AdminDeviceEditComponent from './components/admin/AdminDeviceEditPage';
import CorpHardware from './components/Corp/SelectionCorp';
import Software from './components/Corp/Software';
import FieldHardware from './components/Field/SelectionField';
import ManageCalendar from './components/admin/ManageCalendar';
import OptOut from './components/OptOut';
import ReviewOptOut from './components/ReviewOptOut';
import OptOutConfirmation from './components/OptOutConfirmation';
import Deadline from './components/DeadlinePage';
import AdminResetUserComponent from './components/admin/AdminResetUserPage';



 function App(){
    return (
        <Router>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/unauthorized' exact component={Unauthorized} />
                <ProtectedRoute exact path="/userinformation" exact component={UserInformation} />
                <Route exact path="/help" exact component={HelpPage} />
                <ProtectedRoute exact path="/calendar" exact component={CalendarComp} />
                <ReviewRoute exact path="/review" exact component={ReviewPage} />
                <Route exact path="/confirmation" exact component={ConfirmationPage} />
                <Route exact path="/software" exact component={Software} />
                <ProtectedRoute exact path="/selection" component={Selection} />
                <ProtectedRoute exact path="/selectionCorp" component={CorpHardware} />
                <ProtectedRoute exact path="/selectionField" component={FieldHardware} />
                <AdminRoute exact path="/admin/adminUserAddPage" component={AdminUserAddComponent} />
                <AdminRoute exact path="/admin/adminPOCAddPage" component={AdminPOCAddComponent} />
                <AdminRoute path="/admin/adminUserEditPage" component={AdminUserEditComponent} />
                <AdminRoute exact path="/admin/adminPage" component={AdminTabPageComponent} />
                <AdminRoute exact path="/admin/AdminRegisteredUserPage" component={AdminRegisteredUserPage} />
                <AdminRoute exact path="/admin/adminOfficeListingPage" component={AdminOfficeListing} />
                <AdminRoute path="/admin/adminOfficeEditPage" component={AdminOfficeEditComponent} />
                <AdminRoute exact path="/admin/adminHardwarePage" component={AdminHardware} />
                <AdminRoute exact path="/admin/adminDeviceAddPage" component={AdminDeviceAddComponent} />
                <AdminRoute path="/admin/adminDeviceEditPage" component={AdminDeviceEditComponent} />
                <AdminRoute exact path="/welcomepage" component={WelcomePage} />
                <AdminRoute path="/admin/adminResetUserPage" component={AdminResetUserComponent} />
                <AdminRoute exact path="/ManageCalendar" component={ManageCalendar} />
                <AdminRoute path="/admin/adminUserReservationDetailPage" component={AdminUserReservationDetailComponent} />
                <ProtectedRoute exact path="/optout" exact component={OptOut} />
                <ReviewRoute exact path="/reviewoptout" exact component={ReviewOptOut} />
                <Route exact path="/optoutconfirmation" exact component={OptOutConfirmation} />
                <ReviewRoute exact path="/deadlinePage" exact component={Deadline} />
            </Switch>
        </Router> 
    )
}
export default App;

