import React, { Component } from 'react';
import { Form, Input, Grid, Button, TextArea, Dimmer, Loader } from 'semantic-ui-react'
import './OptOut.css';

import { authContext } from '../adalConfig';
import { connect } from 'react-redux';
import { authAxios } from '../utils';
import { GetEmployeeInformationURL, GetOfficeListingPointOfContactURL, InsertPatchEmployeeAsyncURL, EmployeeOptOutURL } from '../constants';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter';
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter';


const countryOptions = [
    {
        key: 'US',
        text: 'US',
        value: 'US',
    },
    {
        key: 'Canada',
        text: 'Canada',
        value: 'Canada',
    }
]




const deliveryOfficeOptions = [
    {
        key: 'New York',
        text: 'New York',
        value: 'New York',
    },
    {
        key: 'Chicago',
        text: 'Chicago',
        value: 'Chicago',
    }
]

let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
let name = authContext.getCachedUser() ? authContext.getCachedUser().profile.name : '';

class OptOut extends Component {

    constructor(props) {
        super(props);
        this.handleClickOptOut = this.handleClickOptOut.bind(this);
        this.handleCalClickBack = this.handleCalClickBack.bind(this);
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
        this.handleDeliveryOfficeChange = this.handleDeliveryOfficeChange.bind(this);
        this.handleHomeOfficeChange = this.handleHomeOfficeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleHeaderSelection = this.handleHeaderSelection.bind(this);
        this.handleFooterSelection = this.handleFooterSelection.bind(this);
        this.handleComment = this.handleComment.bind(this);
    }

    state = {
        key: '',
        name: '',
        email: '',
        deliveryOffice: '',
        telephoneBusiness: '',
        telephoneAlternate: '',
        homeOffice: '',
        country: '',
        city: '',
        citiesCostCenters: [],
        citiesCostCentersHomeOffice: [],
        softwarePackage: '',
        error: false,
        userRole: '',
        optoutComments: '',
        loading: false,
        officeListingPointOfContact: {},
        key: ''
    }

    updateOfficeListingCostCenterOptions(source) {
        if (this.state.userRole === 'PROTIVITI') {
            let sink = source.map((x, index) => {
                return ({
                    key: x.city,
                    text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                    value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                })
            });
            return sink;
        }
        else if (this.state.userRole === 'FIELD') {
            let sink = source.map((x, index) => {
                return ({
                    key: x.city,
                    text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                    value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                })
            });
            return sink;
        }
        else {
            let sink = source.map((x, index) => {
                return ({
                    key: x.city,
                    text: x.city,
                    value: x.city
                })
            });
            return sink;
        }
    }



    componentDidMount() {
        // This method is called when the component is first added to the document
        this.setState({
            loading: true
        })
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    email: data.email, name: data.name, deliveryOffice: data.deliveryOffice,
                    telephoneBusiness: data.telephoneBusiness, telephoneAlternate: data.telephoneAlternate,
                    homeOffice: data.homeOffice, key: data.key, softwarePackages: data.softwarePackages,
                    country: data.country, userRole: data.userRole, loginName: loginNameWithDomain.split('@').length > 1 ? loginNameWithDomain.split('@')[0] : '',
                    optoutComments: this.isBase64(data.optoutComments) ? atob(data.optoutComments) : data.optoutComments, officeListingPointOfContact: data.officeListingPointOfContact, key: data.key
                });


                if (this.state.telephoneBusiness) {
                    this.setState({ telephoneBusiness: Number(this.state.telephoneBusiness.replace(/[^0-9]/g, '')) });
                }
             
                authAxios.get(GetOfficeListingPointOfContactURL + `?role=${this.state.userRole}`)
                    .then(response => {
                        let data = response.data;
                        let citiesCostCentersHomeOffice = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                        citiesCostCentersHomeOffice.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i);

                        let citiesCostCenters = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                        citiesCostCenters.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i)

                        if (this.state.userRole === 'CORPORATE') {
                            citiesCostCentersHomeOffice = citiesCostCentersHomeOffice.filter(_ => _.city == 'Deer Park' || _.city == 'Menlo Park' || _.city == 'San Ramon' || _.city == 'Pleasanton' || _.city == 'Other');
                            citiesCostCenters = citiesCostCenters.filter(_ => _.city == 'Deer Park' || _.city == 'Menlo Park' || _.city == 'San Ramon' || _.city == 'Pleasanton' || _.city == 'Other');
                        }

                        this.setState({
                            citiesCostCentersHomeOffice: this.updateOfficeListingCostCenterOptions(citiesCostCentersHomeOffice),
                            citiesCostCenters: this.updateOfficeListingCostCenterOptions(citiesCostCenters),
                        });
                        if (this.state.userRole === 'PROTIVITI') {
                            this.state.citiesCostCenters.push({
                                key: 'San Ramon',
                                text: '(90000) San Ramon - US',
                                value: '(90000) San Ramon - US',
                            })
                            this.state.citiesCostCentersHomeOffice.push({
                                key: 'San Ramon',
                                text: '(90000) San Ramon - US',
                                value: '(90000) San Ramon - US',
                            })
                        }

                        let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice);
                        let costCenterFromEmployeeHomeOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.homeOffice);
                        if (costCenterFromEmployeeDeliveryOffice.length > 0) {
                            this.setState({
                                deliveryOffice: costCenterFromEmployeeDeliveryOffice[0].value
                            })
                        }
                        else {
                            this.setState({
                                deliveryOffice: null
                            })
                        }
                        if (costCenterFromEmployeeHomeOffice.length > 0) {
                            this.setState({
                                homeOffice: costCenterFromEmployeeHomeOffice[0].value
                            })
                        }
                        else {
                            this.setState({
                                homeOffice: null
                            })
                        }

                        if (this.state.userRole === 'PROTIVITI') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                        }
                        else if (this.state.userRole === 'CORPORATE') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text > b.text) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text > b.text) ? 1 : -1);
                        }
                        else if (this.state.userRole === 'FIELD') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                        }
                        if (this.state.deliveryOffice == 'Other') {
                            this.setState({
                                deliveryOffice: 'Other'
                            })
                        }
                        if (this.state.homeOffice == 'Other') {
                            this.setState({
                                homeOffice: 'Other'
                            })
                        }

                        if (this.state.homeOffice == null && this.state.deliveryOffice == null) {
                            let office = this.state.citiesCostCenters.filter(_ => _.key == this.state.officeListingPointOfContact.city);
                            if (office.length > 0) {
                                this.setState({
                                    homeOffice: office[0].value,
                                    deliveryOffice: office[0].value
                                })
                            }
                        }


                        this.setState({
                            loading: false
                        });
                    }, error => {
                        console.log(error);
                        this.setState({
                            loading: false
                        });
                    });
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });


    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }


    componentDidUpdate() {
        // This method is called when the route parameters change
        //this.ensureDataFetched();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleDeliveryOfficeChange = (e, { value }) => {
        let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice);
        if (costCenterFromEmployeeDeliveryOffice.length > 1) {
            costCenterFromEmployeeDeliveryOffice = costCenterFromEmployeeDeliveryOffice[0];
        }
        this.setState({
            deliveryOffice: value,
            error: false
        })
    }

    handleHomeOfficeChange = (e, { value }) => {
        let costCenterFromEmployeeHomeoffice = this.state.citiesCostCentersHomeOffice.filter(_ => _.key == this.state.homeOffice);
        if (costCenterFromEmployeeHomeoffice.length > 1) {
            costCenterFromEmployeeHomeoffice = costCenterFromEmployeeHomeoffice[0];
        }
        this.setState({
            homeOffice: value,
            error: false
        })
    }

    handleCountryChange = (e, { value }) => this.setState({ country: value })


  handleCommentChange(e) {
      this.setState({
          optoutComments: e.target.value,
            error: false
        })
    }


    ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        this.props.requestWeatherForecasts(startDateIndex);
    }



    Usererrormessage = () => {
        return (
            <React.Fragment>
                {this.state.error &&
                    <h2 className="errorreviewUI" >
                        Error: You must complete all required fields.
                    </h2>
                }
            </React.Fragment>
        )
    }

    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHHeader />;
        else if (this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHFooter />;
        else if (this.state.userRole === 'FIELD')
            return <RHFooter />;
    }


    handleComment() {
       
            return (
                <div className='CorpFields'>
                    <Form className='userComments'>
                        <label> <span style={{ color: "red", fontWeight: "bold"}}>* Please state your reason for opting out:</span></label>
                        <Form.Field
                            width={6}
                            id='form-textarea-control-opinion'
                            control={TextArea}
                            value={this.state.optoutComments}
                            required
                            error={this.state.error}
                            onChange={(e) => this.handleCommentChange(e)}
                        />
                    </Form>
                </div>
            )
    }

   


    handleClickOptOut = async () => {

        if (!this.state.optoutComments) {
                this.setState({ error: true });
                return;
            }
        
        if (this.state.deliveryOffice && this.state.homeOffice && this.state.country) { 
           
            if (this.state.homeOffice != 'Other' && this.state.userRole === 'PROTIVITI') {
              
            }
            let empDTO = {
                email: this.state.email, name: this.state.name, loginNameWithDomain: loginNameWithDomain,
                loginName: loginNameWithDomain.split('@').length > 1 ? loginNameWithDomain.split('@')[0] : '',
                telephoneAlternate: this.state.telephoneAlternate,
                OptoutComments: btoa(this.state.optoutComments)
            }
            let results = [];
            for (const property in empDTO) {
                results.push({
                    "op": "replace",
                    "path": `${property}`,
                    "value": `${empDTO[property]}`
                })
            }            
            authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                results
            )
                .then(response => {
                    console.log(response)
                    if (this.state.userRole === 'PROTIVITI')
                        this.props.history.push('./reviewoptout');

                    else if (this.state.userRole === 'CORPORATE')
                        this.props.history.push('./reviewoptout')

                    else if (this.state.userRole === 'FIELD')
                        this.props.history.push('./reviewoptout')
                })
                .catch((error) => {
                    console.log('error: ' + error);
                });
        } else {
            this.setState({ error: true });

        }
    }


        
        



    handleCalClickBack() {
        if (this.state.userRole === 'PROTIVITI')
            this.props.history.push('./');
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD' )
            this.props.history.push('./')
    }


    handleGoToHelp() {
        this.props.history.push('./help');
    }



    render() {
        const { telephoneBusiness, name, email } = this.state;
        if (this.state.loading) {
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting Employee Information...</Loader>
                    </Dimmer>
                </div>
            )
        }
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    {this.handleHeaderSelection()}
                    <h1 className="headerclass">Opt-out</h1>
                    <h2 className="Optouttext">If you do NOT wish to upgrade to a new Robert Half computer, please fill out the form below and submit to Opt-out.</h2>
                    <h2 className="Optout">Please Note: By selecting this option, you will no longer be taking part in the refresh program: you will no longer receive upgrade emails, and will not be able to access the upgrade site.</h2>
                    <h2 className="Required">* Required Field</h2>
                     
                    <Form id="form_id" name="form" >

                        <Grid centered className="optoutfields">
                            <Grid.Row className="optoutfields">
                                <Grid.Column stretched >
                                    <Form.Field inline >
                                        <label>Name: </label>
                                        <Input name='name' className='inputWidth' value={name} readOnly onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                    <Form.Field inline >
                                        <label>Email: </label>
                                        <Input name='email' className='inputWidth' value={email} readOnly onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                    <Form.Field inline >
                                        <label>Telephone <br/>(business): </label>
                                        <Input name='telephoneBusiness' className='inputWidthOptOut' value={telephoneBusiness} readOnly  onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                   
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>

                        {this.Usererrormessage()}
                        {this.handleComment()}


                        <Grid centered>
                           
                            <Grid.Row>
                                <Button color="green" type="submit" className="nextoptout" onClick={this.handleClickOptOut}
                                > Submit   <i class="right arrow icon"></i></Button>
                            </Grid.Row>
                            <Grid.Row>
                                <Button
                                    type='submit'
                                    color="green"
                                    onClick={this.handleCalClickBack}
                                    className="backoptout">
                                    Back to Registration Site
                                </Button>
                            </Grid.Row>

                            <Grid.Row>
                                <Button className="helpbuttonuser" onClick={this.handleGoToHelp}>Help</Button>
                            </Grid.Row>

                           
                              
                          
                        </Grid>
                    </Form>

                </div>
                {this.handleFooterSelection()}
            </div>

        );
    }
}


export default  (OptOut);

