import React, { Component } from 'react';
import { Form, Input, Grid, Button, TextArea, Dimmer, Loader } from 'semantic-ui-react'
import './UserInformation.css';
import { authContext } from '../adalConfig';
import { connect } from 'react-redux';
import { authAxios } from '../utils';
import { GetEmployeeInformationURL, GetOfficeListingPointOfContactURL, InsertPatchEmployeeAsyncURL } from '../constants';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter';
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter';
import { MDBAutocomplete } from 'mdbreact';


const countryOptions = [
    {
        key: 'US',
        text: 'US',
        value: 'US',
    },
    {
        key: 'Canada',
        text: 'Canada',
        value: 'Canada',
    }
]




const deliveryOfficeOptions = [
    {
        key: 'New York',
        text: 'New York',
        value: 'New York',
    },
    {
        key: 'Chicago',
        text: 'Chicago',
        value: 'Chicago',
    }
]

let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
let name = authContext.getCachedUser() ? authContext.getCachedUser().profile.name : '';

class UserInformation extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
        this.handleDeliveryOfficeChange = this.handleDeliveryOfficeChange.bind(this);
        this.handleHomeOfficeChange = this.handleHomeOfficeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleHeaderSelection = this.handleHeaderSelection.bind(this);
        this.handleFooterSelection = this.handleFooterSelection.bind(this);
        //this.handleCorpFloor = this.handleCorpFloor.bind(this);
        this.handleComment = this.handleComment.bind(this);
       
        //this.isBase64 = this.isBase64.bind(this);
        //this.handleCubicleChange = this.handleCubicleChange.bind(this);

        // this.checkDropdownValue = this.checkDropdownValue.bind(this);
    }

    state = {
        key: '',
        name: '',
        email: '',
        deliveryOffice: '',
        telephoneBusiness: '',
        telephoneAlternate: '',
        homeOffice: '',
        country: '',
        city: '',
        citiesCostCenters: [],
        citiesCostCentersHomeOffice: [],
        softwarePackage: '',
        error: false,
        userRole: '',
        /*CubicleNumber: '',*/
        userComments: '',
        loading: false,
        officeListingPointOfContact: {},
        key: '',
      
    }

    /*
    getEmployeeInformation = (employeeInformation) => {
        let data = employeeInformation.map((x, index) => {
            return ({
                'name': x.name,
                'email': x.email,
                'telephoneBusiness': x.telephoneBusiness,
                'telephoneAlternate': x.telephoneAlternate,
                'CubicleNumber': x.CubicleNumber,

               
            })
        });
        return data;
    }

    getOfficeListingData(officelisting) {
        let data = officelisting.map((x, index) => {
            return ({
                'city': x.city,
         
            })
        });
        return data;
    }
    */

    updateOfficeListingCostCenterOptions(source) {
        if (this.state.userRole === 'PROTIVITI') {
            let sink = source.map((x, index) => {

                return ({
                    key: x.city,
                    text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                    value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                })

            });
            return sink;
        }
        else if (this.state.userRole === 'FIELD') {
            let sink = source.map((x, index) => {
                return ({
                    key: x.city,
                    text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                    value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                })
            });
            return sink;
        }
        else {
            let sink = source.map((x, index) => {
                return ({
                    key: x.city,
                    text: x.city,
                    value: x.city
                })
            });
            return sink;
        }
    }



    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        this.setState({
            loading: true
        })
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    email: data.email, name: data.name, deliveryOffice: data.deliveryOffice,
                    telephoneBusiness: data.telephoneBusiness, telephoneAlternate: data.telephoneAlternate,
                    homeOffice: data.homeOffice, key: data.key, softwarePackages: data.softwarePackages,
                    country: data.country, userRole: data.userRole, /*CubicleNumber: data.cubicleNumber,*/
                    userComments: this.isBase64(data.userComments) ? atob(data.userComments) : data.userComments, loginName: loginNameWithDomain.split('@').length > 1 ? loginNameWithDomain.split('@')[0] : '',
                    officeListingPointOfContact: data.officeListingPointOfContact, key: data.key
                });


                if (this.state.telephoneBusiness) {
                    this.setState({ telephoneBusiness: Number(this.state.telephoneBusiness.replace(/[^0-9]/g, '')) });
                }
                if (this.state.userRole == 'PROTIVITI') {
                    this.setState({
                        country: data.country
                    })
                }
                authAxios.get(GetOfficeListingPointOfContactURL + `?role=${this.state.userRole}`)
                    .then(response => {
                        let data = response.data;
                        let citiesCostCentersHomeOffice = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                        citiesCostCentersHomeOffice.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i);

                        let citiesCostCenters = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                        citiesCostCenters.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i)

                        if (this.state.userRole === 'CORPORATE' ) {
                            citiesCostCentersHomeOffice = citiesCostCentersHomeOffice.filter(_ => _.city == 'Deer Park' || _.city == 'Menlo Park' || _.city == 'San Ramon' || _.city == 'Pleasanton' || _.city == 'Other');
                            citiesCostCenters = citiesCostCenters.filter(_ => _.city == 'Deer Park' || _.city == 'Menlo Park' || _.city == 'San Ramon' || _.city == 'Pleasanton' || _.city == 'Remote/Home Location');
                        }
                        
                        //this.setState({
                        //    citiesCostCentersHomeOffice: this.updateOfficeListingCostCenterOptions(citiesCostCentersHomeOffice),
                        //    citiesCostCenters: this.updateOfficeListingCostCenterOptions(citiesCostCenters),
                        //});


                        if (this.state.userRole === 'PROTIVITI') {
                            citiesCostCenters = citiesCostCenters.filter(_ => _.city == 'Other');
                            citiesCostCentersHomeOffice = citiesCostCentersHomeOffice.filter(_ => _.city == 'Remote/Home Location');
                        }


                        if (this.state.userRole === 'FIELD') {
                            citiesCostCenters = citiesCostCenters.filter(_ => _.city =="Remote/Home Location");
                            citiesCostCentersHomeOffice = citiesCostCentersHomeOffice.filter(_ => _.city != 'Remote/Home Location');
                          
                        }
                            
                           //this.state.citiesCostCenters.push({
                           //     key: 'San Ramon',
                           //     text: '(90000) San Ramon - US',
                           //     value: '(90000) San Ramon - US',

                           // })
                           // this.state.citiesCostCentersHomeOffice.push({
                           //     key: 'San Ramon',
                           //     text: '(90000) San Ramon - US',
                           //     value: '(90000) San Ramon - US',
                           // })
                        

                        this.setState({
                            citiesCostCentersHomeOffice: this.updateOfficeListingCostCenterOptions(citiesCostCentersHomeOffice),
                            citiesCostCenters: this.updateOfficeListingCostCenterOptions(citiesCostCenters),
                        });


                        let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice );
                        let costCenterFromEmployeeHomeOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.homeOffice);
                        if (costCenterFromEmployeeDeliveryOffice.length > 0) {
                            this.setState({
                                deliveryOffice: costCenterFromEmployeeDeliveryOffice[0].value
                            })
                        }
                        else {
                            this.setState({
                                deliveryOffice: null
                            })
                        }
                        if (costCenterFromEmployeeHomeOffice.length > 0) {
                            this.setState({
                                homeOffice: costCenterFromEmployeeHomeOffice[0].value
                            })
                        }
                        else {
                            this.setState({
                                homeOffice: null
                            })
                        }

                        if (this.state.userRole === 'PROTIVITI') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                        }
                        else if (this.state.userRole === 'CORPORATE') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text > b.text) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text > b.text) ? 1 : -1);
                        }
                        else if (this.state.userRole === 'FIELD') {
                            this.state.citiesCostCentersHomeOffice.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                            this.state.citiesCostCenters.sort((a, b) => (a.text.substring(7) > b.text.substring(7)) ? 1 : -1);
                        }

                        //if (this.state.userRole === 'CORPORATE') {
                        //    this.state.citiesCostCenters.push({
                        //        key: 'Other',
                        //        text: 'Other',
                        //        value: 'Other',
                        //    })
                        //    this.state.citiesCostCentersHomeOffice.push({
                        //        key: 'Other',
                        //        text: 'Other',
                        //        value: 'Other',
                        //    })
                        //}
                        if (this.state.deliveryOffice == 'Remote/Home Location') {
                            this.setState({
                                deliveryOffice: 'Remote/Home Location'
                            })
                        }
                        if (this.state.homeOffice == 'Other') {
                            this.setState({
                                homeOffice: 'Other'
                            })
                        }

                        if (this.state.homeOffice == null && this.state.deliveryOffice == null) {
                            let office = this.state.citiesCostCenters.filter(_ => _.key == this.state.officeListingPointOfContact.city);
                            if (office.length > 0) {
                                this.setState({
                                    homeOffice: office[0].value,
                                    deliveryOffice: office[0].value
                                })
                            }
                        }


                        this.setState({
                            loading: false
                        });
                    }, error => {
                        console.log(error);
                        this.setState({
                            loading: false
                        });
                    });
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });


    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }


    componentDidUpdate() {
        // This method is called when the route parameters change
        //this.ensureDataFetched();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleDeliveryOfficeChange = (e, { value }) => {
        let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice);
        if (costCenterFromEmployeeDeliveryOffice.length > 1) {
            costCenterFromEmployeeDeliveryOffice = costCenterFromEmployeeDeliveryOffice[0];
        }
        this.setState({
            deliveryOffice: value,
            error: false
        })
    }

    handleHomeOfficeChange = (e, { value }) => {
        let costCenterFromEmployeeHomeoffice = this.state.citiesCostCentersHomeOffice.filter(_ => _.key == this.state.homeOffice);
        if (costCenterFromEmployeeHomeoffice.length > 1) {
            costCenterFromEmployeeHomeoffice = costCenterFromEmployeeHomeoffice[0];
        }
        this.setState({
            homeOffice: value,
            error: false
        })
    }

    handleCountryChange = (e, { value }) => this.setState({ country: value })




    //handleCubicleChange(e) {
    //    this.setState({ CubicleNumber: e.target.value, error: false })
    //}

    handleCommentChange(e) {
        this.setState({
            userComments: e.target.value,
            error: false
        })
    }



    ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        this.props.requestWeatherForecasts(startDateIndex);
    }


    checkDropdownValue = () => {
        if (!this.state.country || !this.state.deliveryOffice || !this.state.homeOffice) {
            this.setState({
                error: true
            })
        } else {
            this.setState({
                error: false
            })
        }

    }


    Usererrormessage = () => {
        return (
            <React.Fragment>
                {this.state.error &&
                    <h2 className="errorreviewUI" >
                        Error: You must make a selection for all the required fields.
                    </h2>
                }
            </React.Fragment>
        )
    }

    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHHeader />;
        else if (this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter />;
        else if (this.state.userRole === 'CORPORATE')
            return <RHFooter />;
        else if (this.state.userRole === 'FIELD')
            return <RHFooter />;
    }


    handleComment() {
        if (this.state.homeOffice && this.state.homeOffice.includes('Other') || this.state.deliveryOffice && (this.state.deliveryOffice.includes('Remote/Home Location') || this.state.deliveryOffice.includes('Other') ))
            return(
                <div className='CorpFields'>
                    <Form className='userComments'>
                        <label style={{ fontWeight: "bold" }}>Alternate Delivery Location: <span style={{ color: "red", fontWeight: "bold", fontStyle: "italic"}}><br />*If you selected "Remote/Home Location", please enter street address, state, and zipcode.</span></label>
                        <Form.Field
                            width={7}
                            id='form-textarea-control-opinion'
                            control={TextArea}
                            value={this.state.userComments}
                            required
                            error={this.state.error}
                            onChange={(e) => this.handleCommentChange(e)}
                        />
                    </Form>
                </div>
            )
    }




    //handleCorpFloor() {
    //    if (this.state.userRole === 'CORPORATE' && this.state.homeOffice != 'Other' && this.state.deliveryOffice != 'Other')

    //        return (
    //            <React.Fragment>

    //                <Form.Field inline error={this.state.error} required className='dropdown1'>
    //                    <label>
    //                        Cube Number:
    //                    </label>
    //                    <Input type="number"
    //                        onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
    //                        value={this.state.CubicleNumber}
    //                        onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5) }}
    //                        onChange={(e) => this.handleCubicleChange(e)}
    //                        className='inputWidth'
    //                    />

    //                </Form.Field>
    //            </React.Fragment>
    //        )   
    //}


    handleClick = async () => {
        let isUserCommentsNeeded = (this.state.deliveryOffice.includes('Remote/Home Location') || this.state.homeOffice.includes('Other'));
        if (isUserCommentsNeeded) {
            if (!this.state.userComments) {
                this.setState({ error: true });
                return;
            }
        }
        //let needCubicle = this.state.userRole === 'CORPORATE' && this.state.deliveryOffice != 'Other' && this.state.homeOffice != 'Other' ? this.state.CubicleNumber : 'none';
        if (this.state.deliveryOffice && this.state.homeOffice && this.state.country /*&& needCubicle*/) {
            if (this.state.deliveryOffice != 'Remote/Home Location' && this.state.userRole === 'PROTIVITI') {
                this.state.deliveryOffice = this.state.deliveryOffice.split("-")[0].split(")")[1].trim(); // Example "New York - 11111", take only New York and remove whitespace at the end of string New York
            }
            if (this.state.deliveryOffice != 'Remote/Home Location' && this.state.userRole === 'FIELD') {
                this.state.deliveryOffice = this.state.deliveryOffice.split("-")[0].split(")")[1].trim();
            }
            if (this.state.homeOffice != 'Other' && this.state.userRole === 'PROTIVITI') {
                this.state.homeOffice = this.state.homeOffice.split("-")[0].split(")")[1].trim();
            }
            if (this.state.homeOffice != 'Other' && this.state.userRole == 'FIELD') {
                this.state.homeOffice = this.state.homeOffice.split("-")[0].split(")")[1].trim();
            }
            let empDTO = {
                email: this.state.email, name: this.state.name, deliveryOffice: this.state.deliveryOffice, loginNameWithDomain: loginNameWithDomain,
                loginName: loginNameWithDomain.split('@').length > 1 ? loginNameWithDomain.split('@')[0] : '',
                telephoneBusiness: this.state.telephoneBusiness, telephoneAlternate: this.state.telephoneAlternate,
                homeOffice: this.state.homeOffice, country: this.state.country, softwarePackages: this.state.softwarePackages, CubicleNumber: this.state.CubicleNumber,
                UserComments: btoa(this.state.userComments)
            }
            let results = [];
            for (const property in empDTO) {
                results.push({
                    "op": "replace",
                    "path": `${property}`,
                    "value": `${empDTO[property]}`
                })
            }
            authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                results
            )
                .then(response => {
                    console.log(response)
                    if (this.state.userRole === 'PROTIVITI')
                        this.props.history.push('./selection');

                    else if (this.state.userRole === 'CORPORATE')
                        this.props.history.push('./selectioncorp')

                    else if (this.state.userRole === 'FIELD')
                        this.props.history.push('./selectionfield')
                })
                .catch((error) => {
                    console.log('error: ' + error);
                });


        } else {
            this.setState({ error: true });

        }
    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    handleShowCountry() {
        if (this.state.userRole === 'CORPORATE' || this.state.userRole == 'FIELD') {
            return (
                <React.Fragment>
                    <Form.Dropdown
                        inline
                        selection
                        options={countryOptions}
                        label='Country'
                        className="dropdown1"
                        name='country'
                        onChange={this.handleCountryChange}
                        onblur="validate()"
                        required
                        error={this.state.error}
                        value={this.state.country}
                    />
                </React.Fragment>
            )
        }
    }

    handleCorpCovid() {
        if (this.state.userRole === 'CORPORATE') {
            return (
                <React.Fragment>
                    <h2 className="Covid" >
                        Due to COVID-19, if you are working from home, please select the 'Remote/Home' option for the Delivery Office.
                    </h2>
                    <h2 className="Pickup" >
                        If you would like to pick up your computer from the office, please follow the COVID-19 Building Access Guidelines provided by Robert Half.
                    </h2>
                </React.Fragment>)
        }
    }

    handleProCovid() {
        if (this.state.userRole === 'PROTIVITI') {
            return (
                <React.Fragment>
                    <h2 className="Covid" >
                        Due to COVID-19, if you are working from home, please select the 'Remote/Home' option for the Delivery Office.
                    </h2>
                </React.Fragment>)
        }
    }

    handleFieldCovid() {
        if (this.state.userRole === 'FIELD') {
            return (
                <React.Fragment>
                    <h2 className="Covid" >
                        Due to COVID-19, if you are working from home, please select the 'Remote/Home' option for the Delivery Office.
                    </h2>
                </React.Fragment>)
        }
    }


    

    render() {

        const { citiesCostCentersHomeOffice, citiesCostCenters, telephoneBusiness, name, email, telephoneAlternate, deliveryOffice, homeOffice, country, city, CubicleNumber } = this.state;
        if (this.state.loading) {
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting Employee Information...</Loader>
                    </Dimmer>
                </div>
            )
        }
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    {this.handleHeaderSelection()}
                    <h1 className="headerclass">User Information</h1>

                    <Form id="form_id" name="form" >

                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column stretched className="leftcolumn">
                                    <Form.Field inline className="leftforms">
                                        <label>Name: </label>
                                        <Input name='name' className='inputWidth' value={name} readOnly onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                    <Form.Field inline className="leftforms">
                                        <label>Email: </label>
                                        <Input name='email' className='inputWidth' value={email} readOnly onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                    <Form.Field inline className="leftforms">
                                        <label>Telephone (business): </label>
                                        <Input name='telephoneBusiness' className='inputWidth' value={telephoneBusiness} readOnly onChange={(e) => this.handleChange(e)} />
                                    </Form.Field>
                                    <Form.Field inline className="leftforms">
                                        <label>Telephone (alternate): </label>
                                        <Input
                                            name='telephoneAlternate'
                                            type="number"
                                            error={this.state.error}
                                            onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 11) }}
                                            value={telephoneAlternate}
                                            onChange={(e) => this.handleChange(e)}
                                            className='inputWidth'
                                        />

                                    </Form.Field>
                                </Grid.Column>



                                <Grid.Column stretched className="rightcolumn">

                                    {this.handleShowCountry()}


                                    <Form.Dropdown
                                        defaultValue={this.state.homeOffice}
                                        inline
                                        //control={Dropdown}
                                        label='Home Office Location'
                                        selection
                                        
                                        
                                        className="dropdown2"
                                        name='homeOffice'
                                        value={homeOffice}
                                        options={citiesCostCentersHomeOffice.sort()}
                                        onChange={this.handleHomeOfficeChange}
                                        error={this.state.error}
                                        search
                                      
                                      
                                        required
                                    />

                                    <Form.Dropdown
                                        defaultValue={this.state.deliveryOffice}
                                        inline
                                        //control={Dropdown}
                                        label='Delivery Location'
                                       search
                                        selection
                                        className="dropdown2"
                                        name='deliveryOffice'
                                        value={deliveryOffice}
                                        options={citiesCostCenters}
                                        onChange={this.handleDeliveryOfficeChange}
                                        error={this.state.error}
                                        required
                                    />

                                    {/*this.handleCorpFloor()*/}

                                </Grid.Column>

                            </Grid.Row>

                        </Grid>


                        {this.handleComment()}


                        <Grid centered>
                            <Grid.Row>
                                <Button color="green" type="submit" className="nextbuttonuser" onClick={this.handleClick} onSubmit={this.handle}
                                //disabled={!this.state.deliveryOffice || !this.state.homeOffice}
                                > Next   <i class="right arrow icon"></i></Button>
                            </Grid.Row>
                            <Grid.Row>
                                <Button className="helpbuttonuser" onClick={this.handleGoToHelp}>Help</Button>
                            </Grid.Row>


                        </Grid>



                        {this.Usererrormessage()}

                    </Form>

                </div>
                {this.handleFooterSelection()}
            </div>

        );
    }
}


export default connect()(UserInformation);

//<span id="errfn1" className="errfn1"></span>
//    <span id="errfn" className="errfn"></span>