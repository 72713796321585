import React, { Component } from 'react';
import { Form, TextArea, Input, Grid, Button, Image, Segment, Radio, List, } from 'semantic-ui-react'
import './Selection.css';
import { connect } from 'react-redux';
import { authAxios } from '../../utils';
import { UpdateUserURL, GetEmployeeInformationURL, InsertPatchEmployeeAsyncURL, GetDeviceImagesURL } from '../../constants';
import { authContext } from '../../adalConfig';
import Popup from "reactjs-popup";
import ProHeader from './ProHeader';
import ProFooter from './ProFooter';


let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class Selection extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleLaptopChange = this.handleLaptopChange.bind(this);
        }

    state = {
        value: '',
        userRole: '',
        computerDeviceData: [],
        key: ''
    }

    handleLaptopChange(e) {
        this.setState({
            value: e.target.textContent
        })
    }



    getComputerDevicesData(computerdevices) {
        const indx = computerdevices.findIndex(v => v.name === "Other");
        computerdevices.splice(indx, indx >= 0 ? 1 : 0);
        let data = computerdevices.map((x) => {
            return ({
                'name': x.name,
                'display': x.display,
                'processor': x.processor,
                'ram': x.ram,
                'hardDrive': x.hardDrive,
                'ports': x.ports,
                'weight': x.weight,
                'batterylife': x.batterylife,
                'image': x.imageFile ? "data:image/" + x.imageName.split(".")[1] + ";base64, " + x.imageFile : null

            })
        });
        return data;
    }

    getComputerImageURL(computerData) {
        var data = "";
        for (var index = 0; index < computerData.length - 1; index++) {
            data = data.concat(computerData[index].image + ',');
        }
        data = data.concat(computerData[computerData.length - 1].image);
        return data;
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }

        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                let imageUrls = data.computerDevices.map(_ => _.image);

                authAxios.post(GetDeviceImagesURL, {
                    DeviceNames: imageUrls
                })
                    .then(response1 => {
                        let data1 = response1.data;
                        let newdata = data.computerDevices.map((item, i) => Object.assign({}, item, data1[i]));
                        this.setState({
                            value: data.currentSelectedComputerDevice,
                            userRole: data.userRole,
                            computerDeviceData: this.getComputerDevicesData(newdata),
                            key: data.key
                        }, () => {
                            if (this.state.userRole === 'CORPORATE')
                                this.props.history.push('/selectioncorp')
                            else if (this.state.userRole === 'FIELD')
                                this.props.history.push('/selectionfield')
                            this.setState({
                                loading: false
                            })

                        });
                    }).catch((error) => {
                        console.log('error ' + error);
                        this.setState({
                            errorredirect: true
                        });
                        this.setState({
                            loading: false
                        })
                        this.props.history.push('../unauthorized');
                    })


            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    handleClick() {
        if (this.state.value) {
            let empDTO = {
                CurrentSelectedComputerDevice: this.state.value,
                softwarePackages: btoa(this.state.software),
            }
            let results = [];
            for (const property in empDTO) {
                results.push({
                    "op": "replace",
                    "path": `${property}`,
                    "value": `${empDTO[property]}`
                })
            }
            authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                results
            )
                .then(response => {
                    this.props.history.push('./calendar');
                })
                .catch((error) => {
                    console.log('error: ' + error);
                });
        }
    }

    handleHelpClick = async () => {
        this.props.history.push('./help');
    }

    handleClickBack = async () => {
        this.props.history.push('./userinformation');
    }


    handleNoComputerDevicesAllowed() {
        if (this.state.computerDeviceData.length === 0) {
            return (
                <React.Fragment>
                    <h2 className= 'noDeviceText'>
                        You currently have no allowed devices. <br />
                        Please send us an <a href='mailto:NAProtivitiUpgrades@protiviti.com'>email!</a>
                    </h2>
                </React.Fragment>
            )
        }
    }


    render() {
        return (
            <div className="outer-container">
                <div className='inner-container'>
                    <ProHeader/>

                    <h1 className="h1Select">Computer Selection</h1>
                    <div>
                        <div className='batterywarn'>
                            **Battery life will vary based on many factors including screen brightness, applications, features, power management, battery condition, and other customer preferences.                        
                        </div>
                    </div>
                    {this.handleNoComputerDevicesAllowed()}
                    <Form  required>
                        <Grid columns={2} divided className="selection">
                        
                        {this.state.computerDeviceData.map((item, index) =>
                            (<Grid.Row className="border" key={item.name}>
                                <Grid.Column className="">
                                    <Form.Field className="">
                                        <Radio className='text'
                                            label={item.name}
                                            name='radioGroup'
                                            value={item.name}
                                            checked={this.state.value === item.name}
                                            onChange={(e) => this.handleLaptopChange(e)}
                                        />
                                    </Form.Field>

                                    <Popup modal trigger={
                                        <Image src={item.image} className='laptops' />
                                }>

                                        <Image src={item.image} className='picturepopup' />
                                    </Popup>

                                </Grid.Column>
                                <Grid.Column>
                                    <Grid columns={2} divided className="details">
                                        <Grid.Row className="rowlight">
                                            <Grid.Column>
                                                Display
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.display}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowdark">
                                            <Grid.Column>
                                                Processor
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.processor}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowlight">
                                            <Grid.Column>
                                                Ram
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.ram}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowdark">
                                            <Grid.Column>
                                                Hard Drive
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.hardDrive}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowlight">
                                            <Grid.Column>
                                                Ports
                                        </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                <List bulledted>
                                                    {item.ports}
                                                </List>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowdark">
                                            <Grid.Column>
                                                Weight
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.weight}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="rowlight">
                                            <Grid.Column>
                                                Battery Life
                                            </Grid.Column>
                                            <Grid.Column className="leftalign">
                                                {item.batterylife}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                </Grid.Column>

                            </Grid.Row>))}

                        </Grid>
                    </Form>
                                        
                    <div>
                        <Button color="green" className="backbutton2" onClick={this.handleClickBack}>   <i class="left arrow icon"></i>Back</Button>
                        <Button
                            type="submit"
                            color="green"
                            width="50"

                            className="calnextbutton"
                            onClick={this.handleClick}
                            disabled={!this.state.value} >Next  <i class="right arrow icon"></i></Button>

                 

                    </div>

                    <div>   <Button className="helpbutton2" onClick={this.handleHelpClick}> Help</Button>
                    </div>
                </div>
                <ProFooter />
            </div>
            

        );
    }
}

export default connect()(Selection);
