import React, { Component } from 'react';
import { Form, TextArea, Grid, Button } from 'semantic-ui-react'
import './Software.css';
import { connect } from 'react-redux';
import { authAxios } from '../../utils';
import { GetEmployeeInformationURL, InsertPatchEmployeeAsyncURL } from '../../constants';
import { authContext } from '../../adalConfig';
import Popup from "reactjs-popup";
import CorpHeader from './RHHeader';
import CorpFooter from './RHFooter';


let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class Software extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleSoftwareChange = this.handleSoftwareChange.bind(this);
    }

    state = {
        software1: '- Acrobat Reader \n' + '- Office 365: \n\t(Includes: Word, Outlook, Teams, Excel, PowerPoint, OneNote, Access, Publisher, and OneDrive) \n' + '- Edge Browser \n',
        software2: '',
        key: '',
        isSubmitted: false
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    software2: this.isBase64(data.softwarePackages) ? atob(data.softwarePackages) : data.softwarePackages,
                    key: data.key, isSubmitted: data.isSubmitted
                })
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    handleClick() {
        let empDTO = {
            softwarePackages: btoa(this.state.software2),
        }
        let results = [];
        for (const property in empDTO) {
            results.push({
                "op": "replace",
                "path": `${property}`,
                "value": `${empDTO[property]}`
            })
        }
        authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
            results
        )
            .then(response => {
                this.props.history.push('./review');
            })
            .catch((error) => {
                console.log('error: ' + error);
                authContext.login();
                this.handleClick();
            });
    }

    handleHelpClick = async () => {
        this.props.history.push('./help');
    }

    handleClickBack = async () => {
        if (this.state.isSubmitted == true)
            this.props.history.push('./review')
        else
            this.props.history.push('./calendar');
    }

    handleSoftwareChange(e, value) {
        this.setState({
            software2: value.value
        })
    }

    render() {

        return (
            <div className="outer-container">
                <div className='inner-container'>
                    <CorpHeader />

                        <Grid divided>
                            <Grid.Row>
                               <Grid.Column width={16}>
                                <h1 className="h1software">Software Information</h1>
                                <h2 className="h3software">Please note you will be able to return to this page after registration.</h2>
                                <br />
                            </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                               <Grid.Column width={5}>
                                <h2 className="installedSoftwareHeader">Software Installed on the Upgrade Computer:</h2>
                                <h2 className="h2Asoftware">- Edge Browser</h2>
                                <h2 className="h2Asoftware">- Office 365 includes:</h2>
                                <h2 className="officeProducts">  - Word</h2>
                                <h2 className="officeProducts">  - Outlook</h2>
                                <h2 className="officeProducts">  - Teams</h2>
                                <h2 className="officeProducts">  - Excel</h2>
                                <h2 className="officeProducts">  - PowerPoint</h2>
                                <h2 className="officeProducts">  - OneNote</h2>
                                <h2 className="officeProducts">  - Access</h2>
                                <h2 className="officeProducts">  - Publisher</h2>
                                <h2 className="officeProducts">  - OneDrive</h2>
                               </Grid.Column>

                               <Grid.Column width={6}>
                                   <h2 className="softwareLinksHeader">Helpful Links to Install Popular Software:</h2>

                                   <p className="softwarelink"><a href="https://rhiprod.service-now.com/now/nav/ui/classic/params/target/kb_view.do%3Fsys_kb_id%3D0e7cd86f877cd990041597d83cbb3593" target="_blank" rel="noopener noreferrer">How to update Visio, SnagIt and other software</a></p>
                                   <p className="softwarelink"><a href="https://rhiprod.service-now.com/now/nav/ui/classic/params/target/kb_view.do%3Fsys_kb_id%3Db90ecbac876fdd544ad5eca73cbb354e" target="_blank" rel="noopener noreferrer">How to install Adobe Acrobat Standard or Pro</a></p>
                                   <p className="softwarelink"><a href="https://support.microsoft.com/en-us/office/install-visio-or-access-visio-for-the-web-f98f21e3-aa02-4827-9167-ddab5b025710#:~:text=1%20From%20office.com%20select%20Install%20Office%20and%20make,%28If%20you%20have%20any%20other%20Office%20apps%20already" target="_blank" rel="noopener noreferrer">Microsoft Visio Plan 2 install instructions</a></p>
                                   <p className="softwarelink"><a href="https://support.microsoft.com/en-us/office/install-project-7059249b-d9fe-4d61-ab96-5c5bf435f281" target="_blank" rel="noopener noreferrer">Microsoft Project Plan 3 install instructions</a></p>
                                   <br />
                                   <p className="notsoftwarelink">Adobe Creative Cloud users please follow the link below:</p>
                                   <p className="softwarelink"><a href="https://creativecloud.adobe.com/" target="_blank" rel="noopener noreferrer">Creative Cloud login page</a></p>
                               </Grid.Column>
                                <Grid.Column width={5}>
                                        <h2 className="softwareLinksHeader">Requesting New Software and Licenses:</h2>

                                <p className="softwarelink"><a href="https://rhiprod.service-now.com/rhsp?id=sc_category&catalog_sys_id=e0d08b13c3330100c8b837659bba8fb4" target="_blank" rel="noopener noreferrer">How to request new software</a></p>
                                        <p className="softwarelink"><a href="https://rhiprod.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=0325ba45139faa00e3a4dbf18144b056" target="_blank" rel="noopener noreferrer">Microsoft Visio Plan 2 license request</a></p>
                                        <p className="softwarelink"><a href="https://rhiprod.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=52b4672413d3aa00e3a4dbf18144b06a" target="_blank" rel="noopener noreferrer">Microsoft Project Plan 3 license request</a></p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    <div className='buttons'>
                        <Button color="green" className="backbutton2" onClick={this.handleClickBack}>   <i className="left arrow icon"></i>Back</Button>
                        <Button
                            type="submit"
                            color="green"
                            width="50"

                            className="calnextbutton"
                            onClick={this.handleClick}
                            disabled={this.state.needDock === '1' && !this.state.dock.includes('Dock')}
                        >
                            Next

                        <i className="right arrow icon">
                            </i>
                        </Button>

                    </div>

                    <div>   <Button className="helpbutton2" onClick={this.handleHelpClick}> Help</Button>
                    </div>
                </div>
                <CorpFooter />
            </div>


        );
    }
}

export default connect()(Software);
