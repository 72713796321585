// fetchProducts.js
import { authAxios } from "../utils";
import { authContext } from '../adalConfig';
import { GetUserURL, GetAllEmployeesInformationURL, DeleteEmployeeInformationURL, UpdateUserURL, GetAllSubmittedEmployeesInformationURL } from '../constants';
import {
    fetchUserPending, fetchUserSuccess, fetchUserError,
    fetchAllUsersPending, fetchAllUsersSuccess, fetchAllUsersError,
    deleteUserPending, deleteUserSuccess, deleteUserError,
    createUserPending, createUserSuccess, createUserError,
    fetchAllRegisteredUsersPending, fetchAllRegisteredUsersSuccess, fetchAllRegisteredUsersError
} from '../actions';
import { getAllUsers, getAllUsersPending } from "../reducers/allUsersReducer";
let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';


export const extractDataFromAPIToEmployeeObject = (responses) => {
    return responses.map((x, index) => {
        return ({
            'loginname': x.loginName,
            'loginNameWithDomain': x.loginNameWithDomain,
            'name': x.name,
            'autoenableby': x.autoEnableBy,
            'selecteddevices': x.computerModel,
            'isAuthenticate': x.isAuthenticated,
            'email': x.email,
            'computerDevices': x.computerDevices,
            'softwarePackages': x.softwarePackages,
            'upgradeWeek': x.upgradeWeek,
            'deliveryOffice': x.deliveryOffice,
            'isSubmitted': x.isSubmitted,
            'isAdmin': x.isAdmin,
            'pickUpDate': x.pickUpDate,
            'shipDate': x.shipDate,
            'trackingNumber': x.trackingNumber,
            'estimatedReceiptDate': x.estimatedReceiptDate,
            'currentSelectedComputerDevice': x.currentSelectedComputerDevice,
            'userComments': x.userComments,
            'key': x.key
        })
    });
}

export const fetchUser = () => async (dispatch, getState) => {
    dispatch(fetchUserPending());
    authAxios.post(GetUserURL, {
        LoginNameWithDomain: loginNameWithDomain
    })
        .then(response => {
            let userData = {
                email: response.data.email, accountLogon: response.data.accountLogon, computerModel: response.data.computerModel,
                deliveryOffice: response.data.deliveryOffice, country: response.data.country, isAuthenticated: response.data.isAuthenticated,
                id: response.data.key, level: response.data.level, name: response.data.name, softwarePackages: response.data.softwarePackages,
                telephoneAlternate: response.data.telephoneAlternate, telephoneBusiness: response.data.telephoneBusiness,
                upgradeWeek: response.data.upgradeWeek, loginNameWithDomain: response.data.loginNameWithDomain, isAdmin: response.data.isAdmin,
                pickupDate: response.data.pickUpDate, shipDate: response.data.shipDate, trackingNumber: response.data.trackingNumber,
                estimatedReceiptDate: response.data.estimatedReceiptDate, isSubmitted: response.data.isSubmitted,
                currentSelectedComputerDevice: response.data.currentSelectedComputerDevice
            };
            dispatch(fetchUserSuccess(userData));
            return response;
        })
        .catch(error => {
            dispatch(fetchUserError(error));
        })
}


export const fetchAllUsers = (role = 'CORP') => async (dispatch, getState) => {
    dispatch(fetchAllUsersPending());
    authAxios.post(GetAllEmployeesInformationURL, {
        userRole: role
    })
        .then(response => {
            let employees = extractDataFromAPIToEmployeeObject(response.data);
            dispatch(fetchAllUsersSuccess(employees));
            return response;
        })
        .catch(error => {
            dispatch(fetchAllUsersError(error));
        })
}

export const fetchAllSubmittedUsers = (role = 'CORP') => async (dispatch, getState) => {
    dispatch(fetchAllRegisteredUsersPending());
    authAxios.post(GetAllSubmittedEmployeesInformationURL, {
        userRole: role
    })
        .then(response => {
            let employees = extractDataFromAPIToEmployeeObject(response.data);
            dispatch(fetchAllRegisteredUsersSuccess(employees));
            return response;
        })
        .catch(error => {
            dispatch(fetchAllRegisteredUsersError(error));
        })
}

export const deleteUser = (loginNameWithDomain) => async (dispatch, getState) => {
    dispatch(deleteUserPending());
    authAxios.delete(`${DeleteEmployeeInformationURL}${loginNameWithDomain}`)
        .then(response => {
            let selectedUser = getState().allUsersReducer.users.find(_ => _.loginNameWithDomain == loginNameWithDomain);
            let employees = getState().allUsersReducer.users.splice(getState().allUsersReducer.users.indexOf(selectedUser), 1);
            dispatch(deleteUserSuccess(selectedUser.email));
        }).catch((error) => {
            dispatch(fetchAllUsersError(error));
        });
}






