export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const FETCH_ALL_USERS_PENDING = 'FETCH_ALL_USERS_PENDING';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_ERROR = 'FETCH_ALL_USERS_ERROR';

export const FETCH_ALL_REGISTERED_USERS_PENDING = 'FETCH_ALL_REGISTERED_USERS_PENDING';
export const FETCH_ALL_REGISTERED_USERS_SUCCESS = 'FETCH_ALL_REGISTERED_USERS_SUCCESS';
export const FETCH_ALL_REGISTERED_USERS_ERROR = 'FETCH_ALL_REGISTERED_USERS_ERROR';

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_PENDING = 'FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_PENDING';
export const FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_SUCCESS = 'FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_SUCCESS';
export const FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_ERROR = 'FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_ERROR';

export function fetchAllOfficeListingWithPointOfContactPending() {
    return {
        type: FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_PENDING
    }
}

export function fetchAllOfficeListingWithPointOfContactSuccess(officeListingPointOfContacts) {
    return {
        type: FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_SUCCESS,
        officeListingPointOfContacts: officeListingPointOfContacts
    }
}

export function fetchAllOfficeListingWithPointOfContactError(error) {
    return {
        type: FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_ERROR,
        error: error
    }
}

export function fetchUserPending() {
    return {
        type: FETCH_USER_PENDING
    }
}

export function fetchUserSuccess(user) {
    return {
        type: FETCH_USER_SUCCESS,
        user: user
    }
}

export function fetchUserError(error) {
    return {
        type: FETCH_USER_ERROR,
        error: error
    }
}


export function fetchAllUsersPending() {
    return {
        type: FETCH_ALL_USERS_PENDING
    }
}

export function fetchAllUsersSuccess(users) {
    return {
        type: FETCH_ALL_USERS_SUCCESS,
        users: users
    }
}

export function fetchAllUsersError(error) {
    return {
        type: FETCH_ALL_USERS_ERROR,
        error: error
    }
}

export function fetchAllRegisteredUsersPending() {
    return {
        type: FETCH_ALL_REGISTERED_USERS_PENDING
    }
}

export function fetchAllRegisteredUsersSuccess(registeredUsers) {
    return {
        type: FETCH_ALL_REGISTERED_USERS_SUCCESS,
        registeredUsers: registeredUsers
    }
}

export function fetchAllRegisteredUsersError(error) {
    return {
        type: FETCH_ALL_REGISTERED_USERS_ERROR,
        error: error
    }
}

export function deleteUserPending() {
    return {
        type: DELETE_USER_PENDING
    }
}

export function deleteUserSuccess(userEmail) {
    return {
        type: DELETE_USER_SUCCESS,
        userEmail: userEmail
    }
}

export function deleteUserError(error) {
    return {
        type: DELETE_USER_ERROR,
        error: error
    }
}

export function createUserPending() {
    return {
        type: CREATE_USER_PENDING
    }
}

export function createUserSuccess(userEmail) {
    return {
        type: CREATE_USER_SUCCESS,
        userEmail: userEmail
    }
}

export function createUserError(error) {
    return {
        type: CREATE_USER_ERROR,
        error: error
    }
}



