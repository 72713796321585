import React, { Component } from 'react';
import { Label, Menu, Tab, Image, Button } from 'semantic-ui-react';
import AdminGridComponent from './AdminGridPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAllUsers, deleteUser } from '../../services/userService';
import { getAllUsersError, getAllUsers, getAllUsersPending } from '../../reducers/allUsersReducer';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import AdminRegisteredUserPage from './AdminRegisteredUserPage';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';
import AdminReport from './AdminReport';


class AdminTabPageComponent extends Component {

    constructor(props) {
        super(props)
        this.handleAddNewUser = this.handleAddNewUser.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                activeIndex: this.props.location.state.id,
                employeeData: this.props.location.state.employeeData
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.users && (this.props.users.length != prevProps.users.length)) {
            this.setState({ userLength: this.props.users.length, panes: this.panes});
        }
    }

    state = {
        userLength: null,
        activeIndex: 0,
        employeeData: null,
        //panes: [
        //    {
        //        menuItem: (
        //            <Menu.Item key='AllowedUsers'>
        //                Allowed Users<Label>8</Label>
        //            </Menu.Item>
        //        ),
        //        render: () => <AdminGridComponent />,
        //    },
        //    {
        //        menuItem: (
        //            <Menu.Item key='RegisteredUsers'>
        //                Registered Users<Label>8</Label>
        //            </Menu.Item>
        //        ),
        //        render: () => <AdminRegisteredUserPage />,
        //    },
        //]
        panes: [],
    }

    
    panes = [
        {
            menuItem: (<Menu.Item key='AllowedUsers'>Allowed Users<Label>{this.state.userLength}</Label></Menu.Item>), render: () => <AdminGridComponent  />
        },
        {
            menuItem: (<Menu.Item key='RegisteredUsers'>Registered Users<Label>{this.state.userLength}</Label></Menu.Item>), render: () => <AdminRegisteredUserPage />
        },
        {
            menuItem: (<Menu.Item key='Report'>Report<Label>{this.state.userLength}</Label></Menu.Item>), render: () => <AdminReport/>
        }
    ];

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

    renderTabPanel() {    
        const { activeIndex } = this.state;
        return (
            <Tab panes={this.panes} activeIndex={activeIndex} onTabChange={this.handleTabChange}/>
        );
    }
    
    handleAddNewUser() {
        this.props.history.push(`/admin/AdminUserAddPage`);
    }

    render() {

        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <Button content='Add New User' primary style={{ width: '190px', marginBottom: '20px' }} onClick={this.handleAddNewUser} />
                    {this.renderTabPanel()}
                </div>
                <DualFooter/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllUsersError(state),
    users: getAllUsers(state),
    pending: getAllUsersPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllUsers: fetchAllUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminTabPageComponent);

