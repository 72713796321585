import React, { Component } from 'react';
import { withRouter } from 'react-router';
import backbutton from '../../img/BackButton.PNG';

import { Segment, Button, Grid, Label, } from 'semantic-ui-react'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../../shares/Checkbox';
import { fetchAllUsers, deleteUser } from '../../services/userService';
import { authAxios } from '../../utils';
import { getAllUsersError, getAllUsers, getAllUsersPending } from '../../reducers/allUsersReducer';
import { UpdateEmployeeComputerDeviceURL, GetComputerDevicesLOBURL, GetEmployeeInformationURL, InsertPatchEmployeeAsyncURL, PartialResetEmployeeURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminResetUserPage.css';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';

class AdminResetUserComponent extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
        this.handleEnable = this.handleEnable.bind(this)
    }

    selectedCheckedBoxes = [];
    deadlineCheckedBox = [];

    state = {
        checkedItems: new Map(),
        autoEnableBy: new Date(),
        upgradeDeadline: new Date(),
        selectedCheckedBoxes: [],
        disabledCheckedBox: [],
        disabledUser: new Map(),
        disablebox: [],
        checkboxes: [],
        employee: {},
    }

    getCheckBoxes(computerdevices) {
        let data = computerdevices.map((x) => {
            return ({
                'name': x.name,
                'label': x.name,
            })
        });
        return data;
    }

    getDisabledcheckBoxes(disableduser) {
        let data = disableduser.map((x) => {
            return ({
                'otpout': x.isOptout,
                'deadline': x.isDeadline,
            })
        });
        return data;
    }

    componentDidMount() {
        authAxios.post(GetEmployeeInformationURL, {
            Key: this.props.location.pathname.split("/")[3]
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    employee: data
                }, () => {
                    this.setState({
                        autoEnableBy: new Date(this.state.employee.autoEnableBy),
                        upgradeDeadline: new Date(this.state.employee.upgradeDeadline), isOptout: data.isOptout, isDeadline: data.isDeadline
                        

                    });
                    authAxios.get(GetEmployeeInformationURL)
                        .then(response => {
                            this.setState({
                                disablebox: this.getDisabledcheckBoxes(response.data)
                            }, () => {
                                this.setState(prevState => ({ disabledUser: prevState.disabledUser.set(this.state.disablebox.find(_ => _.optout == this.state.employee.isOptout)) }));
                            })
                        })
                    let lob = this.state.employee.userRole == 'CORPORATE' ? 'CORP' : this.state.employee.userRole == 'PROTIVITI' ? 'PRO' : 'FIELD';
                    authAxios.get(GetComputerDevicesLOBURL + lob)
                        .then(response => {
                            this.setState({
                                checkboxes: this.getCheckBoxes(response.data)
                            }, () => {
                                for (let i = 0; i < this.state.employee.computerDevices.length; i++) {
                                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(this.state.checkboxes.find(_ => _.label == this.state.employee.computerDevices[i].name).name, true) }));
                                }
                            })
                        });

                });
            });


    }

    handleDelete = () => {
        authAxios.delete(`${PartialResetEmployeeURL}${this.state.employee.key}`)
            .then(response => {
                this.props.history.push(`/admin/adminPage`)
            }).catch((error) => {
                console.log(error);
            });
    }

    handleClickBack() {
        this.props.history.push(`/admin/adminPage`);
    }

    handleClick() {
        let selectedItems = [];
        this.state.checkedItems.forEach((isChecked, item) => {
            if (isChecked) {
                let foundCheck = this.state.checkboxes.find(_ => _.name == item);
                if (foundCheck) {
                    selectedItems.push(foundCheck.label);
                }
            }
        })
        authAxios.put(`${UpdateEmployeeComputerDeviceURL}`, {
            ComputerDevicesNames: selectedItems,
            UserKey: this.state.employee.key
        })
            .then(response => {
                authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.employee.key,
                    [{
                        "op": "replace",
                        "path": '/UpgradeDeadline',
                        "value": this.state.upgradeDeadline
                    },
                    {
                        "op": "replace",
                        "path": '/LoginNameWithDomain',
                        "value": this.state.employee.loginNameWithDomain
                    }
                    ]
                )
                    .then(response => {
                        this.props.history.push({
                            pathname: `/admin/adminPage`, state: {
                                id: 0,
                            }
                        });
                    })
                    .catch((error) => {
                        console.log('error: ' + error);
                    });
            })
            .catch((error) => {
                console.log('error: ' + error);
            });

    }



    handleChange = date => {
        this.setState({
            autoEnableBy: date
        });

    };

    handleDlChange = date => {
        this.setState({
            upgradeDeadline: date
        });
    };

    handleCheckChieldElement(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        if (isChecked && this.selectedCheckedBoxes.includes(e.target.name) == false) {
            this.selectedCheckedBoxes.push(e.target.name);
        } else if (!isChecked && this.selectedCheckedBoxes.includes(e.target.name) == true) {
            this.selectedCheckedBoxes.splice(e.target.name, 1);
        }
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    handleEnable() {
        const { isEnabled } = this.props;
        if (isEnabled == true) {
            this.setState(({ isOptout }) => (
                {
                    isEnabled: !isOptout
                }));
        }

    }

    render() {
        const { checkboxes } = this.state
        return (
            <div>
                <DualHeader />
                <Segment.Group className="segment-container">
                    <Segment className="segment-header inverted">Edit User: {this.state.employee.loginNameWithDomain}</Segment>
                    <Segment>
                        <Grid columns={2}>
                            {/* Name */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title">Name: </Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <Label>{this.state.employee.loginName}</Label>
                                </Grid.Column>
                            </Grid.Row>
                                                        
                            {/* Opt-out User */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title"> Opt-out: </Label>
                                </Grid.Column>
                                <Grid.Column style={{ marginLeft: '20px' }}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CheckBox label='Opt-Out' checked={this.state.disabledUser}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>

                            {/* Upgrade Deadline reached */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title"> Upgrade Deadline reached: </Label>
                                </Grid.Column>
                                <Grid.Column style={{ marginLeft: '20px' }}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CheckBox label='updateDeadling' checked={this.state.disabledUser}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>

                            {/* Upgrade Deadline */}
                            <Grid.Row >
                                <Grid.Column width={6}>
                                    <Label className="label-title" >Upgrade Deadline: </Label>
                                </Grid.Column>
                                <Grid.Column>

                                    <DatePicker
                                        className="datepicker"
                                        required
                                        selected={this.state.upgradeDeadline}
                                        onChange={this.handleDlChange} />

                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Segment>
                </Segment.Group>
                <div class="container">
                    <Button className="backbuttonadmin3" onClick={this.handleClickBack}>Back</Button>
                    <Button className="savedbutton" onClick={this.handleClick} disabled={!this.state.autoEnableBy}>Save</Button>
                </div>
                <div class="container button-div">
                    <Button className="deleteButton" negative onClick={() => { if (window.confirm('Are you sure you wish to delete this user?')) this.handleDelete() }}>Reset</Button>
                </div>
                <DualFooter />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllUsersError(state),
    users: getAllUsers(state),
    pending: getAllUsersPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllUsers: fetchAllUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminResetUserComponent);