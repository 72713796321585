import React, { Component } from 'react';
import { Image, Button, Dimmer, Loader } from 'semantic-ui-react'
import './Unauthorized.css';
import { authContext, getToken } from '../adalConfig';
import { GetEmployeeInformationURL } from '../constants'
import alert from '../img/Alert.PNG';
import DualHeader from './DualHeader.js';
import DualFooter from './DualFooter.js';
import { authAxios } from '../utils';


class Deadline extends Component {

    constructor(props) {
        super(props)
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
    }

    state = {
        userRole: '',
        loading: false
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    userRole: data.userRole
                })
            })
        this.setState({
            loading: false
        })
    }

    handleUserRole() {
        if (this.state.userRole === 'PROTIVITI') {
            return (
                <React.Fragment>
                    <div className="textdiv">
                        <Image src={alert} className="alertimg" />
                        <p className="unauthtext">
                            Thank you for visiting the computer upgrade registration site.<br />The allotted timeframe to upgrade your  computer has expired.
                                If you would like help upgrading your computer, please contact the Pro Upgrades Team at < a href='mailto:NAProtivitiUpgrades@protiviti.com' >NAProtivitiUpgrades@protiviti.com</a>.
                            </p>
                        <Button className="helpbuttonunauth" onClick={this.handleGoToHelp}>Help</Button>
                    </div>
                </React.Fragment>
            )
        }
        else if (this.state.userRole === 'CORPORATE') {
            return (
                <React.Fragment>
                    <div className="textdiv">
                        <Image src={alert} className="alertimg" />
                        <p className="unauthtext">
                            Thank you for visiting the computer upgrade registration site.<br />The allotted timeframe to upgrade your  computer has expired.
                                If you would like help upgrading your computer, please contact the Corp Upgrades Team at < a href='mailto:NACorpUpgrades@roberthalf.com' >NACorpUpgrades@roberthalf.com</a>.
                            </p>
                        <Button className="helpbuttonunauth" onClick={this.handleGoToHelp}>Help</Button>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="textdiv">
                        <Image src={alert} className="alertimg" />
                        <p className="unauthtext">
                            Thank you for visiting the computer upgrade registration site.<br />The allotted timeframe to upgrade your  computer has expired.
                                If you would like help upgrading your computer, please contact the RH Upgrades Team at < a href='mailto:upgrades@protiviti.com' >Upgrades@roberthalf.com</a>.
                            </p>
                        <Button className="helpbuttonunauth" onClick={this.handleGoToHelp}>Help</Button>
                    </div>
                </React.Fragment>
            )
        }
    }


    handleGoToHelp() {
        this.props.history.push('./help');
    }

    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Loading data...</Loader>
                    </Dimmer>
                </div>
            )
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader />
                    {this.handleUserRole()}
                    <div>

                        <div className="textdiv">
                            <Image src={alert} className="alertimg" />
                            <p className="unauthtext">
                            </p>
                            <Button className="helpbuttonunauth" onClick={this.handleGoToHelp}>Help</Button>
                        </div>
                    </div>
                </div>
                <DualFooter />
            </div>
        );
    }
}


export default Deadline;
