// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fetchUser } from './services/userService';
import { getUserError, getUser, getUserPending } from './reducers/userReducer';
import { Loader } from 'semantic-ui-react'
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router"
import { authContext, getToken } from './adalConfig';

class ProtectedRoute extends Component {

    constructor(props) {

        super(props);
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
    }

    componentDidMount() {
        const { fetchUser } = this.props;
        fetchUser();
    }

    shouldComponentRender() {
        const { pending, user } = this.props;
        if (user && user.isAuthenticated) {
            console.log('foudn user');
            return true;
        } else {
            console.log('no user yet');
            return false;
        }
    }

    render() {
        const { component: Component, ...rest } = this.props
        const { user, error, pending, path, exact, component, location } = this.props;

        if (getToken() && !this.shouldComponentRender()) return <Loader active inline='centered' />
        return (
            <Route
                {...rest}
                render={props => {
                    return !error || user.isAdmin ? (
                        <Component {...props} />
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/unauthorized",
                                }}
                            />
                        )
                }}
            />
        )
    }
}
const mapStateToProps = state => ({
    error: getUserError(state),
    user: getUser(state),
    pending: getUserPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchUser: fetchUser
}, dispatch)
export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(ProtectedRoute);




