// fetchProducts.js
import { authAxios } from "../utils";
import { authContext } from '../adalConfig';
import { GetUserURL, GetOfficeListingPointOfContactURL , DeleteEmployeeInformationURL, UpdateUserURL } from '../constants';
import {
    fetchAllOfficeListingWithPointOfContactError, fetchAllOfficeListingWithPointOfContactPending, fetchAllOfficeListingWithPointOfContactSuccess
} from '../actions';
let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

export const extractDataFromAPIToOfficeListingPOCObject = (responses) => {
    return responses.map((x, index) => {
        return ({
            'key': x.key,
            'office': x.office,
            'costCenter': x.costCenter,
            'contact': x.contact,
            'shippingInfo': x.shippingInfo,
            'loginName': x.loginName,
            'email': x.email,
            'country': x.country,
            'phoneNumber': x.phoneNumber
        })
    });
}


export const fetchAllOfficeListingWithPointOfContacts = (role = 'CORP') => async (dispatch, getState) => {
    dispatch(fetchAllOfficeListingWithPointOfContactPending());
    authAxios.get(GetOfficeListingPointOfContactURL + `?role=${role}`)
        .then(response => {
            let officeListingPointOfContacts = extractDataFromAPIToOfficeListingPOCObject(response.data);
            dispatch(fetchAllOfficeListingWithPointOfContactSuccess(officeListingPointOfContacts));
            return response;
        })
        .catch(error => {
            dispatch(fetchAllOfficeListingWithPointOfContactError(error));
        })
}

