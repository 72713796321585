import React, { Component } from 'react';
import { Segment, Button, Grid, Label, Input, Form } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { AddComputerDeviceURL, UploadDeviceImageURL } from '../../constants';
import { authAxios } from '../../utils';
import './AdminUserAddPage.css';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';
import './AdminHardwarePage.css';


const LOBOptions = [
    {
        key: 'CORP',
        text: 'CORP',
        value: 'CORP',
    },
    {
        key: 'PRO',
        text: 'PRO',
        value: 'PRO',
    },
    {
        key: 'CORPPRO',
        text: 'CORPPRO',
        value: 'CORPPRO',
    },
    {
        key: 'FIELD',
        text: 'FIELD',
        value: 'FIELD',

    },
    {
        key: 'FIELDCORPPRO',
        text: 'FIELDCORPPRO',
        value: 'FIELDCORPPRO',

    },
]

class AdminDeviceAddComponent extends Component {
    constructor(props) {
        super(props)
        this.handleClickBack = this.handleClickBack.bind(this);
        this.fileToBase64 = this.fileToBase64.bind(this);
        //this.handleSave = this.handleSave.bind(this);
    }

    

    state = {
        deviceName: '',
        display: '',
        processor: '',
        ram: '',
        hardDrive: '',
        ports: '',
        weight: '',
        batteryLife: '',
        dockingStation: '',
        deviceLOB: '',
        error: false,
        imgFile: undefined,
        imgFileName: ''
    }

    handleClickBack() {
        this.props.history.push(`/admin/adminHardwarePage`);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: false })
    };

    fileToBase64(file, onLoadCallback) {
        if (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () { resolve(reader.result); };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        }
        else {
            return new Promise(function (resolve) {
                resolve();
            });
        }
    }

    handleSave = () => {
        if (!this.state.deviceName || !this.state.display || !this.state.batteryLife || !this.state.ram || !this.state.weight || !this.state.processor || !this.state.deviceLOB || !this.state.ports || !this.state.hardDrive || !this.state.imgFileName || !this.state.dockingStation) {
            this.setState({
                error: true
            })
        }
        else {
            const { deviceName, display, processor, ram, weight, batteryLife, deviceLOB, ports, hardDrive, imgFile, imgFileName, dockingStation } = this.state;
            var that = this;
            var promise = this.fileToBase64(imgFile);
            promise.then(function (result) {

                authAxios.put(UploadDeviceImageURL, {
                    ImageFile: result.split(',')[1],
                    ImageName: imgFileName
                }).then((result) => {
                    authAxios.put(AddComputerDeviceURL, {
                        Name: deviceName,
                        Display: display,
                        BatteryLife: batteryLife,
                        Ram: ram,
                        Weight: weight,
                        Processor: processor,
                        DeviceLOB: deviceLOB,
                        Ports: ports,
                        HardDrive: hardDrive,
                        DockingStation: dockingStation,
                        Image: imgFileName
                    }).then(response => {
                        that.handleClickBack();

                    })
                        .catch((error) => {
                            console.log('error ' + error);
                            that.setState({
                                error: true
                            })
                        });

                })
                    .catch((error) => {
                        console.log('error ' + error);
                        that.setState({
                            error: true
                        })
                    });


            })
        }
       

    }

    handleLOBChange = (e, { value }) => this.setState({ deviceLOB: value })

    handleFileUploadChange = event => {
        this.setState({ error: false })
        if (!event.target.files[0]) {
            this.setState({ error: true })
            return;
        }
        this.setState({
            imgFile: event.target.files[0],
            imgFileName: event.target.files[0].name
        });
    };

    Usererrormessage = () => {
        if (this.state.error === true) {
            return (
                <React.Fragment>
                    {this.state.error &&
                        <h2 className="errorreviewUINewUser" >
                            You must fill out all fields and include a Picture
                        </h2>
                    }
                </React.Fragment>
            )
        }
    }

    render() {
        const { deviceName, display, processor, ram, weight, batteryLife, deviceLOB, ports, hardDrive, dockingStation } = this.state
        return (
          <div className='outer-container'>
             <div className='inner-container'>
                <DualHeader />
                <Segment className="segment-header inverted">Add a Device:</Segment>
                <Grid columns={2} divided className="selection" >

                    <Grid.Row className="border">
                            <Grid.Column className="area">
                                <br />
                                <Grid.Row>
                                    <Label className="label-title">Device Name: </Label>
                                    <Input className="adddevice" placeholder='Device Name' name='deviceName' value={deviceName} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                </Grid.Row>
                                <br />                                
                                <Grid.Row>
                                    <Label className="label-title">Upload an image:</Label>
                                </Grid.Row>
                                <Grid.Row centered>
                                    <input
                                        className="csv-input"
                                        required
                                        error={this.state.error}
                                        type="file"
                                        ref={input => {
                                            this.filesInput = input;
                                        }}
                                        name="file"
                                        placeholder={null}
                                        onChange={this.handleFileUploadChange}
                                    />
                                </Grid.Row>
                                <br />
                                <Grid.Row>
                                    <Label className="label-title">Select LOB</Label>
                                    <Form.Dropdown
                                        defaultValue=''
                                        inline
                                        selection
                                        className="label-title"                                        
                                        name='selectLOB'
                                        value={deviceLOB}
                                        options={LOBOptions}
                                        onChange={this.handleLOBChange}
                                        error={this.state.error}
                                        required
                                    />
                                </Grid.Row>                                
                                <br />

                        </Grid.Column>
                        <Grid.Column>
                            <Grid columns={2} divided className="detailsselectcorp">
                                <Grid.Row className="rowlight">
                                    <Grid.Column>
                                        Display
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input
                                                className="adddevice"
                                                placeholder='15" Multi-touch Screen'
                                                name='display'
                                                value={display}
                                                required
                                                error={this.state.error}
                                                onChange={(e) => this.handleChange(e)} 
                                            />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowdark">
                                    <Grid.Column>
                                        Processor
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input placeholder='Intel Core i7' name='processor' value={processor} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowlight">
                                    <Grid.Column>
                                        Ram
                                            </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='16GB' name='ram' value={ram} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                          </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowdark">
                                    <Grid.Column>
                                        Hard Drive
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='256GB Solid State Disk' name='hardDrive' value={hardDrive} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowlight">
                                    <Grid.Column>
                                        Ports
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='1 USB-C, 1 USB-A' name='ports' value={ports} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowdark">
                                    <Grid.Column>
                                        Weight
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='3.40 lbs.' name='weight' value={weight} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowlight">
                                    <Grid.Column>
                                            Battery Life
                                        </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='Up to 11.5 hours' name='batteryLife' value={batteryLife} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="rowlight">
                                    <Grid.Column>
                                        Accessories
                                    </Grid.Column>
                                        <Grid.Column className="leftalign">
                                            <Input className="adddevice" placeholder='1 Powered USB Hub, 1 HDMI Cable' name='dockingStation' value={dockingStation} required error={this.state.error} onChange={(e) => this.handleChange(e)} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <div>
                    {this.Usererrormessage()}
                </div>

                <div>
                    <Button className="backbuttonadmin2" onClick={this.handleClickBack}>Back</Button>
                    <Button className="savedbutton" onClick={this.handleSave}>Save</Button>

                </div>
                <br />                
             </div>
                <DualFooter />
          </div>
        );
    }
}

export default AdminDeviceAddComponent;