import React, { Component } from 'react';
import { Header, Button, Grid, Image } from 'semantic-ui-react'
import './Home.css';
import { authContext, getToken } from '../adalConfig';
import { connect } from 'react-redux';
import { authAxios } from '../utils';
import { GetUserURL } from '../constants';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Valid';

import DualHeader from './DualHeader.js';
import DualFooter from './DualFooter.js';
import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS
} from "../auth-util";
// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
// const useRedirectFlow = true;
class Home extends Component {
    constructor(props) {
        super(props)
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
       // this.handleOptOut = this.handleOptOut.bind(this);
    }
    state = {
        apiResponse: '',
        redirect: false,
        errorredirect: false,
        loading: true,
    };

    handleClick = async () => {
        var email = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        authAxios.post(GetUserURL, {
            LoginNameWithDomain: email
        })
            .then(response => {
                //this.props.makeValid();
                //if (JSON.stringify(response.data.userRole).includes('PROTIVITI'))
                if (response.data.isAdmin) {
                    this.props.history.push('./WelcomePage')
                }
                else if (response.data.isSubmitted) {
                    this.props.history.push('./review')
                }
                else if (response.data.isOptout) {
                    this.props.history.push('./reviewOptOut')
                }
                else if (response.data.isDeadline) {
                    this.props.history.push('./DeadlinePage')
                }
                else {
                    this.props.history.push('./userinformation');
                }
                // else if (this.state.onClick == this.handleOptOut) {
                //    this.props.push('./optout')
                //}
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    handleOptOut = async () => {
        var email = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        authAxios.post(GetUserURL, {
            LoginNameWithDomain: email
        })
            .then(response => {
                //this.props.makeValid();
                //if (JSON.stringify(response.data.userRole).includes('PROTIVITI'))
                if (response.data.isSubmitted) {
                    this.props.history.push('./review')
                }
                else if (response.data.isOptout) {
                    this.props.history.push('./reviewOptOut')
                }
                else if (response.data.isDeadline) {
                    this.props.history.push('./DeadlinePage')
                }
                else {
                    this.props.history.push('./optout');
                }
                //else if (JSON.stringify(response.data.userRole).includes('CORPORATE')) {
                //    this.props.history.push('/corpuserinfo'); 
                //}
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    renderRedirect = () => {
        if (this.state.redirect) {
        }
    }

    handleWindowCallback(hash) {
        console.log('hello world');
    }

    componentDidMount () {

      // var email = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        //clear cookie if it exists to prevent firewall issues.
        document.cookie = "invoca_sesssion=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";       

        if (!getToken()) {
            authContext.login();
        }
        if (getToken()) {

        }
      
    }

    isCallback = authContext.isCallback(window.location.hash);
    if (isCallback) {
        authContext.handleWindowCallback();
    }

    render() {
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                  
                        <div className="maindivHome">
                  
                            <p className="h1aHome">
                                Welcome to the <br/>
                                Computer Upgrade Registration Website!
                            </p>
                            <p className='h3home'>
                                This website will walk you through your computer upgrade and appointment date selection. <br />
                                Once the process is complete, a confirmation email will be sent to you.
                            </p>
                        <Grid>
                            <Grid.Row centered>
                                <Button
                                    type='button'
                                    //onClick={() => { history.push('./UserInformation') }}
                                    onClick={this.handleClick}
                                        className="homebegin"
                                    >
                                    Begin
                                </Button>
                            </Grid.Row>
                            <Grid.Row centered>

                                    <Button onClick={this.handleGoToHelp} className="homehelp">Help</Button>

                                    <Button onClick={this.handleOptOut} className="homeoptout">Opt-Out</Button>

                            </Grid.Row>
                        </Grid>
                      
                        </div>
                   
                </div>
                <DualFooter/>
            </div>
        );
    }
}


export default Home;


