import newestprofooter from '../../img/bannergray.png';
import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'
import './RHFooter.css';



class Footer extends Component {



    render() {
        return (
            <footer >
                <Image className='botbanner3' />
            </footer>

        )
    }

}
export default Footer;

