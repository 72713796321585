import React, { Component } from 'react';
import { Segment, Button, Image, Grid, Label, Form, Dimmer, Loader} from 'semantic-ui-react'
import rhprobanner from '../img/besttorhprobanner.png';
import rhprobotbanner from '../img/bannergray.png';
import { withRouter } from 'react-router';
import './ReviewPage.css';
import checkcomplete from '../img/checkcomplete.png';
import { store } from '../indexApp.js';
import { authContext, getToken } from '../adalConfig';
import { authAxios } from '../utils';
import { GetEmployeeInformationURL, SendEmailConfirmationURL, GetOfficeListingPointOfContactURL, CheckIfDateIsExceedTheMaxSlotURL, InsertPatchEmployeeAsyncURL } from '../constants';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter'
//import ReviewProFooter from './ReviewProFooter';
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter'
//import ReviewRHFooter from './ReviewRHFooter';


let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class ReviewPage extends Component {
    constructor(props) {
        super(props)
        this.handleGoesBack = this.handleGoesBack.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
        this.handleCalClickBack = this.handleCalClickBack.bind(this);
        this.handleBacktoUserInfo = this.handleBacktoUserInfo.bind(this);
        this.handleBacktoCal = this.handleBacktoCal.bind(this);
        this.handleBacktoSelection = this.handleBacktoSelection.bind(this);
        //this.handleCorpCube = this.handleCorpCube.bind(this);
        this.handleUserComment = this.handleUserComment.bind(this);
        this.handleBacktoSoftware = this.handleBacktoSoftware.bind(this);
        this.handleCorpOtherHardware = this.handleCorpOtherHardware.bind(this);
        this.handleRouteButtons = this.handleRouteButtons.bind(this);
        this.handleReviewText = this.handleReviewText.bind(this);
    }

    state = {
        name: "",
        accountLogon : "",
        homeOffice : "",
        deliveryOffice : "",
        email : "",
        phoneNumber : "",
        computerModel : "", 
        softwarePackages : "",
        upgradeWeek: "",
        phoneNumberAlternate: "",
        exceededMaxSlotDate: false,
        userRole: "",
        /*cubicleNumber: "",*/
        userComments: "",
        dockType: "",
        submitted: 'false',
        loading: false,
        isSubmitted: '',
        isAdmin: '',
        country: "",
        key: ""
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        this.setState({
            loading: true
        })
        let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    email: data.email, name: data.name, deliveryOffice: data.deliveryOffice,
                    phoneNumber: data.telephoneBusiness, homeOffice: data.homeOffice,
                    computerModel: data.currentSelectedComputerDevice, softwarePackages: this.isBase64(data.softwarePackages) ? atob(data.softwarePackages) : data.softwarePackages,
                    upgradeWeek: data.upgradeWeek, phoneNumberAlternate: data.telephoneAlternate,
                    accountLogon: data.loginName, userRole: data.userRole, /*cubicleNumer: data.cubicleNumber,*/
                    userComments: this.isBase64(data.userComments) ? atob(data.userComments) : data.userComments, dockType: data.currentSelectedDockDevice, isSubmitted: data.isSubmitted,
                    isAdmin: data.isAdmin, country: data.country, key: data.key

                })
                if (this.state.phoneNumber) {
                    this.setState({ phoneNumber: Number(this.state.phoneNumber.replace(/[^0-9]/g, '')) });
                }
                if (data.userRole === 'PROTIVITI') {
                    authAxios.get(GetOfficeListingPointOfContactURL + `?role=${this.state.userRole}`)
                        .then(response => {
                            let data = response.data;
                            let citiesCostCenters = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                            citiesCostCenters.push({
                                city: 'San Ramon',
                                costCenter: '90000',
                                country: 'US',
                            })
                            this.setState({
                                citiesCostCenters: this.updateOfficeListingCostCenterOptions(citiesCostCenters),
                                loading: false
                            });
                            let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice);
                            let costCenterFromEmployeeHomeOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.homeOffice);
                            if (costCenterFromEmployeeDeliveryOffice.length > 0) {
                                this.setState({
                                    deliveryOffice: costCenterFromEmployeeDeliveryOffice[0].value
                                })
                            }
                            if (costCenterFromEmployeeHomeOffice.length > 0) {
                                this.setState({
                                    homeOffice: costCenterFromEmployeeHomeOffice[0].value
                                })
                            }

                        });
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    updateOfficeListingCostCenterOptions(source) {
        let sink = source.map((x, index) => {
            return ({
                key: x.city,
                text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
            })
        });
        return sink;
    }

    handleGoesBack() {
        this.props.history.goBack();
    }

    handleClick = async () => {
        // Going to Help=>Software page can allow progression to review page without delivery location or computer model.
        if (!this.state.userComments) {
            this.props.history.push('/userinformation');
        } else if (!this.state.computerModel) {
            this.props.history.push('/selectionCorp');
        }

        if (this.state.deliveryOffice && this.state.homeOffice) {
            this.setState({
                submitted: 'true'
            }, () => {
                    let tempDeliveryOffice = this.state.deliveryOffice
                    if (this.state.deliveryOffice.includes("-")) {
                        tempDeliveryOffice = this.state.deliveryOffice.split("-")[0].replace(/\s+$/g, '').split(') ')[1];
                    }
                    let tempHomeOffice = this.state.homeOffice;
                    if (this.state.homeOffice.includes("-")) {
                        tempHomeOffice = this.state.homeOffice.split("-")[0].replace(/\s+$/g, '').split(') ')[1];
                }
                    if (this.state.computerModel != 'Other') {
                        authAxios.post(CheckIfDateIsExceedTheMaxSlotURL, {
                            SoftwarePackages: this.state.softwarePackages, Email: this.state.email,
                            HomeOffice: tempHomeOffice,
                            DeliveryOffice: tempDeliveryOffice, isSubmitted: true,
                            LoginNameWithDomain: loginNameWithDomain, UpgradeWeek: this.state.upgradeWeek,
                            UserRole: this.state.userRole, Country: this.state.country
                           
                        })
                            .then(response => {
                                this.setState({ exceededMaxSlotDate: false });
                                authAxios.post(SendEmailConfirmationURL, { SoftwarePackages: this.state.softwarePackages, HomeOffice: tempDeliveryOffice, Email: this.state.email, DeliveryOffice: tempDeliveryOffice, isSubmitted: true, LoginNameWithDomain: loginNameWithDomain })
                                    .then(response => {
                                        authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                                            [{
                                                "op": "replace",
                                                "path": '/isSubmitted',
                                                "value": true
                                            }]
                                        ).then(response => {
                                            this.props.history.push({
                                                pathname: './confirmation',
                                                state: {
                                                    passedUserRole: this.state.userRole
                                                }
                                            });
                                        })
                                            .catch((error) => {
                                                console.log('error: ' + error);
                                            });


                                    })
                                    .catch((error) => {
                                        console.log('error ' + error);
                                        this.setState({
                                            errorredirect: true
                                        });
                                        this.props.history.push('./unauthorized');
                                    });
                            })
                            .catch((error) => {
                                console.log('error ' + error);
                                //if (!getToken()) {
                                //    authContext.login();
                                //    this.handleClick();
                                //}
                                //else {
                                this.setState({ exceededMaxSlotDate: true });
                                //}
                            })
                    }
                    else {
                        authAxios.post(SendEmailConfirmationURL, { SoftwarePackages: this.state.softwarePackages, HomeOffice: tempDeliveryOffice, Email: this.state.email, DeliveryOffice: tempDeliveryOffice, isSubmitted: true, LoginNameWithDomain: loginNameWithDomain })
                            .then(response => {
                                let empDTO = {
                                    isSubmitted: true, MondaySevenDaysReminderEmailFlag: true,
                                    FiveDaysTrackingEmailFlag: true, ThursdayPriorToUpgradeWeekFlag: true
                                }
                                let results = [];
                                for (const property in empDTO) {
                                    results.push({
                                        "op": "replace",
                                        "path": `${property}`,
                                        "value": `${empDTO[property]}`
                                    })
                                }


                                authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                                    results
                                ).then(response => {
                                    this.props.history.push({
                                        pathname: './confirmation',
                                        state: {
                                            passedUserRole: this.state.userRole
                                        }
                                    });
                                })
                                    .catch((error) => {
                                        console.log('error: ' + error);
                                    });


                            })
                            .catch((error) => {
                                console.log('error ' + error);
                                this.setState({
                                    errorredirect: true
                                });
                                this.props.history.push('./unauthorized');
                            });
                    }
            })
            
        }
    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    handleCalClickBack() {
        if (this.state.userRole === 'CORPORATE')
            this.props.history.push('./software');
        else
            this.props.history.push('./calendar');
    }

    handleBacktoUserInfo() {
        this.props.history.push('./userinformation');
    }

    handleBacktoCal() {
        this.props.history.push('./calendar');
    }

    handleBacktoSoftware() {
        this.props.history.push('./software');
    }

    handleBacktoSelection() {
        if (this.state.userRole === 'PROTIVITI')
            this.props.history.push('./selection');
        else if (this.state.userRole === 'CORPORATE')
            this.props.history.push('./selectioncorp');
    }

    DateExceededErrorMessage = () => {
        return (
            <React.Fragment>
                {this.state.exceededMaxSlotDate &&
                    <h2 className="errorreview" >
                       Error: The Upgrade Date you have selected is no longer available, or you have not selected an upgrade date. Please go back to the Upgrade Week page and select an available Monday.
                    </h2>
                }
            </React.Fragment>
        )
    }

    handleRouteButtons() {
        if (!this.state.isSubmitted || this.state.isAdmin) {
            return (
                <React.Fragment>
                    <Button className="backbuttonrev" onClick={this.handleCalClickBack}>  <i class="left arrow icon"></i> Back</Button>
                    <Button className="nextbuttonrev" onClick={this.handleClick} disabled={this.state.submitted === 'true'} > Submit </Button>
                </React.Fragment>
            )
        }
    }


    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter/>;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHFooter/>;
    } 

    handleReviewText() {
        if (this.state.isSubmitted && !this.state.isAdmin) {
            return (
                <React.Fragment>
                    <div className="reviewText">
                        <p>
                            You are already registered - see below for details.
                        </p>
                        <br/>
                        <p>
                            If you need to cancel or reschedule click the Help button to email the Upgrades Mailbox.
                        </p>
                    </div>
                </React.Fragment>
            )
        }
    }

    //handleCorpCube() {
    //    if (this.state.userRole === 'CORPORATE') {
    //        return (
    //            <React.Fragment>
    //                <Grid.Row className='rowAlignment'>
    //                    <Grid.Column width={6}>
    //                        <Label className="label-title">Cube Number: </Label>
    //                    </Grid.Column>
    //                    <Grid.Column>
    //                        <Label>{this.state.cubicleNumer}</Label>
    //                    </Grid.Column>
    //                </Grid.Row>
    //            </React.Fragment>
    //        )
    //    }
    //}

    handleUserComment() {
        if (this.state.userComments){
            return (
                <React.Fragment>
                    <Grid.Row className='rowAlignment'>
                        <Grid.Column width={6}>
                            <Label className="label-title">Delivery Address: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.userComments}</Label>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            )
        }
    }

    handleCorpHardware() {
        if (this.state.userRole === 'CORPORATE') {
            return (
                <React.Fragment>
                    <Grid.Row className='rowAlignment'>
                        <Grid.Column width={6}>
                            <Label className="label-title">Dock Type: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.dockType}</Label>
                        </Grid.Column>
                    </Grid.Row>

                </React.Fragment>
            )
            
            
        }

        else if (this.state.userRole === 'PROTIVITI' ) {
            return (
                <React.Fragment>
                    <Grid.Row className='rowAlignment'>
                        <Grid.Column width={6}>
                            <Label className="label-title">Software Packages: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.softwarePackages}</Label>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            )
        }
    }


    handleCorpOtherHardware(){
        if (this.state.userRole === 'CORPORATE' && this.state.computerModel === 'Other') {
            return (
                <React.Fragment>

                </React.Fragment>
            )
        }
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD') {
            return (
                <React.Fragment>
                    {/*<button onClick={this.handleBacktoSoftware} className="ui basic button" style={{ display: this.state.isSubmitted && !this.state.isAdmin ? 'none' : '' }}>*/}
                    {/*    <i class="icon pencil"></i>*/}
                    {/*    Edit Software*/}
                    {/*</button>*/}

                    <Grid.Row className='rowAlignment'>
                        <Grid.Column width={6}>
                            <Label className="label-title">Software Information: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>For Software information please click the Software Page button below:</Label><button onClick={this.handleBacktoSoftware} className="softwarebuttonrev">Software Information</button>
                        </Grid.Column>
                    </Grid.Row>

                    <button onClick={this.handleBacktoCal} class="ui basic button" style={{ display: this.state.isSubmitted && !this.state.isAdmin ? 'none' : '' }}>
                        <i class="icon pencil"></i>
                        Edit Upgrade Week
                                </button>

                    <Grid.Row className='rowAlignment'>

                        <Grid.Column width={6}>
                            <Label className="label-title">Upgrade Week: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.upgradeWeek}</Label>

                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            )
        }
        else if (this.state.userRole === 'PROTIVITI') {
            return (
                <React.Fragment>
                    <button onClick={this.handleBacktoCal} class="ui basic button" style={{ display: this.state.isSubmitted && !this.state.isAdmin ? 'none' : '' }}>
                        <i class="icon pencil"></i>
                        Edit Upgrade Week
                                </button>

                    <Grid.Row className='rowAlignment'>

                        <Grid.Column width={6}>
                            <Label className="label-title">Upgrade Week: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.upgradeWeek}</Label>

                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            )
        }
    }

    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Loading data...</Loader>
                    </Dimmer>
                </div>
            )
        const { name, accountLogon, homeOffice, deliveryOffice, email, phoneNumber, computerModel, softwarePackages, upgradeWeek, phoneNumberAlternate, userRole} = this.state;

        return (
            <div className='outer-container'>
                <div className="inner-container">
                    {this.handleHeaderSelection()}
                    {this.handleReviewText()}
                    <Segment.Group className="segment-container">
                        <Segment className={userRole === 'CORPORATE' || userRole === 'FIELD' ? "CORP-segment-header" : "PRO-segment-header"}>  {this.state.isSubmitted && !this.state.isAdmin ? '' : 'Please review your selections.'}</Segment>
                        <Segment>
                       
                            <Grid columns={2}>
                                {/* Name */}
                                <button onClick={this.handleBacktoUserInfo} className="ui basic button" style={{ display: this.state.isSubmitted && !this.state.isAdmin ? 'none' : '' }}>
                                        <i class="icon pencil"></i>
                                        Edit User Information </button>
                                <Grid.Row className='rowAlignment'>
                              
                                    <Grid.Column width={6}>
                                  
                                        <Label className="label-title">Name: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                               
                                        <Label>{name}</Label>
                                  
                                    </Grid.Column>
                                </Grid.Row>

                                {/*Email */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Email: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{email}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Account Logon */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Account Login: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{accountLogon}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Phone # */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Telephone (business): </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{phoneNumber}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Phone # alternative */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Telephone (alternate): </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{phoneNumberAlternate}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Home Office */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                  
                                        <Label className="label-title">Home Office Location: </Label>
                                   
                                    </Grid.Column>
                              
                                    <Grid.Column>
                                        <Label>{homeOffice}</Label>
                                
                                    </Grid.Column>
                               
                                </Grid.Row>


                                {/* Delivery Office */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Delivery Location: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{deliveryOffice}</Label>

                                    </Grid.Column>
                                </Grid.Row>

                                {/*this.handleCorpCube()*/}
                                {this.handleUserComment()}

                                {/* Computer Model  */}
                                <button onClick={this.handleBacktoSelection} class="ui basic button" style={{ display: this.state.isSubmitted && !this.state.isAdmin ? 'none' : '' }}>
                                    <i class="icon pencil"></i>
                                    Edit Device Selection </button>
                                <Grid.Row className='rowAlignment'>
                                
                                    <Grid.Column width={6}>

                                        <Label className="label-title">Computer Model: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{computerModel}</Label>
                                    
                                    </Grid.Column>
                                </Grid.Row>

                               
                               

                            
                                {this.handleCorpOtherHardware()}
                                {/* Upgrade Week */}

                               
                            </Grid>
                        </Segment>
                    </Segment.Group>
                    <div className="bottomButtons">
                        {this.DateExceededErrorMessage()}
                        {this.handleRouteButtons()}
                        
                        <Button className="helpbutton3" onClick={this.handleGoToHelp}> Help</Button>
                    </div>    
            
                   
                </div>
                {this.handleFooterSelection()} 

            </div>
           
        );
    }
}


export default ReviewPage;
