import React, { Component } from 'react';
import { Image } from 'semantic-ui-react'
import { authContext } from '../adalConfig';
import './ConfirmationPage.css';
import checkcomplete from '../img/checkcomplete.png';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter';
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter';


class ConfirmationPage extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        userRole: ''
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                userRole: this.props.location.state.passedUserRole
            }, () => {
                console.log(this.state.userRole);
            });
        }
        else {
            this.props.history.push('./unauthorized');
        }
    }

    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter />;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHFooter />;
    }

    handleUserRole() {
        if (this.state.userRole === 'PROTIVITI')
            return (
                <p className="confirmation-description">You have successfully registered to upgrade your Protiviti computer. You will receive an email confirmation shortly.<br /><br />
                    If you need to make changes to your registration, or have any questions, please contact <br /> <a href='mailto:NAProtivitiUpgrades@protiviti.com'><em>NAProtivitiUpgrades@protiviti.com</em></a>.</p>
                )
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return (
                <p className="confirmation-description">You have successfully registered to upgrade your Robert Half computer. You will receive an email confirmation shortly.<br /><br />
                    If you need to make changes to your registration, or have any questions, please contact <br /> <a href='mailto:upgrades@roberthalf.com'><em>upgrades@roberthalf.com</em></a>.</p>
                )
    }

    render() {
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    {this.handleHeaderSelection()}
                
                    <div className="confirmation-container">

                        <Image src={checkcomplete} className="check-complete-img" />
                        <p className="thank-you">Thank you!</p>
                        {this.handleUserRole()}
                    </div>
                </div>
                {this.handleFooterSelection()}

            </div>
           
        );
    }
}


export default ConfirmationPage;
