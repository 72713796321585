import {
    FETCH_ALL_USERS_SUCCESS, FETCH_ALL_USERS_PENDING, FETCH_ALL_USERS_ERROR, DELETE_USER_SUCCESS, DELETE_USER_PENDING
} from "../actions";

const initialStateForAllUsers = {
    pending: false,
    users: [],
    error: null
}
export function allUsersReducer(state = initialStateForAllUsers, action) {
    switch (action.type) {
        case FETCH_ALL_USERS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                pending: false,
                users: action.users
            }
        case FETCH_ALL_USERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case DELETE_USER_SUCCESS:
            return state.filter((data, i) => i != action.user)
            
        default:
            return state;
    }
}

export const getAllUsers = state => state.allUsersReducer.users ? state.allUsersReducer.users : null
export const getAllUsersPending = state => state.allUsersReducer.pending;
export const getAllUsersError = state => state.allUsersReducer.error;

