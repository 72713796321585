import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Icon, Grid, Label, List, Button, Confirm, Loader, Dimmer, Form, Radio } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchAllUsers, deleteUser } from '../../services/userService';
import { getAllUsersError, getAllUsers, getAllUsersPending } from '../../reducers/allUsersReducer';
import { DeleteEmployeeInformationURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminGridPage.css';
import { authAxios } from '../../utils';
import { authContext, getToken } from '../../adalConfig';


var loginname = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class AdminGridComponent extends Component {
    constructor(props) {
        super(props)
        this.handleDeleteEmployee = this.handleDeleteEmployee.bind(this);
    }

    state = {
        data: [],
        open: false,
        loading: false,
        LOB: ''
    }

    getEmployeesData(employees) {
        let data =  employees.map((x, index) => {
            return ({
                'delete': this.deleteSectionForEachRow(employees[index]),
                'reset': this.resetUserSectionForEachRow(employees[index]),
                'edit': this.editSectionForEachRow(employees[index]),
                'loginNameWithDomain': x.loginNameWithDomain,
                'name': x.name,
                'enabled': this.enableCheckboxSectionForEachRow(employees[index]),
                //'optout': this.optoutCheckboxSelectionForEachRow(employees[index]),
                'autoenableby': x.autoenableby != null ? this.formatDate(x.autoenableby) : null,
                'computerDevices': this.listOfComputerDevices(employees[index]),
                'key': x.key
            })
        });
        return data;
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }

    componentDidMount() {
        if (!loginname) {
            this.props.history.push('../../unauthorized');
        } else {
            
            const { fetchAllUsers } = this.props;
            fetchAllUsers();
            this.setState({
                data: this.getEmployeesData(this.props.users),
                LOB: 'CORP'
            });
            console.log(store.getState());
            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.users.length != prevProps.users.length) {
            this.setState({ data: this.getEmployeesData(this.props.users) });
        }
        
        for (let i = 0; i < this.props.users.length; i++) { 
            if (this.props.users[i] && prevProps.users[i]) {
                if ((JSON.stringify(this.props.users[i].computerDevices) !== JSON.stringify(prevProps.users[i].computerDevices)) || this.props.users[i].autoenableby != prevProps.users[i].autoenableby) {
                    this.setState({ data: this.getEmployeesData(this.props.users) });
                }
            }
        }
    }

    handleGoBackToWelcomePage = () => {
        this.props.history.push('/welcomepage')
    }

    handleDeleteEmployee(employeeInfo) {
        let selectedEmployee = this.props.users.find(_ => _.loginNameWithDomain == employeeInfo.loginNameWithDomain);
        authAxios.delete(`${DeleteEmployeeInformationURL}${selectedEmployee.key}`)
            .then(response => {
                let selectedUser = this.props.users.find(_ => _.loginNameWithDomain == selectedEmployee.loginNameWithDomain);
                let employees = this.props.users.splice(this.props.users.indexOf(selectedUser), 1);
                let updatedEmployees = this.getEmployeesData(this.props.users);

                this.setState({
                    data: updatedEmployees
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    editSectionForEachRow(employeeInfo) {
        return (
            <div className='dropdownAction'>
                <Link to={{ pathname: '/admin/adminUserEditPage/' + employeeInfo.key }} underlaycolor="transparent">
                    <Icon name="pencil alternate" style={{ color: 'blue' }} size='large'/>
                </Link>
            </div>
        )
    }

    deleteSectionForEachRow(employeeInfo) {
        return (
            <React.Fragment>
                <div className='dropdownAction'>
                    <Icon name="trash alternate" style={{ color: 'red' }} size='large' onClick={() => { if (window.confirm('Are you sure you wish to delete this user?')) this.handleDeleteEmployee(employeeInfo) }} />
                </div>
            </React.Fragment>
        )
    }

    resetUserSectionForEachRow(employeeInfo) {
        return (
            <div className='dropdownAction'>
                <Link to={{ pathname: '/admin/adminResetUserPage/' + employeeInfo.key }} underlaycolor="transparent">
                    <Icon name="undo" style={{ color: 'blue' }} size='large' />
                </Link>
            </div>)
    }

  

    enableCheckboxSectionForEachRow(employeeInfo) {
        let today = new Date();
        return (
            <div className='dropdownAction'>
                <Checkbox checked={today >= new Date(employeeInfo.autoenableby)} />
            </div>
        )
    }

    //optoutCheckboxSelectionForEachRow(employeeInfo) {
    //    return (
    //        <div className='dropdownAction'>
    //            <Checkbox checked=
    //            )
    //}

    listOfComputerDevices(employeeInfo) {
        const { computerDevices } = employeeInfo;
        return (
            <React.Fragment>
                {/* Devices */}
                <List>
                    {computerDevices.map(item => (
                        <List.Item key={item.name}>{item.name}</List.Item>
                        ))}
                </List>
            </React.Fragment>
        );
    }

    handleLOBChange(e, value) {
        this.setState({
            LOB: value.value,
        }, () => {
            const { fetchAllUsers } = this.props;
            fetchAllUsers(this.state.LOB);
        });
    }

    render() {
        let rowdata = this.state.data;
        rowdata = rowdata.map(function (item) {
            delete item.key;
            return item;
        })
        const tableData = {
            columns: [
                {
                    label: 'Delete',
                    field: 'delete',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Reset',
                    field: 'reset',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Edit',
                    field: 'edit',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Login Name',
                    field: 'loginNameWithDomain',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Enabled',
                    field: 'enabled',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Auto Enable By',
                    field: 'autoenableby',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Selected Devices',
                    field: 'selecteddevices',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: rowdata
        };
        if (this.props.pending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )
        if (tableData.rows.length < 1 && loginname && this.props.pending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )

        return (
            <React.Fragment>
                <Form className='adminForms'>
                    <Form.Field className='dockformText'>
                        <p className='adminRadioButtonText'> Select line of business:</p>
                    </Form.Field>
                    <Form.Field className='dockform'>
                        <Radio className='adminRadioButton'
                            label='CORP'
                            name='radioGroupLOBManageHardware'
                            value='CORP'
                            checked={this.state.LOB === 'CORP'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                        <Radio className='adminRadioButton'
                            label='PROTIVITI'
                            name='radioGroupLOBManageHardware'
                            value='PROTIVITI'
                            checked={this.state.LOB === 'PROTIVITI'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                        <Radio className='adminRadioButton'
                            label='FIELD'
                            name='radioGroupLOBManageHardware'
                            value='FIELD'
                            checked={this.state.LOB === 'FIELD'}
                            onChange={(e, value) => this.handleLOBChange(e, value)}
                        />
                    </Form.Field>

                </Form>


                <div className="datatable-grid">
                    <MDBDataTable
                        striped
                        bordered
                        hover
                        data={tableData} />
                    <Button
                        onClick={this.handleGoBackToWelcomePage}
                        className="welcome-page-back-button">
                        Back
                        </Button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllUsersError(state),
    users: getAllUsers(state),
    pending: getAllUsersPending(state)
    
})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllUsers: fetchAllUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminGridComponent);


