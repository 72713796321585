import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react'
import './Unauthorized.css';
import alert from '../img/Alert.PNG';
import DualHeader from './DualHeader.js';
import DualFooter from './DualFooter.js';

class Unauthorized extends Component {

    constructor(props) {
        super(props)
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
    }
    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        //this.ensureDataFetched();
    }

    ensureDataFetched() {
        const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        this.props.requestWeatherForecasts(startDateIndex);
    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    render() {
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <div>
                 
                        <div className="textdiv">
                            <Image src={alert} className="alertimg" />
                            <p className="unauthtext">
                                Thank you for visiting the Computer Upgrade Registration Website. <br />
                                Currently, you do not have access to this site.<br />Access is determined by upgrade eligibility.<br /><br />Please click the help button below if you believe this message is in error.
                            </p>
                            <Button className="helpbuttonunauth" onClick={this.handleGoToHelp}>Help</Button>
                        </div>
                    </div>
                </div>
                <DualFooter/>
            </div>
        );
    }
}


export default Unauthorized;
