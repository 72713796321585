import React, { Component } from 'react';
import { withRouter } from 'react-router';
import backbutton from '../../img/BackButton.PNG';

import { Segment, Button, Image, Grid, Label, Input } from 'semantic-ui-react'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../../shares/Checkbox';
import { AddSnowUserURL, UpdateEmployeeComputerDeviceURL, GetComputerDevicesURL } from '../../constants';
import { authAxios } from '../../utils';
import './AdminUserAddPage.css';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';


class AdminUserAddComponent extends Component {
    constructor(props) {
        super(props)
        for (let checkbox in this.state.checkboxes) {
            if (checkbox.name)
                this.selectedCheckedBoxes.push(checkbox.name);
        }
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
    }
    selectedCheckedBoxes = [];
    state = {
        loginName: '',
        computerModel: '',
        error: false,
        checkedItems: new Map(),
        autoEnableBy: new Date(),
        selectedCheckedBoxes: [],
        checkboxes: []
    }

    componentDidMount() {
        authAxios.get(GetComputerDevicesURL)
            .then(response => {
                let data = response.data;

                let cboxes = [];
                for (const d in data) {
                    cboxes.push({
                        name: data[d].name,
                        label: data[d].name
                    })
                }

                this.setState({
                    checkboxes: cboxes
                })
                

            });

        
    }

    handleClickBack() {
        this.props.history.push(`/admin/adminPage`);
    }

    handleDateChange = (date) => {
        this.setState({
            autoEnableBy: date
        });
    }

    handleCheckChieldElement(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        if (isChecked && this.selectedCheckedBoxes.includes(e.target.name) == false) {
            this.selectedCheckedBoxes.push(e.target.name);
        } else if (!isChecked && this.selectedCheckedBoxes.includes(e.target.name) == true) {
            this.selectedCheckedBoxes.splice(e.target.name, 1);
        }
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: false })
    };

    handleSave = () => {

        const { loginName, computerModel, autoEnableBy, selectedCheckedBoxes, checkedItems } = this.state;
        let results = []
        let result = [];
        results.push({
            Login: loginName,
            EnabledAsOf: autoEnableBy
        });
        result.push(results)

        let selectedItems = [];
        this.state.checkedItems.forEach((isChecked, item) => {
            if (isChecked) {
                selectedItems.push(this.state.checkboxes.find(_ => _.name == item).label);
            }
        })
      
        
        authAxios.post(AddSnowUserURL, {
            result: result[0]
        }).then(response => {

            if (!response.data.loginNameWithDomain) {
                this.setState({
                    error: true
                })
                return;
            }
            authAxios.put(`${UpdateEmployeeComputerDeviceURL}`, {
                ComputerDevicesNames: selectedItems,
                LoginNameWithDomain: response.data.loginNameWithDomain
            }).then(response => {
                this.props.history.push(`/admin/adminPage`);
            })
                .catch((error) => {
                    console.log('error ' + error);
                });
        })
        .catch((error) => {
            console.log('error ' + error);
        });
        
    }

    Usererrormessage = () => {
        if (this.state.error === true) {
            return (
                <React.Fragment>
                    {this.state.error &&
                        <h2 className="errorreviewUINewUser" >
                            Error adding new user
                    </h2>
                    }
                </React.Fragment>
            )
        }
    }

    render() {
        const { loginName, autoEnableBy, checkboxes } = this.state
        return (
            <div>
                <DualHeader/>
                <Segment.Group className="segment-container">
                    <Segment className="segment-header inverted">Add a User:</Segment>
                    <Segment>
                        <Grid columns={2}>
                            {/* Name */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title">Name: </Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <Input placeholder='Username' name='loginName' value={loginName} onChange={(e) => this.handleChange(e)} />
                                </Grid.Column>
                            </Grid.Row>

                            {/* Devices */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title">Devices: </Label>
                                </Grid.Column>
                                <Grid.Column style={{marginLeft: '20px'}}>
                                    {checkboxes.map(item => (
                                        <Grid.Row key={item.name}>
                                            <Grid.Column>
                                                <CheckBox  name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handleCheckChieldElement} />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Label>{item.label}</Label>
                                            </Grid.Column>
                                        </Grid.Row>))}
                                </Grid.Column>
                            </Grid.Row>

                            {/* Enable on: */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title">Enable on: </Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <DatePicker
                                        className="datepicker"
                                        required
                                        selected={this.state.autoEnableBy}
                                        onChange={this.handleDateChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            
                        </Grid>
                    </Segment>
                </Segment.Group>
                <div>
                    {this.Usererrormessage()}
                </div>
                <div>
                    <Button className="backbuttonadmin2" onClick={this.handleClickBack}>Back</Button>
                    <Button className="savedbutton" onClick={this.handleSave} disabled={!this.state.autoEnableBy}>Save</Button>
                    
                </div>
                <DualFooter/>
            </div>
        );
    }
}

export default AdminUserAddComponent; 



