import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Switch,
} from 'react-router-dom';
import { Grid, Button, Form, Radio, Image, List, Icon } from 'semantic-ui-react'
import rhprobanner from '../img/besttorhprobanner.png';
import rhprobotbanner from '../img/bannergray.png';
import { withRouter } from 'react-router';
import './HelpPage.css';
import DualHeader from './DualHeader.js';
import DualFooter from './DualFooter.js';
import { DEFAULT_VERSION } from 'redux-persist/lib/constants';

class HelpComponent extends Component {
    constructor(props) {
        super(props)
        this.handleFAQDropDown = this.handleFAQDropDown.bind(this);
        this.handleQ1DropDown = this.handleQ1DropDown.bind(this);
        this.handleQ2DropDown = this.handleQ2DropDown.bind(this);
        this.handleQ3DropDown = this.handleQ3DropDown.bind(this);
        this.handleQ4DropDown = this.handleQ4DropDown.bind(this);
        this.handleQ5DropDown = this.handleQ5DropDown.bind(this);
        this.handleQ6DropDown = this.handleQ6DropDown.bind(this);
        this.handleQ7DropDown = this.handleQ7DropDown.bind(this);
        this.handleQ8DropDown = this.handleQ8DropDown.bind(this);
        this.handleQ9DropDown = this.handleQ9DropDown.bind(this);
        this.handleQ10DropDown = this.handleQ10DropDown.bind(this);
        this.handleQ11DropDown = this.handleQ11DropDown.bind(this);
        this.handleQ12DropDown = this.handleQ12DropDown.bind(this);
        this.handleGuidesToolDropDown = this.handleGuidesToolDropDown.bind(this);
        this.handleGoesBack = this.handleGoesBack.bind(this);
        this.handleSoftwarePage = this.handleSoftwarePage.bind(this);
    }

    state = {
        isFAQDropDown: false,
        isQ1DropDown: false,
        isQ2DropDown: false,
        isQ3DropDown: false,
        isQ4DropDown: false,
        isQ5DropDown: false,
        isQ6DropDown: false,
        isQ7DropDown: false,
        isQ8DropDown: false,
        isQ9DropDown: false,
        isQ10DropDown: false,
        isQ11DropDown: false,
        isQ12DropDown: false,
        isGuidesToolDropDown: false,
        isContactUsDropDown: false,
        radioCompany: ''
    };
    


    handleFAQDropDown() {
        this.setState({ isFAQDropDown: !this.state.isFAQDropDown });
    }

    handleQ1DropDown() {
        this.setState({ isQ1DropDown: !this.state.isQ1DropDown })
    }

    handleQ2DropDown() {
        this.setState({ isQ2DropDown: !this.state.isQ2DropDown })
    }

    handleQ3DropDown() {
        this.setState({ isQ3DropDown: !this.state.isQ3DropDown })
    }

    handleQ4DropDown() {
        this.setState({ isQ4DropDown: !this.state.isQ4DropDown })
    }

    handleQ5DropDown() {
        this.setState({ isQ5DropDown: !this.state.isQ5DropDown })
    }

    handleQ6DropDown() {
        this.setState({ isQ6DropDown: !this.state.isQ6DropDown })
    }

    handleQ7DropDown() {
        this.setState({ isQ7DropDown: !this.state.isQ7DropDown })
    }

    handleQ8DropDown() {
        this.setState({ isQ8DropDown: !this.state.isQ8DropDown })
    }

    handleQ9DropDown() {
        this.setState({ isQ9DropDown: !this.state.isQ9DropDown })
    }

    handleQ10DropDown() {
        this.setState({ isQ10DropDown: !this.state.isQ10DropDown })
    }

    handleQ11DropDown() {
        this.setState({ isQ11DropDown: !this.state.isQ11DropDown })
    }

    handleQ12DropDown() {
        this.setState({ isQ12DropDown: !this.state.isQ12DropDown })
    }

    handleGuidesToolDropDown() {
        this.setState({ isGuidesToolDropDown: !this.state.isGuidesToolDropDown });
    }

    handleCompanyChange(e, value) {
        this.setState({
            radioCompany: value.value
        })
    }


    handleGoesBack() {
        this.props.history.goBack();
    }

    handleSoftwarePage() {
        this.props.history.push('./software')
    }

    handleHelp() {
        if (this.state.radioCompany) {
            return (
                <div className="drop-down-section">
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column width={3}>
                                            {!this.state.isFAQDropDown
                                                ? < Button type="submit" className="back-arrow" onClick={this.handleFAQDropDown} />
                                                : < Button type="submit" className="down-arrow" onClick={this.handleFAQDropDown} />
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <p className="drop-down-title">FAQs</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {this.state.isFAQDropDown && (<Grid.Row>
                                        <Grid.Column width={14}>                                            
                                            <List >
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ7DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ7DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ7DropDown} />
                                                            }
                                                            Q: What browser does the Registration Site work best on?
                                                        </List.Header>
                                                        {this.state.isQ7DropDown && (
                                                                'A: Edge and Chrome.'
                                                            )}
                                                        </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">                                                                                                      
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ1DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ1DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ1DropDown} />
                                                            }
                                                            Q: When will I receive my new computer?
                                                        </List.Header>
                                                        {this.state.isQ1DropDown && (
                                                            'A: Your computer will be delivered to your requested location on the Friday before your upgrade week.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ2DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ2DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ2DropDown} />
                                                            }
                                                            Q: Will someone help me upgrade my computer?
                                                        </List.Header>
                                                        {this.state.isQ2DropDown && (
                                                            'A: No, migration and set-up will need to be completed individually. Please follow the instructions noted in the Computer Upgrade - Reservation Reminder email. If you require assistance, please email the Upgrades Team at the Contact Us link on this page.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ3DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ3DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ3DropDown} />
                                                            }
                                                            Q: I haven't received my tracking number for the new computer. When will I receive the tracking number?
                                                        </List.Header>
                                                        {this.state.isQ3DropDown && (
                                                            'A: A tracking number will be emailed to you the Wednesday before your upgrade week.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ4DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ4DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ4DropDown} />
                                                            }
                                                            Q: Can I have my new computer shipped to my home address?
                                                        </List.Header>
                                                        {this.state.isQ4DropDown && (
                                                            'A: Yes, you will be given the option to provide your home address for shipping purposes.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ5DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ5DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ5DropDown} />
                                                            }
                                                            Q: I am traveling for client work during the week of the upgrade. Can I move my upgrade resevation date?
                                                        </List.Header>
                                                        {this.state.isQ5DropDown && (
                                                            'A: Yes, if you need to make changes to your upgrade reservation date, please email the Upgrades Team at the Contact Us link on this page.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists" >
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ6DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ6DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ6DropDown} />
                                                            }
                                                            Q: Can I purchase my old computer, or keep it as a work backup computer?
                                                        </List.Header>
                                                        {this.state.isQ6DropDown && (
                                                            'A:  No. When the data migration and set-up of the new computer is complete, you will need to ship the old computer back to Corporate using the provided return label.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ8DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ8DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ8DropDown} />
                                                            }
                                                            Q: 4K Monitor Information.
                                                        </List.Header>
                                                        {this.state.isQ8DropDown && (
                                                            'Note: There are known issues when using 4K monitors with a Surface Dock: 1.) 4K monitors are not supported on the Surface Dock One; 2.) a 4K monitor connected to the Surface Dock One will not show 4K resolutions; and 3.) a 4K monitor connected to a Surface Dock One has the potential to cause a blue screen error on your new computer.'
                                                        )}                                                        
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ12DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ12DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ12DropDown} />
                                                            }
                                                            Q: How do I know which type of docking station I have?
                                                        </List.Header>
                                                        {this.state.isQ12DropDown && (
                                                            'A: To determine what docking station you have, please see the Surface Dock Guide in the “Guides & Tools” section of this page.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ9DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ9DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ9DropDown} />
                                                            }
                                                            Q: How can I connect my 4K monitor to my new computer?
                                                        </List.Header>
                                                        {this.state.isQ9DropDown && (
                                                            'A: You can connect your 4K monitor directly to the computer with the provided cable.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ10DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ10DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ10DropDown} />
                                                            }
                                                            Q: What if I use two 4K monitors?
                                                        </List.Header>
                                                        {this.state.isQ10DropDown && (
                                                            'A: If you are using two 4K monitors, you will need one of the suggested docks in the “Suggested Docking Stations” question down below.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content className="bluecolor">
                                                        <List.Header>
                                                            {!this.state.isQ11DropDown
                                                                ? < Icon name='plus' Button type="submit" onClick={this.handleQ11DropDown} />
                                                                : < Icon name='minus' Button type="submit" onClick={this.handleQ11DropDown} />
                                                            }
                                                            Q: Suggested Docking Stations
                                                        </List.Header>
                                                        {this.state.isQ11DropDown && (
                                                            'A: IT suggests the following supported docking stations: 1.) Surface Dock 2 (only compatible with Microsoft Surface products); and 2.) Targus USB-C Universal DV4K Docking station (compatible with all RH-issued computers). Please contact your Department Admin to order one.'
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>)}
                                </Grid>
                            </Grid.Column>

                            <Grid.Column>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column width={3}>
                                            {!this.state.isGuidesToolDropDown
                                                ? < Button type="submit" className="back-arrow" onClick={this.handleGuidesToolDropDown} />
                                                : < Button type="submit" className="down-arrow" onClick={this.handleGuidesToolDropDown} />
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <p className="drop-down-title">Guides & Tools</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {this.state.isGuidesToolDropDown && (<Grid.Row>
                                        <Grid.Column width={14}>
                                            <List >
                                                <List.Item as='a' className="FAQ-lists">
                                                    <List.Content>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalf' ? <Link to="/Computer Refresh Registration Process.pdf" target="blank" download>Computer Refresh Registration Process</Link>
                                                                : < Link to="" target="blank" download ></Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalf' ? <Link to="/Surface Dock Guide.pdf" target="blank" download>Surface Dock Guide</Link>
                                                                : this.state.radioCompany === 'RobertHalfField' ? <Link to="/Surface Dock Guide.pdf" target="blank" download>Surface Dock Guide</Link>
                                                                    : <Link to="/Surface Dock Guide.pdf" target="blank" download>Surface Dock Guide</Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalfField' ? <Link to="/Non-Autopilot Migration and Set-Up Guide.pdf" target="blank" download>Non-Autopilot Migration and Set-Up Guide (Canada Only)</Link>
                                                                : this.state.radioCompany === 'Protiviti' ? <Link to="/Non-Autopilot Migration and Set-Up Guide.pdf" target="blank" download>Non-Autopilot Migration and Set-Up Guide (Canada Only)</Link>
                                                                    : < Link to="" target="blank" download ></Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalfField' ? <Link to="/Moving the .Never Back Up Folder Guide.pdf" target="blank" download>Moving the .Never Back Up Folder Guide</Link>
                                                                : this.state.radioCompany === 'RobertHalf' ? <Link to="/Moving the .Never Back Up Folder Guide.pdf" target="blank" download>Moving the .Never Back Up Folder Guide</Link>
                                                                    : <Link to="/Moving the .Never Back Up Folder Guide.pdf" target="blank" download>Moving the .Never Back Up Folder Guide</Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany == 'RobertHalf' ? <Link to="/Windows Hello and Quick Guide Links.pdf" target="bland" download>Windows Hello and Quick Guide Links</Link>
                                                                : this.state.radioCompany === 'RobertHalfField' ? <Link to="/Windows Hello and Quick Guide Links.pdf" target="blank" download>Windows Hello and Quick Guide Links</Link>
                                                                    : <Link to="/Windows Hello and Quick Guide Links.pdf" target="blank" download>Windows Hello and Quick Guide Links</Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalfField' ? <a href="https://roberthalf.sharepoint.com/sites/shared/learning/publishingfiles/talent-solutions-pre-migration-checklist.pdf">Talent Solutions Pre-Migration Checklist</a> : <Link to="" target="blank" download></Link> }
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalfField' ? <a href="https://roberthalf.sharepoint.com/sites/shared/learning/publishingfiles/talent-solutions-pre-migration-guide.pdf">Talent Solutions Pre-Migration Guide</a>
                                                                : this.state.radioCompany == 'RobertHalf' ? <a href="https://roberthalf.sharepoint.com/:b:/r/sites/shared/learning/na-field/publishingfiles/pre-migration-device-setup-guide-rh-corporate.pdf?csf=1&web=1">Corp Pre-Migration Guide</a>
                                                                    : < Link to="" target="blank" download></Link>}
                                                        </List.Header>
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalfField' ? <a href="https://roberthalf.sharepoint.com/sites/shared/learning/publishingfiles/talent-solutions-migration-checklist.pdf">Talent Solutions Migration Checklist</a> : <Link to="" target="blank" download ></Link>}
                                                        </List.Header>
                                                        {/*<List.Header className="guides">*/}
                                                        {/*    {this.state.radioCompany === 'RobertHalf' ? <Link to="/Targus Docking Station Set-Up Guide.pdf" target="blank" download>Targus Docking Station Set-Up Guide</Link>*/}
                                                        {/*        : this.state.radioCompany === 'RobertHalfField' ? < Link to="/Targus Docking Station Set-Up Guide.pdf" target="blank" download>Targus Docking Station Set-Up Guide</Link> : < Link to="" target="blank" download ></ Link>}*/}
                                                        {/*</List.Header>*/}
                                                        <List.Header className="guides">
                                                            {this.state.radioCompany === 'RobertHalf' ? <a href="https://roberthalf.sharepoint.com/:b:/r/sites/shared/learning/na-field/publishingfiles/migration-device-setup-guide-rh-corporate.pdf?csf=1&web=1">Corporate - Migration and Set-Up Guide</a>
                                                                : this.state.radioCompany == 'RobertHalfField' ? <a href="https://roberthalf.sharepoint.com/sites/shared/learning/publishingfiles/Forms/AllItems.aspx?id=%2Fsites%2Fshared%2Flearning%2Fpublishingfiles%2Ftalent%2Dsolutions%2Dmigration%2Dand%2Dset%2Dup%2Dguide%2Epdf&parent=%2Fsites%2Fshared%2Flearning%2Fpublishingfiles">Talent Solutions - Migration and Set-Up Guide</ a>
                                                                    : <a href="https://clients.ishare.protiviti.com/teamsites6/laptop_upgrades/User%20Training%20and%20Documentation/Protiviti%20Migration%20and%20Set-Up%20Guide.pdf">Protiviti - Migration and Set-Up Guide</a>}
                                                        </List.Header>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>)}
                                </Grid>
                            </Grid.Column>

                            <Grid.Column>
                                <Grid columns={2}>
                                    <Grid.Row>

                                        <Grid.Column>
                                            {this.state.radioCompany === 'RobertHalf' ? <a className="drop-down-title" href="mailto:NACorpUpgrades@roberthalf.com?subject=Laptop Upgrade Help">Contact Us</a>
                                                : this.state.radioCompany === 'RobertHalfField' ? <a className="drop-down-title" href="mailto:upgrades@roberthalf.com?subject=Laptop Upgrade Help">Contact Us</a> : <a className="drop-down-title" href="mailto:NAProtivitiUpgrades@protiviti.com?subject=Laptop Upgrade Help">Contact Us</a>}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }
    }

    render() {
        const { isFAQDropDown, isGuidesToolDropDown, isContactUsDropDown } = this.state;

        return (
            <div className='outer-container'>
                <div className='inner-container'> 
                    <DualHeader />
                    <div>
                   
                        <h4 className="helpHeader">Help Page</h4>

                        <div className="helpTextSection">
                            <p> Need help? Check out our FAQs.<br/>
                                Still stuck? Check the Guides & Tools for helpful resources on device upgrades. <br />
                                Need even more help? Click the Contact Us button and our team will get back to you shortly.<br />
                                For software please click the Software Information button:<br />
                                <button className='softwarebuttonhep' onClick={this.handleSoftwarePage}>Software Information</button></p>
                        </div>

                        <div>
                            <Grid className=''>
                                <Grid.Row centered>
                                    <Grid.Column width={8}>
                                        <Form>
                                            <Form.Field className='rhpro'>
                                                Please select which line of business you require.
                                            </Form.Field>
                                            <Form.Field className='helpRadio'>
                                                <Radio className='text'
                                                    label='Robert Half Corporate'
                                                    name='helpradio'
                                                    value='RobertHalf'
                                                    checked={this.state.radioCompany === 'RobertHalf'}
                                                    onChange={(e, value) => this.handleCompanyChange(e, value)}
                                                />
                                            </Form.Field>
                                            <Form.Field className='helpRadio'>
                                                <Radio className='text'
                                                    label='Robert Half Talent Solutions'
                                                    name='helpradio'
                                                    value='RobertHalfField'
                                                    checked={this.state.radioCompany === 'RobertHalfField'}
                                                    onChange={(e, value) => this.handleCompanyChange(e, value)}
                                                />
                                            </Form.Field>
                                            <Form.Field className='helpRadio'>
                                                <Radio className='text'
                                                    label='Protiviti'
                                                    name='helpradio'
                                                    value='Protiviti'
                                                    checked={this.state.radioCompany === 'Protiviti'}
                                                    onChange={(e, value) => this.handleCompanyChange(e, value)}
                                                />
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>

                        {this.handleHelp()}
                        

                        <div className="back-button-section">
                            <Button className="backButton" onClick={this.handleGoesBack}>Back</Button>
                        </div>
                    </div>

                </div>
                <DualFooter />
            </div>
        );
    }
}


export default HelpComponent;
