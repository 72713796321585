import React, { Component } from 'react';
import { withRouter } from 'react-router';
import backbutton from '../../img/BackButton.PNG';

import { Segment, Button, Image, Grid, Label, Input, Checkbox, Dropdown, Radio } from 'semantic-ui-react'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../../shares/Checkbox';
import { fetchAllUsers, deleteUser } from '../../services/userService';
import { authAxios } from '../../utils';
import { getAllUsersError, getAllUsers, getAllUsersPending } from '../../reducers/allUsersReducer';
import { InsertPatchEmployeeAsyncURL, GetEmployeeInformationURL, GetComputerDevicesLOBURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminUserReservationDetailPage.css';
import DualFooter from '../DualFooter';
import DualHeader from '../DualHeader';
class AdminUserReservationDetailComponent extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleReservationDateChange = this.handleReservationDateChange.bind(this);
        this.handlePickupDateChange = this.handlePickupDateChange.bind(this);
        this.handleHomeOffice = this.handleHomeOffice.bind(this);
        this.handleDeliveryOffice = this.handleDeliveryOffice.bind(this);
    }

    selectedCheckedBoxes = [];

    state = {
        pickupDate: new Date(),
        reservationDate: new Date(),
        checkedItems: new Map(),
        autoEnableBy: new Date(),
        selectecdComputer: '',
        trackingNumber: '',
        checkboxes: [],
        employee: {},
        homeOffice: '',
        deliveryOffice: '',
        loginNameWithDomain: '',
        key: ''
    }

    getCheckBoxes(computerdevices) {
        let data = computerdevices.map((x) => {
            return ({
                'name': x.name,
                'label': x.name
            })
        });
        return data;
    }

    componentDidMount() {
        authAxios.post(GetEmployeeInformationURL, {
            Key: this.props.location.pathname.split("/")[3]
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    employee: data,
                    selectecdComputer: data.currentSelectedComputerDevice,
                    homeOffice: data.homeOffice,
                    deliveryOffice: data.deliveryOffice,
                    pickupDate: data.pickUpDate ? new Date(data.pickUpDate) : null,
                    reservationDate: new Date(data.upgradeWeek),
                    trackingNumber: data.trackingNumber,
                    loginNameWithDomain: data.loginNameWithDomain,
                    key: data.key

                }, () => {

                        let thislob = this.state.employee.userRole == 'CORPORATE' ? 'CORP' : this.state.employee.userRole == 'PROTIVITI' ? 'PRO' : 'FIELD' ;
                        authAxios.get(GetComputerDevicesLOBURL + thislob)
                            .then(response => {
                                this.setState({
                                    checkboxes: this.getCheckBoxes(response.data)
                                }, () => {
                                    for (let i = 0; i < this.state.employee.computerDevices.length; i++) {
                                        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(this.state.checkboxes.find(_ => _.label == this.state.employee.currentSelectedComputerDevice), true) }));
                                    }
                                })
                            });

                })
                
            }).catch((error) => {
                console.log(error);
            });
    }

    handleClickBack() {
        this.props.history.push({
            pathname: `/admin/adminPage`, state: {
                id: 1,
            }
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }

    handleClick() {
        let upgradeWeekWithSpecificFormat = this.formatDate(this.state.reservationDate);

        let empDTO = {
            PickUpDate: this.state.pickupDate,
            UpgradeWeek: upgradeWeekWithSpecificFormat,
            homeOffice: this.state.homeOffice,
            deliveryOffice: this.state.deliveryOffice,
            TrackingNumber: this.state.trackingNumber,
            CurrentSelectedComputerDevice: this.state.selectecdComputer
        }
        let results = [];
        for (const property in empDTO) {
            results.push({
                "op": "replace",
                "path": `${property}`,
                "value": `${empDTO[property]}`
            })
        }
        authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
            results
        ).then(response => {
            this.props.history.push({
                pathname: `/admin/adminPage`, state: {
                    id: 1,
                }
            });
        }).catch((error) => {
             console.log(error);
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleReservationDateChange = date => {
        this.setState({
            reservationDate: date
        });
    }

    handlePickupDateChange = date => {
        this.setState({
            pickupDate: date
        });
    }

    handleHomeOffice = (event, { value }) => {
        console.log(value);
        this.setState({
            homeOffice: event.target.textContent
        })
    }

    handleDeliveryOffice = (event, { value }) => {
        this.setState({
            deliveryOffice: event.target.textContent
        })
    }

    handleCheckChieldElement(e) {
        const item = e.target.textContent;
        this.setState({
            selectecdComputer: item
        })
        //const isChecked = e.target.checked;
        //if (isChecked && this.selectedCheckedBoxes.includes(e.target.name) == false) {
        //    this.selectedCheckedBoxes.push(e.target.name);
        //} else if (!isChecked && this.selectedCheckedBoxes.includes(e.target.name) == true) {
        //    this.selectedCheckedBoxes.splice(e.target.name, 1);
        //}
       // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
    }

    render() {
        const { checkboxes, homeOfficeOptions, deliveryOfficeOptions, trackingNumber, homeOffice, deliveryOffice } = this.state
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <div className="flex-container">
                        <Segment.Group className="segment-container-reservation-details">
                            <Segment className="segment-header inverted">Current Reservation Details: { this.state.employee.loginNameWithDomain} </Segment>
                            <Segment>
                                <Grid columns={2}>
                                    {/* Home Office */}
                                    <Grid.Row>
                                        <Grid.Column width={5} className="home-office-label-column">
                                            <Label className="label-title">Home Office: </Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            {this.state.employee.homeOffice}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* Delivery Office */}
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Label className="label-title">Delivery Office: </Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            {this.state.employee.deliveryOffice}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* Devices */}
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Label className="label-title">Devices: </Label>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginLeft: '20px' }}>
                                            {checkboxes.map(item => (
                                                <Grid.Row key={item.name}>
                                                    <Grid.Column>
                                                        <Radio label={item.label} name={item.name} value={item.name} checked={item.label == this.state.selectecdComputer} onChange={(e) => this.handleCheckChieldElement(e)}/>
                                                    </Grid.Column>
                                                     
                                                </Grid.Row>))}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* Auto Enable on: */}
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Label className="label-title">Reservation Date: </Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <DatePicker
                                                selected={this.state.reservationDate ? this.state.reservationDate : null}
                                                onChange={this.handleReservationDateChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Segment.Group>
                        <Segment.Group className="segment-container-tracking-details">
                            <Segment className="segment-header inverted">Tracking Detail:</Segment>
                            <Segment>
                                <Grid columns={2}>
                                    {/* Tracking Number */}
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Label className="label-title">Tracking #: </Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Input name='trackingNumber' value={trackingNumber} onChange={(e) => this.handleChange(e)} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* Pickup Date */}
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Label className="label-title">Pickup Date: </Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <DatePicker name='pickupDate' selected={this.state.pickupDate} onChange={this.handlePickupDateChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Segment.Group>
                    </div>
                    

  
                    <div>
                        <Button className="next-button" onClick={this.handleClick}>Save</Button>
                        <br />
                        <Button className="back-button" onClick={this.handleClickBack}>Back</Button>
                    </div>
                </div>
                <DualFooter/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllUsersError(state),
    users: getAllUsers(state),
    pending: getAllUsersPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllUsers: fetchAllUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminUserReservationDetailComponent);
