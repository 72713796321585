//export const host = process.env.NODE_ENV === 'production' ? 'https://gcsupgradedev-dev.azurewebsites.net' : 'http://localhost:44371';
//console.log(`host:${process.env.NODE_ENV}`);
//console.log(window.location.origin);
export const host = window.location.origin;
export const APIEndPoint = `${host}/api`;

export const GetUserURL = `${APIEndPoint}/Employee/finduser`;
export const UpdateUserURL = `${APIEndPoint}/Employee/InsertUpdate`;
export const InsertPatchEmployeeAsyncURL = `${APIEndPoint}/Employee/InsertPatchEmployeeAsync/`;
export const GetEmployeeInformationURL = `${APIEndPoint}/Employee/employee/`;
export const GetAllEmployeesInformationURL = `${APIEndPoint}/Employee/GetAllEmployeesInfo`;
export const GetAllSubmittedEmployeesInformationURL = `${APIEndPoint}/Employee/GetAllSubmittedEmployeesInfo`;
export const GetAllSubmittedEmployeesReportURL = `${APIEndPoint}/Employee/GetAllSubmittedEmployeesReport`;
export const DeleteEmployeeInformationURL = `${APIEndPoint}/Employee/deleteEmployee/`;
export const PartialResetEmployeeURL = `${APIEndPoint}/Employee/PartialResetEmployee/`;
export const EmployeeOptOutURL = `${APIEndPoint}/Employee/EmployeeOtpOut/`;
export const EmployeeEnableURL = `${APIEndPoint}/Employee/EmployeeEnable`;

export const UpdateEmployeeComputerDeviceURL = `${APIEndPoint}/ComputerDevice/updateEmployeeComputerDevice/`;
export const RemoveEmployeeComputerDeviceURL = `${APIEndPoint}/ComputerDevice/removeEmployeeComputerDeviceURL/`;
export const GetComputerDevicesURL = `${APIEndPoint}/ComputerDevice/GetComputerDevicesInfo/`;
export const GetComputerDevicesLOBURL = `${APIEndPoint}/ComputerDevice/GetComputerDevicesLOB/`;
export const GetComputerDeviceByIdURL = `${APIEndPoint}/ComputerDevice/GetComputerDevice/`;
export const AddComputerDeviceURL = `${APIEndPoint}/ComputerDevice/AddComputerDevice/`;
export const UpdateComputerDeviceURL = `${APIEndPoint}/ComputerDevice/UpdateComputerDevice/`;
export const DeleteComputerDeviceURL = `${APIEndPoint}/ComputerDevice/DeleteComputerDevice/`;
export const UploadDeviceImageURL = `${APIEndPoint}/ComputerDevice/addDeviceImage/`;
export const GetDeviceImagesURL = `${APIEndPoint}/ComputerDevice/getDeviceImages/`;




export const GetOfficeListingPointOfContactURL = `${APIEndPoint}/OfficeListingPointOfContact/GetAllOfficeListingPointOfContact`;
export const GetSingleOfficeListingPointOfContactURL = `${APIEndPoint}/OfficeListingPointOfContact/officeListingPointOfContact/`;
export const UpdateOfficeListingPointOfContactURL = `${APIEndPoint}/OfficeListingPointOfContact/updateOfficeListingPointOfContact/`;
export const DeleteOfficeListingointOfContactURL = `${APIEndPoint}/OfficeListingPointOfContact/deleteOfficeListingPointOfContact/`;

export const SendEmailConfirmationURL = `${APIEndPoint}/EmailService/sendEmailConfirmation`;
export const SendOptoutEmailConfirmationURL = `${APIEndPoint}/EmailService/sendOptoutEmailConfirmation`;

export const AddSnowUserURL = `${APIEndPoint}/SnowService/snowUserAdd/`;
export const AddSnowPocURL = `${APIEndPoint}/SnowService/addOfficeListingPointOfContact/`;

export const CheckIfDateIsExceedTheMaxSlotURL = `${APIEndPoint}/AppointmentCalendar/CheckIfDateIsExceedTheMaxSlot/`;
export const GetInvalidWeekURL = `${APIEndPoint}/AppointmentCalendar/GetInvalidWeek/`;
export const GetAllFutureDatesURL = `${APIEndPoint}/AppointmentCalendar/GetAllFutureDates`;
export const UpdateSlots = `${APIEndPoint}/AppointmentCalendar/UpdateSlots`;

export const UpdateEmployeeReservationDetail = `${APIEndPoint}/ReservationDetail/UpdateEmployeeReservationDetail`;
export const UpdateEmployeeReservationDetailsURL = `${APIEndPoint}/ReservationDetails/UpdateEmployeeReservationDetails/`;
export const GetEmployeeReservationDetailsURL = `${APIEndPoint}/ReservationDetails/GetEmployeeReservationDetails/`;
























