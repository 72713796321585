import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Checkbox, Icon, Grid, Label, List, Loader, Dimmer, Form, Radio, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchAllUsers, deleteUser, fetchAllSubmittedUsers } from '../../services/userService';
import { getAllRegisteredUsersError, getAllRegisteredUsers, getAllRegisteredUsersPending } from '../../reducers/AllRegisteredUsersReducer';
import { GetAllSubmittedEmployeesReportURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminRegisteredUserPage.css';
import { authAxios } from '../../utils';
import { authContext, getToken } from '../../adalConfig';

var loginname = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class AdminReport extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        data: []
    }

    getEmployeesData(employees) {
        if (employees && employees.length > 0) {
            let data = employees.map((x, index) => {
                return ({
                    'LOB': x.userRole,
                    'User_ID': x.loginName,
                    'Employee_Name': x.name,
                    'JobFunction': x.jobFunction,
                    /*'Cubicle_Number': x.cubicleNumber,*/
                    'Delivery_Office': x.deliveryOffice,
                    'Office_Code': x.officeCode,
                    'Cost_Center': x.costCenter,
                    'Email': x.email,
                    'Upgrade_Date': x.upgradeWeek,
                    'Selected_Device': x.currentSelectedComputerDevice,
                    'Selected_Dock': x.currentSelectedDockDevice,
                    'User_Comments': this.isBase64(x.userComments) ? atob(x.userComments).replace(/(\r\n|\n|\r)/gm, " ") : x.userComments.replace(/(\r\n|\n|\r)/gm, " "),
                    'Optout_Comments': this.isBase64(x.optoutComments) ? atob(x.optoutComments).replace(/(\r\n|\n|\r)/gm, " ") : x.optoutComments.replace(/(\r\n|\n|\r)/gm, " "),
                    'Software_Packages': this.isBase64(x.softwarePackages) ? atob(x.softwarePackages) : x.softwarePackages,
                    'Registered': x.isSubmitted ? 'yes' : 'no',
                    'Optout': x.isOptout ? 'yes' : 'no',
                    'Upgrad_Deadline': x.isDeadline ? 'yes' : 'no',
                    'Reservation_Reminder': x.mondaySevenDaysReminderEmailFlag ? 'yes' : 'no',
                    'Complete_Your_Computer_Upgrade': x.thursdayPriorToUpgradeWeekFlag ? 'yes' : 'no',
                    'Shipping_Confirmation': x.fiveDaysTrackingEmailFlag ? 'yes' : 'no',
                    'Ship_Date': x.shipDate != null ? this.formatDate(x.shipDate) : null,
                    'Tracking_Number': x.trackingNumber

                })
            });
            return data;
        }
        return [];
    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [month, day, year].join('/');
    }



    componentDidMount() {
        if (!loginname) {
            this.props.history.push('../../unauthorized');
        } else {
            authAxios.get(GetAllSubmittedEmployeesReportURL)
                .then(response => {
                    this.setState({
                        data: this.getEmployeesData(response.data)
                    })
                })
                .catch(error => {
                    this.props.history.push('../../unauthorized');
                })

        }
    }

    componentDidUpdate(prevProps, prevState) {
        //if (!this.props.registeredUsers || this.props.registeredUsers.length <= 0) return;
        if (this.props.registeredUsers != prevProps.registeredUsers) {
            this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
        }
        for (let i = 0; i < this.props.registeredUsers.length; i++) {
            if (this.props.registeredUsers != prevProps.registeredUsers) {
                this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
                break;
            }
            if (prevProps.registeredUsers.length > 0) {
                if (this.props.registeredUsers[i] && this.props.registeredUsers[i].computerDevices && this.props.registeredUsers[i].computerDevices.length != prevProps.registeredUsers[i].computerDevices.length) {
                    this.setState({ data: this.getEmployeesData(this.props.registeredUsers) });
                }
            }
        }
    }



    handleGoBackToWelcomePage = () => {
        this.props.history.push('/welcomepage')
    }

    handleCSVDownload = () => {
        let { data } = this.state;
        const rows = [
            ["LOB", "User ID", "Employee Name", "Title", /*"Cubicle Number",*/ "Delivery Office", "Office Code", "Cost Center", "Email", "Upgrade Date", "Selected Device", "Selected Dock", "User Comments", "Optout Comments", "Software Packages", "Registered", "Optout", "Upgrade Deadline", "Reservation Reminder", "Complete Your Computer Upgrade", "Shipping Confirmation", "Ship Date", "Tracking Number"]
        ]
        for (let i = 0; i < data.length; i++) {
            let row = [];
            row.push(data[i].LOB);
            row.push(data[i].User_ID);
            row.push(data[i].Employee_Name);
            row.push(data[i].JobFunction);
            /*row.push(data[i].Cubicle_Number);*/
            row.push(data[i].Delivery_Office);
            row.push(data[i].Office_Code);
            row.push(data[i].Cost_Center);
            row.push(data[i].Email);
            row.push(data[i].Upgrade_Date);
            row.push(data[i].Selected_Device);
            row.push(data[i].Selected_Dock);
            row.push(data[i].User_Comments);
            row.push(data[i].Optout_Comments);
            row.push(data[i].Software_Packages);
            row.push(data[i].Registered);
            row.push(data[i].Optout);
            row.push(data[i].Upgrad_Deadline);
            row.push(data[i].Reservation_Reminder);
            row.push(data[i].Complete_Your_Computer_Upgrade);
            row.push(data[i].Shipping_Confirmation);
            row.push(data[i].Ship_Date);
            row.push(data[i].Tracking_Number);
            rows.push(row);
        }

        var Papa = require("papaparse/papaparse.min.js");
        var csv = Papa.unparse(rows);
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, 'Report.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Report.csv');
        tempLink.click();

    }

    render() {
        const tableData = {
            columns: [
                {
                    label: 'LOB',
                    field: 'LOB',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'User ID',
                    field: 'User ID',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Employee Name',
                    field: 'Employee Name',
                    sort: 'asc',
                    width: 50
                },
                {
                    label: 'Employee Title',
                    field: 'Employee Title',
                    sort: 'asc',
                    width: 150
                },
                //{
                //    label: 'Cubicle Number',
                //    field: 'Cubicle Number',
                //    sort: 'asc',
                //    width: 70
                //},
                {
                    label: 'Delivery Office',
                    field: 'Delivery Office',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Office Code',
                    field: 'Office Code',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Cost Center',
                    field: 'Cost Center',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Email',
                    field: 'Email',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Upgrade Date',
                    field: 'Upgrade Date',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Selected Device',
                    field: 'Selected Device',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Selected Dock',
                    field: 'Selected Dock',
                    sort: 'asc',
                    width: 50
                },
                {
                    label: 'User Comments',
                    field: 'User Comments',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Optout Comments',
                    field: 'Optout Comments',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Software Packages',
                    field: 'Software Packages',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Registered',
                    field: 'Registered',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Optout',
                    field: 'Optout',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Upgrade Deadline',
                    field: 'Upgrade_Deadline',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Reservation Reminder',
                    field: 'Reservation Reminder',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Complete Your Computer Upgrade',
                    field: 'Complete Your Computer Upgrade',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Shipping Confirmation',
                    field: 'Shipping Confirmation',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Ship Date',
                    field: 'Ship Date',
                    sort: 'asc'
                },
                {
                    label: 'Tracking Number',
                    field: 'Tracking Number',
                    sort: 'asc',
                    width: 100
                },


            ],
            rows: this.state.data
        };

        if (this.props.registeredPending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )
        return (
            <React.Fragment>
                <Button
                    style={{ margin: 5, backgroundColor: "#2185d0", color: "white" }}
                    onClick={this.handleCSVDownload}
                >
                    Download CSV
                </Button>
                <div className="datatable-grid">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        hover
                        data={tableData} />
                </div>
                <Button
                    onClick={this.handleGoBackToWelcomePage}
                    className="welcome-page-back-buttonRegUsers">
                    Back
                </Button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    error: getAllRegisteredUsersError(state),
    registeredUsers: getAllRegisteredUsers(state),
    registeredPending: getAllRegisteredUsersPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllSubmittedUsers: fetchAllSubmittedUsers,
    deleteUser: deleteUser
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminReport);


