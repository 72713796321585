import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Icon, Button, Radio, Form, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { fetchAllOfficeListingWithPointOfContacts } from '../../services/officeListingWithPointOfContactService';
import { DeleteOfficeListingointOfContactURL } from '../../constants';
import { store } from '../../indexApp.js';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import './AdminOfficeListingPage.css';
import { authAxios } from '../../utils';

import { getAllOfficeListingWithPointOfContacts, getAllOfficeListingWithPointOfContactsError, getAllOfficeListingWithPointOfContactsPending } from '../../reducers/allOfficeListingWithPointOfContactReducer';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';



class AdminOfficeListing extends Component {

    constructor(props) {
        super(props)
        this.handleAddPOC = this.handleAddPOC.bind(this);
        //this.handleDeleteOffice = this.handleDeleteOffice.bind(this);
    }

    state = {
        officeListingPointOfContacts: [],
        LOB: ''
    }

    getOfficeListingPointOfContactData(officeListingPointOfContacts) {
        let data = officeListingPointOfContacts.map((x, index) => {
            return ({
                'key': x.key,
                'delete': this.deleteSectionForEachRow(officeListingPointOfContacts[index]),
                'edit': this.editSectionForEachRow(officeListingPointOfContacts[index]),
                'loginName': x.loginName,
                'office': x.office,
                'costCenter': x.costCenter,
                'country': x.country,
                'shippingInfo': x.shippingInfo,
                'phoneNumber': x.phoneNumber,
                'pointOfContact': x.contact,
                'email': x.email,
            })
        });
        return data;
    }

    handleGoBackToWelcomePage = () => {
        this.props.history.push('/welcomepage')
    }

    componentDidMount() {
        const { fetchAllOfficeListingWithPointOfContacts } = this.props;
        fetchAllOfficeListingWithPointOfContacts();
        this.setState({
            officeListingPointOfContacts: this.getOfficeListingPointOfContactData(this.props.officeListingPointOfContacts),
            LOB: 'CORP'
        });
        console.log(store.getState());
    }

    componentDidUpdate(prevProps, prevState) {
        for (let i = 0; i < this.props.officeListingPointOfContacts.length; i++) {
            if (this.props.officeListingPointOfContacts.length != prevProps.officeListingPointOfContacts.length ||
                this.props.officeListingPointOfContacts[i].office != prevProps.officeListingPointOfContacts[i].office ||
                this.props.officeListingPointOfContacts[i].costCenter != prevProps.officeListingPointOfContacts[i].costCenter ||
                this.props.officeListingPointOfContacts[i].country != prevProps.officeListingPointOfContacts[i].country ||
                this.props.officeListingPointOfContacts[i].shippingInfo != prevProps.officeListingPointOfContacts[i].shippingInfo ||
                this.props.officeListingPointOfContacts[i].phoneNumber != prevProps.officeListingPointOfContacts[i].phoneNumber ||
                this.props.officeListingPointOfContacts[i].pointOfcontact != prevProps.officeListingPointOfContacts[i].pointOfcontact) {
                this.setState({ officeListingPointOfContacts: this.getOfficeListingPointOfContactData(this.props.officeListingPointOfContacts) });
            }
        }
       
    }
    
    handleDeletePoc(selectedOfficeListingPointOfContact) {
        let selectedPoc = this.props.officeListingPointOfContacts.find(_ => _.loginName == selectedOfficeListingPointOfContact.loginName);
        authAxios.delete(`${DeleteOfficeListingointOfContactURL}${selectedPoc.loginName}`)
            .then(response => {
                let thisPoc = this.props.officeListingPointOfContacts.find(_ => _.loginName == selectedPoc.loginName);
                let employees = this.props.officeListingPointOfContacts.splice(this.props.officeListingPointOfContacts.indexOf(thisPoc), 1);
                let updatedOfficeListingPointOfContacts = this.getOfficeListingPointOfContactData(this.props.officeListingPointOfContacts);

                this.setState({
                    officeListingPointOfContacts: updatedOfficeListingPointOfContacts
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    deleteSectionForEachRow(selectedOfficeListingPointOfContact) {
        return (
            <React.Fragment>
                <div className='dropdownAction'>
                    <Icon name="trash alternate" style={{ color: 'red' }} size='large' onClick={() => { if (window.confirm('Are you sure you wish to delete this user?')) this.handleDeletePoc(selectedOfficeListingPointOfContact) }} />
                </div>
            </React.Fragment>
        )
    }

    editSectionForEachRow(selectedOfficeListingPointOfContact) {
        return (
            <div className='dropdownAction'>
                <div className='dropdownAction'>
                    <Link to={{ pathname: '/admin/adminOfficeEditPage/' + selectedOfficeListingPointOfContact.key }} underlaycolor="transparent">
                        <Icon name="pencil alternate" style={{ color: 'blue' }} size='large' />
                    </Link>
                </div>
            </div>
        )
    }

    handleAddPOC() {
        this.props.history.push(`/admin/adminPOCAddPage`);
    }

    handleLOBChange(e, value) {
        this.setState({
            LOB: value.value,
        }, () => {
            const { fetchAllOfficeListingWithPointOfContacts } = this.props;
            fetchAllOfficeListingWithPointOfContacts(this.state.LOB);
        });
    }

    render() {
        const tableData = {
            columns: [
                {
                    label: 'Key',
                    field: 'key',
                    sort: 'asc',
                    width: 70,
                },
                {
                    label: 'Delete',
                    field: 'delete',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Edit',
                    field: 'edit',
                    sort: 'asc',
                    width: 70,
                },
                {
                    label: 'Login Name',
                    field: 'loginName',
                    sort: 'asc',
                    width: 70,
                },
                {
                    label: 'Office',
                    field: 'office',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Cost Center',
                    field: 'costCenter',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Country',
                    field: 'country',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Shipping Info',
                    field: 'shippingInfo',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Phone Number',
                    field: 'phoneNumber',
                    sort: 'asc',
                    width: 70
                },
                {
                    label: 'Point Of Contact',
                    field: 'pointOfContact',
                    sort: 'asc',
                    width: 70
                },

                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 70
                },
            ],
            rows: this.state.officeListingPointOfContacts
        };
        if (this.props.pending)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting All The Employees...</Loader>
                    </Dimmer>
                </div>
            )
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <h1 className="h1"> Office Listings / POCs</h1>
                    {/*<Button content='Add New POC' primary style={{ width: '190px', marginBottom: '20px' }} onClick={this.handleAddPOC} />*/}
                    <React.Fragment>

                        <Form className='adminForms'>
                            <Form.Field className='dockformText'>
                                <p className='adminRadioButtonText'> Select line of business:</p>
                            </Form.Field>
                            <Form.Field className='dockform'>
                                <Radio className='adminRadioButton'
                                    label='CORP'
                                    name='radioGroupLOBManageHardware'
                                    value='CORP'
                                    checked={this.state.LOB === 'CORP'}
                                    onChange={(e, value) => this.handleLOBChange(e, value)}
                                />
                                <Radio className='adminRadioButton'
                                    label='PROTIVITI'
                                    name='radioGroupLOBManageHardware'
                                    value='PROTIVITI'
                                    checked={this.state.LOB === 'PROTIVITI'}
                                    onChange={(e, value) => this.handleLOBChange(e, value)}
                                />
                                <Radio className='adminRadioButton'
                                    label='FIELD'
                                    name='radioGroupLOBManageHardware'
                                    value='FIELD'
                                    checked={this.state.LOB === 'FIELD'}
                                    onChange={(e, value) => this.handleLOBChange(e, value)}
                                />
                            </Form.Field>
                        </Form>


                        <div className="datatable-grid datatable-officeListing">
                            <MDBDataTable
                                striped
                                bordered
                                hover
                                data={tableData} />
                            <Button
                                onClick={this.handleGoBackToWelcomePage}
                                className="welcome-page-back-button">
                                Back
                            </Button>
                        </div>
                    </React.Fragment>
                </div>
                <DualFooter />
            </div>
            );          
    }
                     
}

const mapStateToProps = state => ({
    error: getAllOfficeListingWithPointOfContactsError(state),
    officeListingPointOfContacts: getAllOfficeListingWithPointOfContacts(state),
    pending: getAllOfficeListingWithPointOfContactsPending(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllOfficeListingWithPointOfContacts: fetchAllOfficeListingWithPointOfContacts,
}, dispatch)

export default compose(withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(AdminOfficeListing);