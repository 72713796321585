import React, { Component } from 'react';
import { Segment, Button, Grid, Label, Input } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { AddSnowPocURL, UpdateOfficeListingPointOfContactURL} from '../../constants';
import { authAxios } from '../../utils';
import './AdminUserAddPage.css';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';


class AdminPOCAddComponent extends Component {
    constructor(props) {
        super(props)        
        this.handleClickBack = this.handleClickBack.bind(this);
        this.handleSave = this.handleSave.bind(this);        
    }
    
    state = {
        loginName: '',
        error: false
    }

    handleClickBack() {
        this.props.history.push(`/admin/AdminOfficeListingPage`);
    }
        
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: false })
    };

    handleSave = () => {

        const { loginName } = this.state;
        let results = []
        let result = [];
        results.push({
            Login: loginName,            
        });
        result.push(results)

        authAxios.post(AddSnowPocURL + loginName, {
            
        }).then(response => {
            this.props.history.goBack();
            
        })
        .catch((error) => {
            console.log('error ' + error);
            this.setState({
                error: true
            })
        });
        
    }

    Usererrormessage = () => {
        if (this.state.error === true) {
            return (
                <React.Fragment>
                    {this.state.error &&
                        <h2 className="errorreviewUINewUser" >
                            Error adding new user
                    </h2>
                    }
                </React.Fragment>
            )
        }
    }

    render() {
        const { loginName } = this.state
        return (
            <div>
                <DualHeader/>
                <Segment.Group className="segment-container">
                    <Segment className="segment-header inverted">Add a POC:</Segment>
                    <Segment>
                        <Grid columns={2}>
                            {/* Name */}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Label className="label-title">Name: </Label>
                                </Grid.Column>
                                <Grid.Column>
                                    <Input placeholder='Username' name='loginName' value={loginName} onChange={(e) => this.handleChange(e)} />
                                </Grid.Column>
                            </Grid.Row>

                           
                        </Grid>
                    </Segment>
                </Segment.Group>
                <div>
                    {this.Usererrormessage()}
                </div>

                <div>
                    <Button className="backbuttonadmin2" onClick={this.handleClickBack}>Back</Button>
                    <Button className="savedbutton" onClick={this.handleSave}>Save</Button>
                    
                </div>
                <DualFooter/>
            </div>
        );
    }
}

export default AdminPOCAddComponent;