import {
    FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_PENDING, FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_SUCCESS, FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_ERROR
} from "../actions";

const initialStateForAllOfficeListingWithPointOfContacts = {
    pending: false,
    officeListingPointOfContacts: [],
    error: null
}
export function allOfficeListingWithPointOfContactsReducer(state = initialStateForAllOfficeListingWithPointOfContacts, action) {
    switch (action.type) {
        case FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_SUCCESS:
            return {
                ...state,
                pending: false,
                officeListingPointOfContacts: action.officeListingPointOfContacts
            }
        case FETCH_ALL_OFFICE_LISTING_WITH_POINT_OF_CONTACT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        default:
            return state;
    }
}

export const getAllOfficeListingWithPointOfContacts = state => state.allOfficeListingWithPointOfContactsReducer.officeListingPointOfContacts ? state.allOfficeListingWithPointOfContactsReducer.officeListingPointOfContacts : []
export const getAllOfficeListingWithPointOfContactsPending = state => state.allOfficeListingWithPointOfContactsReducer.pending;
export const getAllOfficeListingWithPointOfContactsError = state => state.allOfficeListingWithPointOfContactsReducer.error;
