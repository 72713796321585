import newestprobanner2 from '../../img/tryingnewheader.png';
import React, { Component } from 'react';
import SmoothImage from 'react-smooth-image';
import { Image } from 'semantic-ui-react'
import '../Header.css';

class Header extends Component {

    //288

    render() {
        const divStyle = {
            paddingBottom: 200
        };
        return (
            
            <header>
                <SmoothImage
                    src={newestprobanner2}
                    alt="header"
                    transitionTime={.5}
                    containerStyles={divStyle}
                //Other props if you choose to use them
                />
            </header>
        );}
}
export default Header;

