import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { allUsersReducer } from './allUsersReducer';
import { allRegisteredUsersReducer } from './AllRegisteredUsersReducer';
import { allOfficeListingWithPointOfContactsReducer } from './allOfficeListingWithPointOfContactReducer';

export default combineReducers({
    userReducer,
    allUsersReducer,
    allRegisteredUsersReducer,
    allOfficeListingWithPointOfContactsReducer
});