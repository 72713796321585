import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import { host } from './constants';

const adalConfig = {
    tenantId: '16532572-d567-4d67-8727-f12f7bb6aed3',
    clientId: '035b552d-8b81-4023-98b0-5728be79ce26',
    redirectUri: host,
    endpoints: {
        api: 'https://login.microsoftonline.com/16532572-d567-4d67-8727-f12f7bb6aed3'
    },
    cacheLocation: 'localStorage',
    navigateToLoginRequestUrl: false
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
