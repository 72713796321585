import React, { Component } from 'react';
import {  Grid, Button} from 'semantic-ui-react'
import './WelcomePage.css';
import { connect } from 'react-redux';
import { store } from '../../indexApp.js';
import Axios from 'axios';
import { authAxios } from '../../utils';
import { UpdateUserURL, GetEmployeeInformationURL } from '../../constants';
import { authContext, getToken } from '../../adalConfig';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';


let email = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
let name = authContext.getCachedUser() ? authContext.getCachedUser().profile.name : '';

class WelcomePage extends Component {

    constructor(props) {
        super(props);
    
        this.handleManageUser = this.handleManageUser.bind(this);
        this.handleManageCal = this.handleManageCal.bind(this);
        this.handleManageHardware = this.handleManageHardware.bind(this);
        this.handleEditComms = this.handleEditComms.bind(this);
        this.handleOfficePOC = this.handleOfficePOC.bind(this);
        //this.handlePermissions = this.handlePermissions.bind(this);
        this.handleBacktoRegSite = this.handleBacktoRegSite.bind(this);
    }

    state = {
        apiResponse: '',
        redirect: false,
        errorredirect: false,
        loading: true,
    };

    

    handleManageUser() {
        this.props.history.push('./admin/AdminPage');
    }

    handleManageCal() {
        this.props.history.push('./ManageCalendar');
    }

    handleManageHardware() {
        this.props.history.push('./admin/AdminHardwarePage');
    }

    handleEditComms() {
        this.props.history.push('./EditCommunications');
    }

    handleOfficePOC() {
        this.props.history.push('./admin/AdminOfficeListingPage');
    }

    //handlePermissions() {
    //    this.props.history.push('./Permissions');
    //}

    handleBacktoRegSite() {
        this.props.history.push('./UserInformation');
    }

    componentDidMount() {
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: email
        })
            .then(response => {
                let data = response.data;
               
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }


    render() {
       
        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <div className="maindivWelcomePage">
                        <h1 className="h1WelcomePage">Welcome to the Registration Site Admin Portal.</h1>


                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column stretched className="left column">
                                     <Button onClick={this.handleManageUser} className="ai manage user"> User Management</Button>
                                     <Button onClick={this.handleManageCal} className="ai manage cal">Manage Calendar</Button>
                                     <Button onClick={this.handleManageHardware} className="ai manage hardware data">Manage Hardware/Upload User Data</Button>
                                </Grid.Column>
                                <Grid.Column stretched className="right column">
                                     <Button onClick={this.handleEditComms} className="ai edit comm">Edit Communication</Button>
                                     <Button onClick={this.handleOfficePOC} className="ai office listing poc">Office Listing/Office POC Page</Button>
                                    {/*<Button onClick={this.handlePermissions} className="ai permissions">Permissions</Button>*/}
                                </Grid.Column>

                            </Grid.Row>
                         </Grid>
                                <Button onClick={this.handleBacktoRegSite} className="ai back to reg">Back to Registration Site</Button>
          
                    </div>
                </div>
                <DualFooter />
            </div>
        );
    }
}
export default connect()(WelcomePage);
