import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Grid, Button, Image, Segment, Radio, List, Message, Label, Icon, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { authAxios } from '../../utils';
import { UpdateUserURL, GetEmployeeInformationURL, GetComputerDevicesURL, AddSnowUserURL, InsertPatchEmployeeAsyncURL, DeleteComputerDeviceURL, GetDeviceImagesURL } from '../../constants';
import { authContext } from '../../adalConfig';
import Popup from "reactjs-popup";
import { MDBDataTable } from 'mdbreact';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ReactUploadFile from 'react-upload-file';
import { CSVReader } from 'react-papaparse';
import DualHeader from '../DualHeader.js';
import DualFooter from '../DualFooter.js';
import './AdminHardwarePage.css';


let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class AdminHardware extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleLaptopChange = this.handleLaptopChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleLaptopEdit = this.handleLaptopEdit.bind(this);
        this.handleEditDetails = this.handleEditDetails.bind(this);
        this.handleHWBackClick = this.handleHWBackClick.bind(this);
        this.handleHWUploadClick = this.handleHWUploadClick.bind(this);
        this.handleAddDevice = this.handleAddDevice.bind(this)
        this.updateData = this.updateData.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.fileInput = React.createRef();
    }

    state = {
        computerDeviceData: [],
        users: [],
        csvfile: undefined,
        csvfileName: '',
        status: 0,
        validUsers: [],
        invalidUsers: [],
        totalUsers: 0,
        LOB: '',
        other: '',
        error: false,
        uploadError: false,
        loading: false
    }

    handleFileUploadChange = event => {
        this.setState({ uploadError: false })
        if (!event.target.files[0] || !this.state.value) {
            this.setState({ error: true })
            return;
        }
        this.setState({
            csvfile: event.target.files[0],
            csvfileName: event.target.files[0].name
        });
        this.setState({ validUsers: [], invalidUsers: [], totalUsers: 0 })
    };

    importCSV = () => {
        this.setState({ uploadError: false })
        if (!this.state.value || !this.state.csvfileName) {
            this.setState({ error: true });
            return;
        }

        const { csvfile } = this.state;
        var Papa = require("papaparse/papaparse.min.js");
        Papa.parse(csvfile, {
            complete: this.updateData,
            skipEmptyLines: true,
            header: true
        });
    };

    result = [];
    validUsers = [];
    invalidUsers = [];

    checkForTrackingInfoFile = (fileObject) => {
        this.setState({ uploadError: false });
        // if the file object header has tracking number, but the file name is not tracking, then we 
        if (typeof (fileObject[0].trackingNumber) !== "undefined" && this.state.value == 'Allowed Users List') {
            this.setState({ uploadError: true })
            return true;
        }
        if (typeof (fileObject[0].enabledAsOf) !== "undefined" && this.state.value == 'Tracking Info') {
            this.setState({ uploadError: true })
            return true;
        }
        return false;
    }

    updateData = (result) => {
        this.result = [];      
        let data = result.data;
        if (typeof (data[0].trackingNumber) !== "undefined" && this.state.value == 'Tracking Info') {
            data.forEach(function (item) {
                item.isSubmitted = true;
            });
        }
        this.result.push(data);
        this.setState({ error: false });
        
        let errorStatus = this.checkForTrackingInfoFile(this.result[0]);
        if (!errorStatus) {
            this.setState({ status: 3 })
            authAxios.post(AddSnowUserURL, {
                result: this.result[0]
            })
                .then(response => {
                    console.log('Upload Successfully')
                    this.invalidUsers = response.data.invalidUsers;
                    this.setState({
                        status: 1,
                        validUsers: response.data.validUsers,
                        invalidUsers: response.data.invalidUsers,
                        totalUsers: response.data.totalUsersCount
                    }, () => {
                        console.log('total users: ' + this.state.totalUsers);
                    })
                    setTimeout(() => {
                        this.setState({ status: 0 });
                    }, 5000);
                })
                .catch((error) => {
                    console.log('error ' + error);
                    this.setState({
                        errorredirect: true
                    });
                    this.setState({
                        loading: false,
                        status: 2
                    })
                    this.props.history.push('../unauthorized');
                });
        }
    }
    updateUserData = () => {
        //console.log(row);
        //this.state.users.push(row.data)
        this.handleChange = this.handleChange.bind(this);

    }
    handleReadCSV = (data) => {
        console.log('data: ' + JSON.stringify(data));
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }

    handleImportOffer = () => {
        this.fileInput.current.click();
    }

    fileLoaded = (data) => {
        var tmp = "hello world";
    }

    handleLaptopChange(e) {
        this.setState({
            value: e.target.textContent,
            error: false
        })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    getComputerDevicesData(computerdevices){
        let data = computerdevices.map((x) => {
            return ({
                'key': x.key,
                'name': x.name,
                'display': x.display,
                'processor': x.processor,
                'ram': x.ram,
                'hardDrive': x.hardDrive,
                'ports': x.ports,
                'weight': x.weight,
                'batterylife': x.batterylife,
                'deviceCode': x.deviceCode,
                'deviceLOB': x.deviceLOB,
                'accessories': x.dockingStation,
                'image': x.imageFile ? "data:image/" + x.imageName.split(".")[1] + ";base64, " + x.imageFile : null
                                
            })
        });
        return data;
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        this.setState({
            LOB: 'CORP',
            loading: true
        }, () => {
                authAxios.get(GetComputerDevicesURL)
                    .then(response => {
                        let data = response.data;
                        let imageUrls = data.map(_ => _.image);

                        authAxios.post(GetDeviceImagesURL, {
                            DeviceNames: imageUrls
                        })
                        .then(response1 => {
                            let data1 = response1.data;
                            let newdata = data.map((item, i) => Object.assign({}, item, data1[i]));
                            this.setState({
                                computerDeviceData: this.getComputerDevicesData(newdata)
                            }, () => {
                                this.setState({
                                    loading: false
                                })
                            });
                        }).catch((error) =>{
                            console.log('error ' + error);
                            this.setState({
                                errorredirect: true
                            });
                            this.setState({
                                loading: false
                            })
                            this.props.history.push('../unauthorized');
                        })

                    })
                    .catch((error) => {
                        console.log('error ' + error);
                        this.setState({
                            errorredirect: true
                        });
                        this.setState({
                            loading: false
                        })
                        this.props.history.push('../unauthorized');
                    });
        });
    }



    handleClick() {
        this.props.history.push('../welcomepage');
    }

    handleHelpClick = async () => {
        this.props.history.push('../help');
    }

    handleEdit = async () => {
        this.props.history.push('')
    }

    handleHWUploadClick = async () => {
        this.props.history.push('./');
    }

    handleHWBackClick = async () => {
        this.props.history.push('../welcomepage');
    }


    
    handleLaptopEdit = async () => {
        this.props.history.push('')
    }

    handleEditDetails = async () => {
        this.props.history.push('')
    }

    handleLOBChange(e, value) {
        this.setState({
            LOB: value.value,
        })
    }

    handleAddDevice() {
        this.props.history.push(`/admin/AdminDeviceAddPage`);
    }

    Usererrormessage = () => {
        return (
            <React.Fragment>
                {this.state.error &&
                    <h2 className="errorreviewUI" >
                        Error: You must make a selection for all the required fields.
                    </h2>
                }
            </React.Fragment>
        )
    }

    UserFileUploadErrorMessage = () => {
        return (
            <React.Fragment>
                {this.state.uploadError &&
                    <h2 className="errorreviewUI" >
                        Error: You must select the right file for your upload type.
                    </h2>
                }
            </React.Fragment>
        )
    }


    handleDeleteDevice(device) {
        authAxios.delete(DeleteComputerDeviceURL + device.item.key)
            .then(response => {
                window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
    }


    handleLOBSelection(item) {
            if (this.state.LOB === 'CORP') {
                return (
                    <React.Fragment>
                        <Form>
                            <Grid columns={2} divided className="selection">
                                {this.state.computerDeviceData.filter(item => (item.deviceLOB.includes('CORP') && item.name !== 'Other')).map((item, index) =>
                                    (<Grid.Row className="border" key={item.name}>
                                        <Grid.Column className="">
                                            <Label className='text'>{item.name + ' - ' + item.deviceCode}</Label>
                                            <div className='dropdownAction'>
                                                <Link to={{ pathname: '/admin/adminDeviceEditPage/' + item.key }} underlaycolor="transparent">
                                                    <Icon name="pencil alternate" style={{ color: 'blue' }} size='small' />
                                                </Link>
                                                <div className='dropdownAction'>
                                                    <Icon name="trash alternate" style={{ color: 'red' }} size='small' onClick={() => { if (window.confirm('Are you sure you wish to delete this device?')) this.handleDeleteDevice({ item }) }} />
                                                </div>
                                            </div>
                                            <Popup modal trigger={<Image src={item.image} className='laptops' />}>
                                                <Image src={item.image} className='picturepopup' />
                                            </Popup>

                                        </Grid.Column>
                                        <Grid.Column>
                                            <Grid columns={2} divided className="details">
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Display
                                                </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.display}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Processor
                                                </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.processor}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ram
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.ram}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Hard Drive
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.hardDrive}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ports
                                                </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                       {item.ports}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Weight
                                                </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.weight}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Battery Life
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.batterylife}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Accessories
                                                    </Grid.Column>
                                                <Grid.Column className="leftalign">
                                                    {item.accessories}
                                                </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                        </Grid.Column>

                                    </Grid.Row>))}
                                {this.state.computerDeviceData.filter(item => (item.name === 'Other')).map((item, index) =>
                                    (<Grid.Row className="border" key={item.name}>
                                        <Grid.Column className="">
                                            <Label className='text'>{item.name + ' - ' + item.deviceCode}</Label>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <br /><p className='other'><text className='bold'>1.</text> Go to BuyRHight</p>
                                            <p className='other'><text className='bold'>2.</text> In the "What do you need" type out the device you are looking for </p>
                                            <p className='other'><text className='bold'>3.</text> Select the device labeled - Corp Upgrade </p>
                                            <p className='other'><text className='important'>Important*</text> Make sure to answer the following question on BuyRHight when you add the device to your cart - "Did you receive an upgrade invite from: NACorpUpgrades@roberthalf.com?”  “Yes or No"</p><br />
                                        </Grid.Column>
                                    </Grid.Row>))}
                            </Grid>
                        </Form>
                    </React.Fragment>
                )
            }
            else if (this.state.LOB === 'PROTIVITI') {
                return (
                    <React.Fragment>
                        <Form>
                            <Grid columns={2} divided className="selection">
                                {this.state.computerDeviceData.filter(item => (item.deviceLOB.includes('PRO'))).map((item, index) =>
                                    (<Grid.Row className="border" key={item.name}>
                                        <Grid.Column className="">
                                            <Label className='text'>{item.name + ' - ' + item.deviceCode}</Label>
                                            <div className='dropdownAction'>
                                                <Link to={{ pathname: '/admin/adminDeviceEditPage/' + item.key }} underlaycolor="transparent">
                                                    <Icon name="pencil alternate" style={{ color: 'blue' }} size='small' />
                                                </Link>
                                                <div className='dropdownAction'>
                                                    <Icon name="trash alternate" style={{ color: 'red' }} size='small' onClick={() => { if (window.confirm('Are you sure you wish to delete this device?')) this.handleDeleteDevice({ item }) }} />
                                                </div>
                                            </div>
                                            <Popup modal trigger={<Image src={item.image} className='laptops' />}>
                                                <Image src={item.image} className='picturepopup' />
                                            </Popup>

                                        </Grid.Column>
                                        <Grid.Column>
                                            <Grid columns={2} divided className="details">
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Display
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.display}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Processor
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.processor}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ram
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.ram}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Hard Drive
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.hardDrive}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ports
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.ports}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Weight
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.weight}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Battery Life
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.batterylife}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                        </Grid.Column>

                                    </Grid.Row>))}

                            </Grid>
                        </Form>
                    </React.Fragment>

                )
            }
            else {
                return (
                    <React.Fragment>
                        <Form>
                            <Grid columns={2} divided className="selection">
                                {this.state.computerDeviceData.filter(item => (item.deviceLOB.includes('FIELD'))).map((item, index) =>
                                    (<Grid.Row className="border" key={item.name}>
                                        <Grid.Column className="">
                                            <Label className='text'>{item.name + ' - ' + item.deviceCode}</Label>
                                            <div className='dropdownAction'>
                                                <Link to={{ pathname: '/admin/adminDeviceEditPage/' + item.key }} underlaycolor="transparent">
                                                    <Icon name="pencil alternate" style={{ color: 'blue' }} size='small' />
                                                </Link>
                                                <div className='dropdownAction'>
                                                    <Icon name="trash alternate" style={{ color: 'red' }} size='small' onClick={() => { if (window.confirm('Are you sure you wish to delete this device?')) this.handleDeleteDevice({ item }) }} />
                                                </div>
                                            </div>
                                            <Popup modal trigger={<Image src={item.image} className='laptops' />}>
                                                <Image src={item.image} className='picturepopup' />
                                            </Popup>

                                        </Grid.Column>
                                        <Grid.Column>
                                            <Grid columns={2} divided className="details">
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Display
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.display}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Processor
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.processor}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ram
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.ram}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Hard Drive
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.hardDrive}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Ports
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.ports}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowdark">
                                                    <Grid.Column>
                                                        Weight
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.weight}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Battery Life
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.batterylife}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="rowlight">
                                                    <Grid.Column>
                                                        Accessories
                                                    </Grid.Column>
                                                    <Grid.Column className="leftalign">
                                                        {item.accessories}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>

                                    </Grid.Row>))}

                            </Grid>
                        </Form>
                    </React.Fragment>

                )
            }
        }

    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Getting Data...</Loader>
                    </Dimmer>
                </div>
            )

        const { status } = this.state;

        return (
            <div className='outer-container'>
                <div className='inner-container'>
                    <DualHeader/>
                    <Tabs>
                        <TabList>
                            <Tab>Manage Hardware</Tab>
                            <Tab>Upload User Data</Tab>
                            <Tab>Templates</Tab>
                        </TabList>


                        <TabPanel>

                            <h1 className="h1">Device Selection</h1>

                            <Form className='adminForms'>
                                <Form.Field className='dockformText'>
                                    <Button content='Add New Device' primary style={{ width: '190px', marginBottom: '20px' }} onClick={this.handleAddDevice} />
                                    <p className='adminRadioButtonText'> Select line of business:</p>
                                </Form.Field>
                                <Form.Field className='dockform'>
                                    <Radio className='adminRadioButton'
                                        label='CORP'
                                        name='radioGroupLOBManageHardware'
                                        value='CORP'
                                        checked={this.state.LOB === 'CORP'}
                                        onChange={(e, value) => this.handleLOBChange(e, value)}
                                    />
                                    <Radio className='adminRadioButton'
                                        label='PROTIVITI'
                                        name='radioGroupLOBManageHardware'
                                        value='PROTIVITI'
                                        checked={this.state.LOB === 'PROTIVITI'}
                                        onChange={(e, value) => this.handleLOBChange(e, value)}
                                    />
                                    <Radio className='adminRadioButton'
                                        label='FIELD'
                                        name='radioGroupLOBManageHardware'
                                        value='FIELD'
                                        checked={this.state.LOB === 'FIELD'}
                                        onChange={(e, value) => this.handleLOBChange(e, value)}
                                    />
                                </Form.Field>
                            </Form>


                            {this.handleLOBSelection()}
                                                       
                            

                     
                            <div className='AdminHardwareButtons'>
                                <Button className="backbutton" onClick={this.handleClick}>Back</Button>
                                <Button className="helpbutton" onClick={this.handleHelpClick}> Help</Button>
                            </div>

                        </TabPanel>

                        
                        <TabPanel>
                            <h1 className="uploadtab">Upload User Data</h1>

                            <Segment.Group className="segment-container">
                                <Segment className="segment-header inverted">Upload User Data </Segment>
                                <Grid columns={2} divided width={6}>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <Label className="uploadtype">Upload Type:</Label>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Form className="form-style">
                                                <Form.Group>
                                                    <Form.Radio
                                                        label='Allowed Users List'
                                                        name='radioGroup'
                                                        className='upload-options'
                                                        value='AllowedUsersList'
                                                        error={this.state.error}
                                                        checked={this.state.value === 'Allowed Users List'}
                                                        onChange={(e) => this.handleLaptopChange(e)}
                                                        required
                                                            />
                                                </Form.Group>
                                            </Form>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Form className="form-style">
                                                <Form.Group>
                                                    <Form.Radio
                                                        label='Tracking Info'
                                                        name='radioGroup'
                                                        className='upload-options'
                                                        value='TrackingInfo'
                                                        error={this.state.error}
                                                        checked={this.state.value === 'Tracking Info'}
                                                        onChange={(e) => this.handleLaptopChange(e)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Label className="uploadtype">Select a file:</Label>
                                        </Grid.Row>
                                        <Grid.Row centered>
                                            <input
                                                className="csv-input"
                                                type="file"
                                                ref={input => {
                                                    this.filesInput = input;
                                                }}
                                                name="file"
                                                placeholder={null}
                                                onChange={this.handleFileUploadChange}
                                            />
                                        </Grid.Row>
                                        <br />
                                        <Grid.Row centered>
                                            <button className="ui positive basic button" onClick={this.importCSV}> Upload</button>
                                        </Grid.Row>
                                        <br />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Label className="fileTitle">{this.state.csvfileName}</Label>
                                        {this.state.csvfileName ? (
                                            <div style={{textAlign:'center'}}>
                                                <Label style={{ color: "black", textAlign: "center" }}>Total Users: {this.state.totalUsers}</Label>
                                                <Label style={{ color: "green", textAlign: "center" }}>Passed: {this.state.validUsers.length}</Label>
                                                <Label style={{ color: "red", textAlign: "center" }}>Failed: {this.state.invalidUsers.length}</Label>
                                            </div>
                                        ) : null}
                                        <Grid columns={2} width={6}>
                                            <Grid.Column>
                                                <Grid.Row className="uploadtype">
                                                    <Grid.Column style={{ marginTop: "50px", marginLeft: "25%" }}>
                                                        {this.state.validUsers.map(item => (
                                                            <Grid.Row key={item}>
                                                                <Grid.Column>
                                                                    <Icon name="checkmark" size='large' style={{ color: "green" }} />
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Label>{item}</Label>
                                                                </Grid.Column>
                                                            </Grid.Row>))}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Grid.Row className="uploadtype">
                                                    <Grid.Column style={{ marginTop: "50px", marginLeft: "25%" }}>
                                                        {this.state.invalidUsers.map(item => (
                                                            <Grid.Row key={item}>
                                                                <Grid.Column>
                                                                    <Icon name="close" size='large' style={{ color: "red" }} />
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Label>{item}</Label>
                                                                </Grid.Column>
                                                            </Grid.Row>))}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                            </Segment.Group>
                            <Grid columns={2}>
                                <Grid.Row centered>
                                    <Button className="backbuttonuploadtab" onClick={this.handleHWBackClick}>Back</Button>
                                </Grid.Row>
                                <Grid.Row centered>
                                    {this.Usererrormessage()}
                                    {this.UserFileUploadErrorMessage()}
                                </Grid.Row>
                            </Grid>

                        </TabPanel>


                        <TabPanel>

                            <h1 className="uploadtab">Templates</h1>

                            <Segment.Group className="segment-container">
                                <Segment className="segment-header inverted">Select a Template</Segment>
                                <Grid columns={2} divided width={6}>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <Label className="uploadtype">Templates:</Label>
                                        </Grid.Row>
                                        <br />
                                        <br />
                                        <Grid.Row>
                                            < Link to="/Allowed Users.csv" target="_blank" download className="form-style" >Allowed User Template</Link>
                                        </Grid.Row>
                                        <br />
                                        <br />
                                        <Grid.Row>
                                            < Link to="/Tracking Upload.csv" target="_blank" download className="form-style">Tracking Info Template</Link>
                                        </Grid.Row>
                                        <br />
                                        <br />
                                        <Grid.Row>
                                            < Link to="/Deploying Upgrade Assets in SNOW.docx" target="_blank" download className="form-style">Deploying Upgrade Assets in SNOW</ Link>
                                        </Grid.Row>
                                        <br />                                        
                                        <br />

                                        <Grid.Row>
                                            <Label className="uploadtype">Device Codes:</Label>
                                        </Grid.Row>
                                        <br />


                                        {this.state.computerDeviceData.filter(item => (item.deviceCode != null)).map((item, index) =>
                                            (
                                                <Grid.Row className='form-style'>
                                                    {item.name + ' - ' + item.deviceCode}
                                                </Grid.Row>
                                            )
                                        )}



                                    </Grid.Column>
                                </Grid>
                                <br />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Row centered>
                                        <Button className="backbuttonuploadtab" onClick={this.handleHWBackClick}>Back</Button>
                                    </Grid.Row>
                                </Grid> 
                            </Segment.Group>
                        </TabPanel>
                    </Tabs>
                    {(() => {
                        if (status == 1) {
                          return (
                              <Message
                                  success
                                  header='Uploaded successfully'
                                  content='Uploaded successfully'
                              />)
                        } else if (status == 2) {
                          return (
                            <Message
                                error
                                header='Uploaded Error'
                                  content='Uploaded Error'
                            />)
                        } else if (status == 3) {
                            return (
                                <Message
                                    info
                                    header='Uploading In Progress'
                                    content='Uploading In Progress'
                                />)
                        } else {
                          return (
                            <div></div>
                          )
                              }
                    })()}
                </div>
                <DualFooter />
            </div>

        );
    }
}

export default connect()(AdminHardware);

