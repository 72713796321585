import React, { Component } from 'react';
import { Segment, Button, Image, Grid, Label, Form, Dimmer, Loader } from 'semantic-ui-react'
import rhprobanner from '../img/besttorhprobanner.png';
import rhprobotbanner from '../img/bannergray.png';
import { withRouter } from 'react-router';
import './ReviewPage.css';
import checkcomplete from '../img/checkcomplete.png';
import { store } from '../indexApp.js';
import { authContext, getToken } from '../adalConfig';
import { authAxios } from '../utils';
import { GetEmployeeInformationURL, SendOptoutEmailConfirmationURL, GetOfficeListingPointOfContactURL, InsertPatchEmployeeAsyncURL, } from '../constants';
import ProHeader from './Pro/ProHeader';
import ProFooter from './Pro/ProFooter'
import RHHeader from './Corp/RHHeader';
import RHFooter from './Corp/RHFooter'
import { replace } from 'react-router-redux';


let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';

class ReviewOptOut extends Component {
    constructor(props) {
        super(props)
        this.handleGoesBack = this.handleGoesBack.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleGoToHelp = this.handleGoToHelp.bind(this);
        this.handleCalClickBack = this.handleCalClickBack.bind(this);
        this.handleBacktoUserInfo = this.handleBacktoUserInfo.bind(this);
        this.handleUserComment = this.handleUserComment.bind(this);
        this.handleRouteButtons = this.handleRouteButtons.bind(this);
        this.handleReviewText = this.handleReviewText.bind(this);
    }

    state = {
        name: "",
        accountLogon: "",
        
        email: "",
        phoneNumber: "",
       
      
       
        userRole: "", 
        optoutComments: "",
        userComments: "",
        optOut: 'false',
        loading: false,
        isSubmitted: '',
        isAdmin: '',
        isOptout: '',
        key: ""
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.ensureDataFetched();
        this.setState({
            loading: true
        })
        let loginNameWithDomain = authContext.getCachedUser() ? authContext.getCachedUser().profile.upn : '';
        if (!loginNameWithDomain) {
            this.props.history.push('./unauthorized');
            return;
        }
        authAxios.post(GetEmployeeInformationURL, {
            LoginNameWithDomain: loginNameWithDomain
        })
            .then(response => {
                let data = response.data;
                this.setState({
                    email: data.email, name: data.name, 
                    phoneNumber: data.telephoneBusiness,
                    accountLogon: data.loginName, userRole: data.userRole,
                    isOptout: data.isOptout, optoutComments: this.isBase64(data.optoutComments) ? atob(data.optoutComments) : data.optoutComments,
                    isAdmin: data.isAdmin, key: data.key

                })
                if (this.state.phoneNumber) {
                    this.setState({ phoneNumber: Number(this.state.phoneNumber.replace(/[^0-9]/g, '')) });
                }
                if (data.userRole === 'PROTIVITI') {
                    authAxios.get(GetOfficeListingPointOfContactURL + `?role=${this.state.userRole}`)
                        .then(response => {
                            let data = response.data;
                            let citiesCostCenters = data.map((s, i) => ({ city: s.city, costCenter: s.costCenter, country: s.country })).filter(_ => _.city && _.costCenter && _.country);
                            citiesCostCenters.push({
                                city: 'San Ramon',
                                costCenter: '90000',
                                country: 'US',
                            })
                            this.setState({
                                citiesCostCenters: this.updateOfficeListingCostCenterOptions(citiesCostCenters),
                                loading: false
                            });
                            let costCenterFromEmployeeDeliveryOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.deliveryOffice);
                            let costCenterFromEmployeeHomeOffice = this.state.citiesCostCenters.filter(_ => _.key == this.state.homeOffice);
                            if (costCenterFromEmployeeDeliveryOffice.length > 0) {
                                this.setState({
                                    deliveryOffice: costCenterFromEmployeeDeliveryOffice[0].value
                                })
                            }
                            if (costCenterFromEmployeeHomeOffice.length > 0) {
                                this.setState({
                                    homeOffice: costCenterFromEmployeeHomeOffice[0].value
                                })
                            }

                        });
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log('error ' + error);
                this.setState({
                    errorredirect: true
                });
                this.setState({
                    loading: false
                })
                this.props.history.push('./unauthorized');
            });
    }

    isBase64(str) {
        if (!str)
            return false;
        if (str === '' || str.trim() === '') { return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    updateOfficeListingCostCenterOptions(source) {
        let sink = source.map((x, index) => {
            return ({
                key: x.city,
                text: "(" + x.costCenter + ") " + x.city + " - " + x.country,
                value: "(" + x.costCenter + ") " + x.city + " - " + x.country,
            })
        });
        return sink;
    }

    handleGoesBack() {
        this.props.history.goBack();
    }

    handleClick = async () => {
        this.setState({
            optOut: 'true'
        })
        authAxios.post(SendOptoutEmailConfirmationURL, { Email: this.state.email, IsOptout: true, LoginNameWithDomain: loginNameWithDomain })
            .then(response => {
                authAxios.patch(InsertPatchEmployeeAsyncURL + this.state.key,
                    [{
                        "op": "replace",
                        "path": '/IsOptout',
                        "value": true
                    }]
                ).then(response => {
                    this.props.history.push({
                        pathname: './OptOutConfirmation',
                        state: {
                            passedUserRole: this.state.userRole
                        }
                    });
                })
                    .catch((error) => {
                        console.log('error' + error);
                    });
            })
            .catch((error) => {
                console.log('error' + error);
                this.setState({
                    errorredirect: true
                });
                this.props.history.push('./unauthorized');
            });
    }

    handleGoToHelp() {
        this.props.history.push('./help');
    }

    handleCalClickBack() {

        this.props.history.push('./optout');

    }

    handleBacktoUserInfo() {
        this.props.history.push('./userinformation');
    }

    

 
   

    handleRouteButtons() {
        if (!this.state.isSubmitted || this.state.isAdmin) {
            return (
                <React.Fragment>
                    <Button className="backbuttonrev" onClick={this.handleCalClickBack}>  <i class="left arrow icon"></i> Back</Button>
                    <Button className="nextbuttonrev" onClick={this.handleClick} disabled={this.state.submitted === 'true'} > Submit </Button>
                </React.Fragment>
            )
        }
    }


    handleHeaderSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProHeader />;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHHeader />;
    }

    handleFooterSelection() {
        if (this.state.userRole === 'PROTIVITI')
            return <ProFooter />;
        else if (this.state.userRole === 'CORPORATE' || this.state.userRole === 'FIELD')
            return <RHFooter />;
    }

    handleUserComment() {
        if (this.state.optoutComments) {
            return (
                <React.Fragment>
                    <Grid.Row className='rowAlignment'>
                        <Grid.Column width={6}>
                            <Label className="label-title">Opt-out Comments: </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Label>{this.state.optoutComments}</Label>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            )
        }
    }

    handleRouteButtons() {
        if (!this.state.isOptout || this.state.isAdmin) {
            return (
                <React.Fragment>
                    <Button className="backbuttonrev" onClick={this.handleCalClickBack}>  <i class="left arrow icon"></i> Back</Button>
                    <Button className="nextbuttonrev" onClick={this.handleClick} disabled={this.state.otpOut === 'true'} > Submit </Button>
                </React.Fragment>
            )
        }
    }

    handleUserRole() {
        if (this.state.userRole === 'PROTIVITI') {
            return (
                <p classname="reviewText">If you have any questions, please email the < a href='mailto:NAProtivitiUpgrades@protiviti.com' >Pro Upgrades Team</a >.</p>
            )
        }
        else if (this.state.userRole === 'CORPORATE') {
            return (
                <p classname="reviewText">If you have any questions, please email the < a href='mailto:NACorpUpgrades@roberthalf.com' >Corp Upgrades Team</a>.</p>
            )
        }
        else {
            return (
                <p classname="reviewText">If you have any questions, please email the < a href='mailto:upgrades@roberthalf.com' >Upgrades Team</a>.</p>
            )
        }
    }

    handleReviewText() {
        if (this.state.isOptout && !this.state.isAdmin) {
            return (
                <React.Fragment>
                    <div className="reviewText">
                        <p>
                            You have Opted-out of the refresh program. - Please see below for details.
                        </p>
                        <br />
                        <p>
                            {this.handleUserRole()}
                        </p>
                    </div>
                </React.Fragment>
            )
        }
    }


    render() {
        if (this.state.loading)
            return (
                <div className="center-me">
                    <Dimmer active inverted>
                        <Loader size='huge'>Loading data...</Loader>
                    </Dimmer>
                </div>
            )
        const { name, accountLogon, homeOffice, deliveryOffice, email, phoneNumber, computerModel, softwarePackages, upgradeWeek, phoneNumberAlternate, userRole } = this.state;

        return (
            <div className='outer-container'>
                <div className="inner-container">
                    {this.handleHeaderSelection()}
                    {this.handleReviewText()}
                    
                    <Segment.Group className="segment-container">
                        <Segment className={userRole === 'CORPORATE' || userRole === 'FIELD' ? "CORP-segment-header" : "PRO-segment-header"}>  {this.state.isSubmitted && !this.state.isAdmin ? '' : 'Please review your responses.'}</Segment>
                        <Segment>

                            <Grid columns={2}>
                                {/* Name */}

                                <Grid.Row className='rowAlignment'>

                                    <Grid.Column width={6}>

                                        <Label className="label-title">Name: </Label>
                                    </Grid.Column>
                                    <Grid.Column>

                                        <Label>{name}</Label>

                                    </Grid.Column>
                                </Grid.Row>

                                {/*Email */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Email: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{email}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Account Logon */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Account Login: </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{accountLogon}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Phone # */}
                                <Grid.Row className='rowAlignment'>
                                    <Grid.Column width={6}>
                                        <Label className="label-title">Telephone (business): </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>{phoneNumber}</Label>
                                    </Grid.Column>
                                </Grid.Row>

                                {this.handleUserComment()}

                            </Grid>
                        </Segment>
                    </Segment.Group>
                    <div className="bottomButtons">
                        {this.handleRouteButtons()}
                        <Button className="helpbutton3" onClick={this.handleGoToHelp}> Help</Button>
                    </div>


                </div>
                {this.handleFooterSelection()}

            </div>

        );
    }
}


export default ReviewOptOut;


