import axios from "axios";
import { APIEndPoint } from "./constants";
import { getToken, authContext } from "./adalConfig";
import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS
} from "./auth-util";

//import { getToken } from "./adalConfig";

//export const authAxios = axios.create({
//    baseURL: APIEndPoint,
//    headers: {
//        Authorization: {
//            toString() {
//                return `Bearer ${getToken()}`
//            }
//        }
//    }
//})
//export const acquireToken = async(request, redirect) => {
//    return msalApp.acquireTokenSilent(request).catch(error => {
//        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
//        // due to consent or interaction required ONLY
//        if (requiresInteraction(error.errorC ode)) {
//            return redirect
//                ? msalApp.acquireTokenRedirect(request)
//                : msalApp.acquireTokenPopup(request);
//        } else {
//            console.error('Non-interactive error:', error.errorCode)
//        }
//    });
//}
export const acquireToken = async (request) => {
    return msalApp.acquireTokenSilent(request).catch(error => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
           return msalApp.acquireTokenPopup(request);
        } else {
            console.error('Non-interactive error:', error.errorCode)
        }
    });
}
export const authAxios = axios.create({
    baseURL: APIEndPoint,
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${getToken()}`
            }
        }
    }
})

//export const getAuthToken = async () => {
//    const tokenResponse = await acquireToken(
//        baseURL + '/Employee/'
//    ).catch(error => {
//        this.setState({
//            error: error.message
//        });
//        this.props.history.push('./unauthorized');
//    });
//    return tokenResponse.accessToken;
//}

authAxios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});





authAxios.interceptors.response.use((response) => {
   // if (response.config.url.indexOf('Employee/finduser') !== -1 || response.config.url.indexOf('Employee/employee') !== -1) {
        //if (response.data.isSubmitted && !response.data.isAdmin) {
          //  return Promise.reject("Already Submitted and Is Not Admin!!");
        //}
   // }
    return response;
}, (error) => {
        if (error.response.status === 401) {
            authContext.login();
            return;
        }
        return Promise.reject(error.message);
});